import styled from "styled-components";

export const PageContainer = styled.div`
  display: grid;

  grid-template-columns: 1fr;
  grid-template-rows: 1fr 1fr;
  grid-template-areas:
    "upper"
    "lower"
`

export const Upper = styled.div`
  grid-area: upper;
  background: white;
  margin-bottom: 10px;
  padding: 10px;

  display: grid;
  grid-template-columns: 210px 2fr auto;
  grid-template-rows: auto 1fr;
  grid-template-areas:
    "header header header"
    "avatar info ."
`

export const Lower = styled.div`
  grid-area: lower;
  background: white;

  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: 1fr 1fr;
  grid-template-areas:
    "upper"
    "lower"
`

export const Header = styled.div`
  grid-area: header;
  justify-self: start;
  padding-bottom: 10px;
`

export const Avatar = styled.div`
  grid-area: avatar;
  justify-self: center;

  svg, img {
    display: block;
  }
`

export const UserInfo = styled.div`
  grid-area: info;
  justify-self: start;
`
