import React from "react";
import PropTypes from "prop-types";
import * as Style from "./styles";

const CardContainer = ({ actions, title, children, style, titleColor }) => {
  return (
    <Style.CardContainer style={style}>
      <Style.Header>
        <Style.StyledText color={titleColor}>{title}</Style.StyledText>
        <Style.Actions>{actions}</Style.Actions>
      </Style.Header>
      <Style.StyledChildren>{children}</Style.StyledChildren>
    </Style.CardContainer>
  );
};

CardContainer.propTypes = {
  titleColor: PropTypes.string,
  actions: PropTypes.any,
  title: PropTypes.string.isRequired,
  children: PropTypes.any.isRequired,
  style: PropTypes.object,
};

export default CardContainer;
