import styled from "styled-components";
import Chip from '@material-ui/core/Chip';
import ClearSharpIcon from '@material-ui/icons/ClearSharp';

export const StyledChip = styled(Chip)`
  margin: 2px;
  &.MuiChip-root {
    color: white;
    background-color: #7288AB;
    border-radius: 5px;
  }
`;

export const StyledX = styled(ClearSharpIcon)`
  &.MuiChip-deleteIcon {
    color: #FF9726;
  }
`;

