import React, { useState, useRef, useEffect, useCallback } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { GoogleMap, Marker } from "@react-google-maps/api";
import Collapse from "@material-ui/core/Collapse";
import AssetFilterTable from "../../components/asset-filter-table";

import {
  addLocationEffect,
  editLocationEffect,
  getUserLocationEffect,
  getLocationDistanceEffect,
  deleteLocationEffect,
  formatLocationString,
} from "../effects/";
import {
  deleteAssetEffect,
  getAssetsEffect,
  getFieldDefinitionsEffect,
  updateAssetEffect,
} from "../../assets/effects/";
import { setAlert } from "../../components/alert/actions";

import AddLocation from "../components/add-location";
import CardContainer from "../../components/card-container";
import { Table } from "../../components/table/Table";
import { DeleteDialog } from "../../components/dialog/DeleteDialog.js";
import Layout from "../../components/layout";
import LocationDetails from "../components/location-details";
import Button from "../../components/button";
import CircularProgress from "../../components/circular-progress";
import * as Style from "./styles";
import * as MapsDefaults from "../../shared/maps/defaults";

const Locations = ({
  dispatch,
  assets,
  fieldDefinitions,
  org_id,
  locations,
  distance,
  user_latitude,
  user_longitude,
  attempted_user_location_load,
  mapsLoaded,
}) => {
  useEffect(() => {
    dispatch(getAssetsEffect());
    dispatch(getFieldDefinitionsEffect());
    if (!attempted_user_location_load) {
      dispatch(getUserLocationEffect());
    }
  }, [
    dispatch,
    org_id,
    user_latitude,
    user_longitude,
    attempted_user_location_load,
  ]);

  // add location state
  const formRef = useRef();

  const initialState = {
    name: "",
    address1: "",
    address2: "",
    city: "",
    region: "",
    postalcode: "",
    country: "",
    latitude: "",
    longitude: "",
  };


  const [locationState, setLocationState] = useState(initialState);
  const disabled = !locationState.name;
  const handleChange = (event) => {
    setLocationState({
      ...locationState,
      [event.target.name]: event.target.value,
    });
  };
  const handleSubmit = async (event) => {
    event.preventDefault();
    formRef.current.reportValidity();

    dispatch(addLocationEffect(locationState));
    openLocationAddDrawer(false);
    setIsLocationSelected(true);
  };

  const handleCancel = () => {
    openLocationAddDrawer(false);
    setIsLocationSelected(true);
  };
  // location edit state
  const [edit, setEdit] = useState(false);
  const [selectedLocation, setSelectedLocation] = useState(null);
  const [openDeleteLocation, setOpenDeleteLocation] = useState(false);

  const handleCloseDeleteLocation = () => {
    setOpenDeleteLocation(false);
    setSelectedLocation(null);
  };

  const handleClickOpenDeleteLocation = (locationId) => {
    setOpenDeleteLocation(true);
    setSelectedLocation(locationId);
  };

  const handleEditSave = (event, locationId) => {
    event.preventDefault();
    formRef.current.reportValidity();

    dispatch(editLocationEffect(locationState, locationId));
    setLocationState(initialState);
    setIsLocationSelected(true);
    setEdit(false);
    setLocationDrawer(false);
  };

  const handleDeleteLocation = (locationId) => {
    dispatch(deleteLocationEffect(locationId));
    setOpenDeleteLocation(false);
    setLocationState(initialState);
    setIsLocationSelected(true);
    setEdit(false);
    setLocationDrawer(false);
  };

  // location drawer states
  const [locationAddDrawer, setLocationAddDrawer] = useState(false);
  const [locationDrawer, setLocationDrawer] = useState(false);
  const openLocationDrawer = (isOpen) => {
    setLocationDrawer(isOpen);
    if (isOpen) {
      setLocationAddDrawer(false);
    }
  };
  const openLocationAddDrawer = (isOpen) => {
    setLocationAddDrawer(isOpen);
    if (isOpen) {
      setLocationDrawer(false);
    }
  };

  // asset table state
  const [selectedAsset, setSelectedAsset] = useState(null);
  const [openDeleteAsset, setOpenDeleteAsset] = useState(false);
  const handleDeleteAsset = (asset_id) => {
    dispatch(deleteAssetEffect(asset_id));
    setOpenDeleteAsset(false);
  };
  const handleClickOpenDeleteAsset = (asset_id) => {
    setOpenDeleteAsset(true);
    setSelectedAsset(asset_id);
  };
  const handleCloseDeleteAsset = () => {
    setOpenDeleteAsset(false);
    setSelectedAsset(null);
  };
  const handleRowUpdate = (event, assetState, row_id) => {
    event.preventDefault();
    dispatch(updateAssetEffect(row_id, assetState));
  };

  // location filter state
  const [isLocationSelected, setIsLocationSelected] = useState(false);
  const filterAssets = (location, assets) => {
      return assets.filter((asset) => asset.fields.location_id === location.id);
  }
  const filterAssetsByLocation = (location) => {
    if (!isLocationSelected) {
      return assets;
    } else {
      return filterAssets(location, assets);
    }
  };

  const assetCount = filterAssetsByLocation(locationState).length;

  // google maps
  const [map, setMap] = useState(null);
  const [points, setPoints] = useState([]);

  const initialBounds = new window.google.maps.LatLngBounds();
  if (locations.length) {
    locations
      .filter((l) => l.latitude != null && l.longitude != null)
      .forEach((l) =>
        initialBounds.extend({
          lat: l.latitude,
          lng: l.longitude,
        })
      );
  } else {
    initialBounds.extend(
      attempted_user_location_load
        ? {
            lat: user_latitude,
            lng: user_longitude,
          }
        : MapsDefaults.center
    );
  }

  if (map) {
    var filteredPoints = points.filter((p) => p.lat != null && p.lng != null)
    if (filteredPoints.length === 0) {
      map.fitBounds(initialBounds);
    } else {
      const bounds = new window.google.maps.LatLngBounds();
      filteredPoints.forEach((p) => bounds.extend(p));
      map.fitBounds(bounds);
    }
  }

  const onMapLoad = useCallback(
    function callback(m) {
      setMap(m);
    },
    [setMap]
  );

  const onUnmount = useCallback(
    function callback() {
      setMap(null);
    },
    [setMap]
  );

  const locationSelectFactory = (l) => {
    return () => {
      const point = [{ lat: l.latitude, lng: l.longitude }];
      if (locationState.id === l.id) {
        setPoints(
          locations
            .map((l, i) => {
              return { lat: l.latitude, lng: l.longitude };
            })
        );
        setLocationState(initialState);
        setIsLocationSelected(false);
        openLocationDrawer(false);
      } else {
        setPoints(point);
        dispatch(getLocationDistanceEffect(l));
        setLocationState(l);
        setIsLocationSelected(true);
        openLocationDrawer(true);
        setEdit(false);
      }
    };
  };

  return (
    <Layout>
      {attempted_user_location_load ? null : <CircularProgress />}
      <Style.PageContainer>
        <Style.Upper>
          <CardContainer
            title="Locations"
            style={{
              gridArea: "table"
            }}
            actions={
              <Button
                size="small"
                fontSize={12}
                onClick={() => {
                  openLocationAddDrawer(true);
                  setLocationState(initialState);
                  setIsLocationSelected(false);
                  setEdit(false);
                }}
              >
                Add Location
              </Button>
            }
            titleColor="white"
          >
            <AssetFilterTable
              filteringObjects={locations}
              assets={assets}
              objectState={locationState}
              onRowClick={locationSelectFactory}
              getAssetCountByObject={filterAssets}
            />
          </CardContainer>
          <Style.Map>
            {mapsLoaded ? (
              <GoogleMap
                mapContainerStyle={MapsDefaults.style}
                zoom={MapsDefaults.zoom}
                onLoad={onMapLoad}
                onUnmount={onUnmount}
                options={MapsDefaults.options}
              >
                {map &&
                  locations
                    .filter((l) => l.latitude != null && l.longitude != null)
                    .map((l, i) => (
                      <Marker
                        key={i}
                        position={{ lat: l.latitude, lng: l.longitude }}
                        onClick={locationSelectFactory(l)}
                      />
                    ))}
              </GoogleMap>
            ) : (
              "Loading Map"
            )}
          </Style.Map>
        </Style.Upper>
        <Style.Divider />
        <Style.Lower>
          <CardContainer
            title={`All Assets (${assetCount > 0 ? assetCount : "..."})`}
            gridArea="table"
            actions={
              locationDrawer && (
                <Button
                  size="small"
                  fontSize={12}
                  onClick={() => {
                    setEdit(true);
                  }}
                >
                  Edit Location
                </Button>
              )
            }
            titleColor="white"
          >
            {locationAddDrawer && (
              <AddLocation
                formRef={formRef}
                handleCancel={handleCancel}
                handleChange={handleChange}
                handleSubmit={handleSubmit}
                disabled={disabled}
              />
            )}
            <Collapse in={locationDrawer} timeout="auto" unmountOnExit>
              {locationDrawer && (
                <LocationDetails
                  locationState={locationState}
                  dispatch={dispatch}
                  edit={edit}
                  setEdit={setEdit}
                  formRef={formRef}
                  distance={distance}
                  setAlert={setAlert}
                  formatLocationString={formatLocationString}
                  user_latitude={user_latitude}
                  user_longitude={user_longitude}
                  handleChange={handleChange}
                  handleClickOpenDeleteLocation={handleClickOpenDeleteLocation}
                  handleEditSave={handleEditSave}
                  locationAssets={filterAssetsByLocation(locationState)}
                  fieldDefinitions={fieldDefinitions}
                />
              )}
            </Collapse>
            <Style.ItemsTableContainer>
              <Table
                fieldDefinitions={fieldDefinitions}
                data={filterAssetsByLocation(locationState)}
                locations={locations}
                handleClickOpenDeleteAsset={handleClickOpenDeleteAsset}
                handleRowUpdate={handleRowUpdate}
              />
            </Style.ItemsTableContainer>
          </CardContainer>
        </Style.Lower>
      </Style.PageContainer>
      {openDeleteAsset && (
        <DeleteDialog
          title={"Delete Asset"}
          open={openDeleteAsset}
          handleClose={handleCloseDeleteAsset}
          handleDelete={handleDeleteAsset}
          id={selectedAsset}
        />
      )}
      {openDeleteLocation && (
        <DeleteDialog
          title={"Delete Location"}
          open={openDeleteLocation}
          handleClose={handleCloseDeleteLocation}
          handleDelete={handleDeleteLocation}
          id={selectedLocation}
        />
      )}
    </Layout>
  );
};

const mapStateToProps = (state) => ({
  assets: state.assets.assets,
  fieldDefinitions: state.assets.fieldDefinitions,
  org_id: state.user.org_id,
  distance: state.location.distance,
  locations: state.location.locations,
  mapsLoaded: state.maps.isLoaded,
  user_latitude: state.location.user_latitude,
  user_longitude: state.location.user_longitude,
  attempted_user_location_load: state.location.attempted_user_location_load,
});

Locations.propTypes = {
  assets: PropTypes.array.isRequired,
  fieldDefinitions: PropTypes.array.isRequired,
  org_id: PropTypes.string.isRequired,
  distance: PropTypes.string.isRequired,
  locations: PropTypes.array.isRequired,
  mapsLoaded: PropTypes.bool.isRequired,
  user_latitude: PropTypes.number,
  user_longitude: PropTypes.number,
  attempted_user_location_load: PropTypes.bool.isRequired,
};

export default connect(mapStateToProps)(Locations);
