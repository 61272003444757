import React from "react";
import { BrowserRouter as Router, Switch } from "react-router-dom";

import ProtectedRoute from "./shared/routes/ProtectedRoute.js";
import PublicRoute from "./shared/routes/PublicRoute.js";
import "./App.css";

import Locations from "./locations/containers/Locations";
import Login from "./login/containers/Login";
import Signup from "./sign-up/containers/Signup";
import Asset from "./assets/containers/Assets";
import NotFound from "./shared/pages/404/NotFound";
import Profile from "./profile/containers/Profile";
import Settings from "./settings/containers/Settings";
import Alert from "./components/alert";

import * as routes from "./shared/routes/paths";

require("dotenv").config();

function App() {
  return (
    <Router>
      <div className="App">
        <header className="App-header">
          <Alert />
          <Switch>
            <PublicRoute exact path={routes.LOGIN} component={Login} />
            <PublicRoute exact path={routes.SIGNUP} component={Signup} />
            <ProtectedRoute exact path={routes.ITEMS} component={Asset} />
            <ProtectedRoute exact path={routes.LOCATIONS} component={Locations} />
            <ProtectedRoute exact path={routes.PROFILE} component={Profile} />
            <ProtectedRoute exact path={routes.SETTINGS} component={Settings} />
            <ProtectedRoute path={routes.NOTFOUND} component={NotFound} />
          </Switch>
        </header>
      </div>
    </Router>
  );
}

export default App;
