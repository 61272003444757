import styled from "styled-components";
import { Alert } from "@material-ui/lab";

export const StyledAlert = styled(Alert)`
  position: fixed;
  z-index: 100;
  left: 0;
  top: 0;
  right: 0;
  bottom: 2;
`;
