import styled from "styled-components";
import CircularProgress from '@material-ui/core/CircularProgress';

export const StyledCircularProgress = styled(CircularProgress)`
    position: fixed;
    z-index: 99;
    top: 50%;
    left: 49%;
    && {
      &.MuiCircularProgress-colorPrimary {
        color: #1a90ab;
      }
    }
`;
