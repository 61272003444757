import {
  FETCHED_LOCATION,
  FETCHED_LOCATION_ERROR,
  GET_LOCATIONS,
  GET_LOCATIONS_ERROR,
  GET_LOCATION,
  GET_LOCATION_ERROR,
  DELETE_LOCATION,
  DELETE_LOCATION_ERROR,
  CREATE_LOCATION,
  CREATE_LOCATION_ERROR,
  EDIT_LOCATION,
  EDIT_LOCATION_ERROR,
  USER_LOCATION_REQUEST,
  USER_LOCATION_FETCHED,
  USER_LOCATION_ERROR,
  LOCATION_DISTANCE_REQUEST,
  LOCATION_DISTANCE_FETCHED,
  LOCATION_DISTANCE_ERROR
} from "./ActionTypes";

export const fetchedLocation = (location) => ({
  type: FETCHED_LOCATION,
  payload: location,
});

export const fetchedLocationError = (error) => ({
  type: FETCHED_LOCATION_ERROR,
  error: error,
});

export const createLocation = () => ({
  type: CREATE_LOCATION,
});

export const createLocationError = (error) => ({
  type: CREATE_LOCATION_ERROR,
  error: error,
});

export const getLocations = (res) => ({
  type: GET_LOCATIONS,
  payload: res,
});

export const getLocation = (locationId) => ({
  type: GET_LOCATION,
  payload: locationId,
});

export const userLocationRequest = () => ({
  type: USER_LOCATION_REQUEST
});

export const userLocationFetched = (latitude, longitude) => ({
  type: USER_LOCATION_FETCHED,
  payload: {"latitude": latitude, "longitude": longitude},
});

export const locationDistanceFetched = (distance) => ({
  type: LOCATION_DISTANCE_FETCHED,
  payload: distance,
});

export const locationDistanceRequest = () => ({
  type: LOCATION_DISTANCE_REQUEST,
});

export const getLocationError = (error) => ({
  type: GET_LOCATION_ERROR,
  payload: error,
});

export const getLocationsError = (error) => ({
  type: GET_LOCATIONS_ERROR,
  error: error,
});

export const deleteLocation = () => ({
  type: DELETE_LOCATION,
});

export const deleteLocationError = (error) => ({
  type: DELETE_LOCATION_ERROR,
  payload: error,
});

export const editLocation = () => ({
  type: EDIT_LOCATION,
});

export const editLocationError = (error) => ({
  type: EDIT_LOCATION_ERROR,
  payload: error,
});

export const userLocationError = (error) => ({
  type: USER_LOCATION_ERROR,
  payload: error,
});

export const locationDistanceError = (error) => ({
  type: LOCATION_DISTANCE_ERROR,
  error: error,
});
