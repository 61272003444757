import React, { useState } from "react";
import PropTypes from "prop-types";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import Paper from "@material-ui/core/Paper";

import * as Style from "./styles";

// An asset filter table is a table of objects that users create and use to slice and group assets.
const AssetFilterTable = ({
  filteringObjects,
  assets,
  objectState,
  onRowClick,
  getAssetCountByObject,
}) => {
  const [stateIndex, setStateIndex] = useState(null);

  return (
    <Paper elevation={0}>
      <Style.StyledTableContainer>
        <Table>
          <TableBody>
            {filteringObjects.map((object, index) => (
              <Style.StyledTableRow
                onMouseEnter={() => {
                  setStateIndex(index);
                }}
                onMouseLeave={() => {
                  setStateIndex(null);
                }}
                key={index}
                selected={objectState.id === object.id}
                style={{
                  cursor: "pointer",
                }}
                onClick={onRowClick(object)}
              >
                <Style.StyledLocationNameCell component="th" scope="row">
                  {object.name}
                </Style.StyledLocationNameCell>
                <Style.StyledCountCell
                  style={{
                    width: "10%",
                  }}
                  align="center"
                >
                  <Style.ItemRecordCount
                    selected={objectState.id === object.id}
                    index={stateIndex === index}
                  >
                    {getAssetCountByObject(object, assets).length}
                  </Style.ItemRecordCount>
                </Style.StyledCountCell>
              </Style.StyledTableRow>
            ))}
          </TableBody>
        </Table>
      </Style.StyledTableContainer>
    </Paper>
  );
};

AssetFilterTable.propTypes = {
  filteringObjects: PropTypes.array.isRequired,
  assets: PropTypes.array.isRequired,
  objectState: PropTypes.object.isRequired,
  onRowClick: PropTypes.func,
  getAssetCountByObject: PropTypes.func,
};

export default AssetFilterTable;
