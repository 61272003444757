import styled from "styled-components";

import { CustomMuiTheme } from "../../../shared/styles/theme.js";

export const fontSize = '1rem';

export const UserLabel = styled.div`
  text-align: right;
  display: grid;
`

export const User = styled(UserLabel)`
  grid-area: name;
  align-self: end;
`

export const Org = styled(UserLabel)`
  grid-area: org;
  align-self: start;
`

export const Avatar = styled.img`
  grid-area: avatar;
  border-radius: 10%;
`

export const Option = styled.div`
  display: block;
  text-align: left;
  &:hover {
    background-color: ${CustomMuiTheme.colors.offSet};
    cursor: pointer;
  }
  border-bottom: 1px solid ${CustomMuiTheme.colors.offSet};
  svg {
    padding-right: 10px
  }

  a {
    padding: 10px;
    display: block;
  }

  a:link, a:visited, a:hover, a:active {
    color: black;
    text-decoration: none;
  }
`

export const ClickableOption = styled.div`
  padding: 10px;
`

export const DropDownContent = styled.div`
  background: white;
  border-radius: 10%;
  border: 1px solid rgb(0, 0, 0, 0.2);
  color: black;
  display: none;
  font-size: ${fontSize};
  grid-area: logout;
  overflow: hidden;
  position: absolute;
  right: 0;
  top: 50px;
  width: fit-content;
  white-space: nowrap;
  z-index: 1;

  ${Option}:last-child {
    border-bottom: none;
  }
`

export const DropDown = styled.div`
  color: white;
  font-size: 0.8rem;
  height: 100%;
  align-items: center;

  display: grid;
  grid-template-columns: auto;
  grid-template-rows: 1fr 1fr;
  grid-template-areas:
    "name avatar"
    "org avatar";

  &:hover ${DropDownContent} {
    display: block;
  }
`
