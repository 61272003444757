import styled from "styled-components";

export const PageContainer = styled.div`
  display: grid;
  height: 100vh;

  grid-template-columns: 1fr;
  grid-template-rows: 65px 1fr;
  grid-template-areas:
    " . "
    " paper ";
`;

export const Text = styled.p`
  color: ${({ selected, theme }) =>
    selected ? theme.colors.default : theme.colors.secondaryGrey};
  font-size: 16px;
  text-decoration: none;
  cursor: pointer;
  :hover {
    font-weight: bold;
  }
`;

export const StyledDiv = styled.div`
  display: grid;
  grid-area: ${({ gridArea }) => gridArea};
  height: 100%;
  width: 100%;
  align-items: center;
  justify-items: center;
  font-weight: ${({ selected }) => selected && "bold"};
  background-color: ${({ selected, theme }) =>
    selected && theme.colors.selected};
  outline: 2px solid
    ${({ selected, theme }) =>
      selected ? theme.colors.accent : theme.colors.navBar};
  outline-offset: -2px;
`;

export const PaperContainer = styled.div`
  display: grid;
  grid-area: paper;

  align-items: center;
  width: 100%;
  padding: 0px 0px 15px 0px;
`;
