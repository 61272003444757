import React, { useState, useRef } from "react";
import PropTypes from "prop-types";

import * as Style from "./styles";
import AssetFormFields from "./components/asset-fields";
import AssetFormWarranty from "./components/asset-warranty";
import AssetPhoto from "./components/asset-photo";
import AssetHelpers from "./components/asset-helper";
import AssetAttachments from "./components/asset-attachments";
import Button from "../../../components/button";

const AssetForm = ({
  initialAssetFieldState,
  initialAssetWarrantyState,
  initialAssetHelpersState,
  initialAssetAttachmentState,
  fieldDefinitions,
  handleSave,
  handleCancel,
  locations,
}) => {
  const [assetFieldState, setAssetFieldState] = useState(
    initialAssetFieldState
  );
  const handleFieldChange = (event) => {
    const newState = { ...assetFieldState };
    let value = event.target.value;
    if (event.target.type === "number") {
      fieldDefinitions.forEach((fieldDefinition) => {
        if (fieldDefinition.id === event.target.name) {
          if ("not_negative" in fieldDefinition) {
            if (fieldDefinition["not_negative"] && value < 0) {
              value = assetWarrantyState[event.target.name];
            }
          }
        }
      });
      value = Number(value);
    }

    newState[event.target.name] = value;
    setAssetFieldState(newState);
  };

  const [assetWarrantyState, setAssetWarrantyState] = useState(
    initialAssetWarrantyState
  );
  const handleWarrantyChange = (event) => {
    const newState = { ...assetWarrantyState };
    let value = event.target.value;

    if (event.target.type === "number") {
      value = Number(value)
      if (value < 0) {
        value = assetWarrantyState[event.target.name]
      }
    }

    newState[event.target.name] = value;
    setAssetWarrantyState(newState);
  };

  const [assetHelpersState, setAssetHelpersState] = useState({
    chips: initialAssetHelpersState,
    input: "",
  });
  const handleHelpersDelete = (index) => {
    const newHelperState = { ...assetHelpersState };
    newHelperState.chips.splice(index, 1);
    setAssetHelpersState(newHelperState);
  };
  const handleHelpersChange = (event) => {
    const newHelperState = { ...assetHelpersState };
    newHelperState["input"] = event.target.value;
    setAssetHelpersState(newHelperState);
  };
  const handleHelpersSave = (event) => {
    if (event.keyCode === 13 || event.keyCode === 9) {
      const newHelperState = { ...assetHelpersState };
      newHelperState["chips"].push(assetHelpersState.input);
      newHelperState["input"] = "";
      setAssetHelpersState(newHelperState);
    }
  };

  const [assetAttachmentState, setAssetAttachmentState] = useState(initialAssetAttachmentState);
  const handleAttachmentChange = (event) => {
    const newAttachmentState = {...assetAttachmentState};
    // Set file and object_name
    newAttachmentState[event.target.files[0].name] = {"file_path": event.target.files[0].name, "file": event.target.files[0]}
    setAssetAttachmentState(newAttachmentState);
  };
  const handleAttachmentDelete = (attachmentName) => {
    const newAttachmentState = {...assetAttachmentState};
    delete newAttachmentState[attachmentName]
    setAssetAttachmentState(newAttachmentState);
  };

  let disabled = false;
  // TODO: Could pull up into redux state if we need faster render
  fieldDefinitions.forEach((fieldDefinition) => {
    if ("required" in fieldDefinition) {
      if (fieldDefinition["required"] && !assetFieldState[fieldDefinition.id]) {
        disabled = true;
      }
    }
  });

  const handleSaveOnClick = (event) => {
    if (disabled === false) {
      handleSave(
        event,
        assetFieldState,
        assetWarrantyState,
        assetHelpersState.chips,
        assetAttachmentState
      );
    }
  };

  const formRef = useRef();
  return (
    <Style.FormContainer ref={formRef}>
      <Style.AssetContainer>
        <Style.FieldContainer>
          <AssetFormFields
            fieldsState={assetFieldState}
            fieldDefinitions={fieldDefinitions}
            locations={locations}
            handleChange={handleFieldChange}
          />
        </Style.FieldContainer>
        <Style.IconsContainer>
          <Style.StyledDiv gridArea={"save"}>
            <Button
              variants={"save"}
              style={{ padding: "1px" }}
              handleClick={(event) => handleSaveOnClick(event)}
              disabled={disabled}
            />
          </Style.StyledDiv>
          <Style.StyledDiv gridArea={"cancel"}>
            <Button
              variants={"cancel"}
              style={{ padding: "1px" }}
              handleClick={handleCancel}
            />
          </Style.StyledDiv>
        </Style.IconsContainer>
        <Style.WarrantyContainer>
          <AssetFormWarranty
            warrantyState={assetWarrantyState}
            handleChange={handleWarrantyChange}
          />
        </Style.WarrantyContainer>
        <Style.PhotoContainer>
          <AssetPhoto
            assetFieldState={assetFieldState}
            fieldDefinitions={fieldDefinitions}
            handleChange={handleFieldChange}
          />
        </Style.PhotoContainer>
        <Style.StyledTextContainer>
          <Style.StyledText1>
            Helpers
          </Style.StyledText1>
          <Style.StyledText2>
  (Start typing and press "enter" or "tab" to add)
          </Style.StyledText2>
        </Style.StyledTextContainer>
        <Style.HelpersContainer>
          <AssetHelpers
            assetHelpersState={assetHelpersState}
            handleChange={handleHelpersChange}
            handleDelete={handleHelpersDelete}
            handleSave={handleHelpersSave}
          />
        </Style.HelpersContainer>
        <Style.AttachmentContainer>
          <AssetAttachments
            assetAttachmentState={assetAttachmentState}
            fieldDefinitions={fieldDefinitions}
            handleChange={handleAttachmentChange}
            handleDelete={handleAttachmentDelete}
          />
        </Style.AttachmentContainer>
      </Style.AssetContainer>
    </Style.FormContainer>
  );
};

AssetForm.propTypes = {
  initialAssetFieldState: PropTypes.object,
  initialAssetWarrantyState: PropTypes.object,
  initialAssetHelpersState: PropTypes.array,
  initialAssetAttachmentState: PropTypes.object,
  fieldDefinitions: PropTypes.array,
  handleSave: PropTypes.func,
  handleCancel: PropTypes.func,
  locations: PropTypes.array,
};

export default AssetForm;
