import React from "react";
import * as Style from "./styles";
import HelperChip from "../../../../components/helper-chip";

const HelpersTabContent = ({ helpersInfo }) => {

  return (
    <Style.TabContainer>
      <Style.Header>
        <Style.StyledText>
          Helpers
        </Style.StyledText>
      </Style.Header>
      <Style.Content>
        {helpersInfo.map((helper, index) => (
          <HelperChip key={index}
            label={helper}
          />
	))}
      </Style.Content>
    </Style.TabContainer>
  )
};

HelpersTabContent.propTypes = {};

export default HelpersTabContent;
