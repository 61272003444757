import React from "react";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableRow from "@material-ui/core/TableRow";
import PropTypes from "prop-types";

import * as Style from "./styles";

import Row from "../row/Row.js";

export const Table = ({
  fieldDefinitions,
  data,
  locations,
  handleClickOpenDeleteAsset,
  handleRowUpdate,
  handleAttachmentDownload
}) => {
  if (data == null) {
    data = [];
  }
  return data.length > 0 ? (
    <Style.StyledTable aria-label="collapsible table">
      <Style.StyledTableHead>
        <TableRow>
          <TableCell />
          {fieldDefinitions.map((fieldDefinition, index) => (
            <Style.StyledTableCell component="th" key={index}>
              {fieldDefinition.name}
            </Style.StyledTableCell>
          ))}
        </TableRow>
      </Style.StyledTableHead>
      <TableBody>
        {data.map((row, index) => (
          <Row
            key={index}
            row={row}
            fieldDefinitions={fieldDefinitions}
            locations={locations}
            handleClickOpenDeleteAsset={handleClickOpenDeleteAsset}
            handleRowUpdate={handleRowUpdate}
            handleAttachmentDownload={handleAttachmentDownload}
          />
        ))}
      </TableBody>
    </Style.StyledTable>
  ) : null;
};

Table.propTypes = {
  fieldDefinitions: PropTypes.array,
  data: PropTypes.array,
  locations: PropTypes.array,
  handleClickOpenDeleteAsset: PropTypes.func,
  handleRowUpdate: PropTypes.func,
  handleAttachmentDownload: PropTypes.func,
};
