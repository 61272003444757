import styled from "styled-components";
import TableRow from "@material-ui/core/TableRow";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";

export const StyledTableRow = styled(TableRow)``;

export const DropdownContainer = styled.div`
  display: grid;
  background: #f5f7f9;

  grid-template-columns: auto;
  grid-template-rows: auto;
  grid-template-areas: " container ";
`;

export const AssetDropdownContainer = styled.div`
  display: grid;
  grid-area: container;

  margin: 15px;

  grid-template-columns: 1fr 5px 70px 3fr 5px 30px;
  grid-template-rows: auto;
  grid-template-areas: " image_container . tabs info_container . actions ";
`;

export const AssetImageContainer = styled.div`
  display: grid;
  grid-area: image_container;

  border: 1px solid ${({ theme }) => theme.colors.tableHeader};
  padding: 10px;
`;

export const AssetTabsContainer = styled.div`
  display: grid;
  grid-area: tabs;
  padding 5px;
`;

export const StyledTabs = styled(Tabs)``;

export const AssetTabs = styled.div`
  display: grid;
  grid-area: tabs;
  padding 5px;
`;

export const AssetInfoContainer = styled.div`
  display: grid;
  grid-area: info_container;
  border: 2px solid #445b79;
  border-radius: 5px;
`;

export const AssetActions = styled.div`
  display: grid;
  grid-area: actions;

  grid-template-columns: 10px;
  grid-template-rows: auto;
  grid-template-areas:
    " edit "
    " split "
    " copy "
    " . "
    " delete ";
`;

export const StyledDiv = styled.div`
  display: grid;
  grid-area: ${({ gridArea }) => gridArea};
  padding: ${({ padding }) => padding};
`;

export const StyledTab = styled(Tab)`
  &.MuiTab-root {
    min-width: 0px;
  }
  &.MuiTab-textColorInherit.Mui-selected {
    color: #ff9726;
    background: #f5f7f9;
  }
`;
