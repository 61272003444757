import styled from "styled-components";
import TextField from "@material-ui/core/TextField";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';

export const InputSelectContainer = styled.div`
  display: grid;
  grid-area: ${({ gridArea }) => gridArea};

  grid-template-columns: 1fr 1fr;
  grid-template-rows: auto;
  grid-template-areas:
    " label label "
    " input select  ";
`;

export const StyledInputLabel = styled(InputLabel)`
  display: grid;
  grid-area: label;
  &.MuiInputLabel-root {
    display: inherit;
    font-size: 14px;
    color: black;
  }

  justify-items: flex-start;
  margin-bottom: 5px;
`;

export const StyledTextField = styled(TextField)`
  display: grid;
  grid-area: input;

  &.MuiFormControl-root {
    display: inherit;
  }
  & .MuiOutlinedInput-root {
    font-size: 14px;
    background: white;
    &.Mui-focused fieldset {
      border-color: ${(props) => props.theme.colors.input};
    }
  }
`;

export const StyledSelect = styled(Select)`
  display: grid;
  grid-area: select;
  & .MuiSelect-select {
    font-size: 14px;
    padding-top: 10.5px;
    padding-bottom: 10.5px;
    text-align: left;
  },
  &.MuiInputBase-root {
    background: white;
  }
`;

export const StyledMenuItem = styled(MenuItem)`
  &.MuiMenuItem-root {
    font-size: 14px;
  },
`;
