import styled from "styled-components";
import Link from "../../components/link";
import Button from "../../components/button";

export const Background = styled.div`
  background: ${(props) => props.theme.colors.default};
  padding: 20px;
  width: 20%;
  border-radius: 5px;
`;

export const StyledLink = styled(Link)`
  font-weight: normal;
  font-size: 14px;
  text-decoration: none;
`;

export const StyledText = styled.p`
  grid-area: text;
  text-align: left;
  font-size: 14px;
  padding-left: 10px;
`;

export const StyleErrorMessage = styled.p`
  grid-area: text;
  text-align: left;
  font-size: 12px;
  color: red;
`;
export const DetailsContainer = styled.div`
  display: grid;

  align-items: top;
  grid-template-columns: 10px 1fr 1fr;
  grid-template-rows: auto;
  grid-template-areas:
    " agreement agreement agreement "
    " button button button "
    " links links links ";
`;

export const CheckBox = styled.input`
  display: grid;
  grid-area: checkbox;
  defaultchecked: false;
  align-items: center;
`;

export const AgreementContainer = styled.div`
  display: grid;
  grid-area: agreement;
  padding-bottom: 5px;

  grid-template-columns: 14px 1fr;
  grid-template-rows: auto;
  grid-template-areas: "checkbox text";
`;

export const LinksContainer = styled.div`
  display: grid;
  grid-area: links;
  text-align: left;
  padding-top: 15px;

  grid-template-columns: 1fr;
  grid-template-rows: auto;
  grid-template-areas:
    "signIn"
    "forgotPassword";
`;

export const StyledForm = styled.form`
  display: grid;
  padding: 0px 0px 0px 0px;

  grid-template-columns: 1fr;
  grid-template-rows: auto;
  grid-template-areas:
    "email"
    "firstName"
    "lastName"
    "password"
    "passwordConf"
    "organization";
`;

export const StyledButton = styled(Button)`
  grid-area: button;
`;
