import React from "react";
import * as Style from "./styles";
import Input from "../../../../../components/input";
import Select from "../../../../../components/select";

const inputTypeMap = {
  "string": "text",
  "number": "number",
  "bool": "bool"
}

const AssetFormFields = ({
	fieldsState, 
	fieldDefinitions,
        locations,
	handleChange
}) => {
    const formFieldsArray = fieldDefinitions
      .filter(fieldDefinition => {
        if ("editable" in fieldDefinition && fieldDefinition.editable === false) {
          return false
        } else return true
      })
      .map((fieldDefinition) => {
        let value = fieldsState[fieldDefinition.id] || ''
        let column

        // hacking in location logic since it's the only field needing logic to manage it's 
        // location_id
        if (fieldDefinition.id === "location") {
          if (value === null) {
            value = ""
          }
          const locationOptions = locations.map((location) => {
            return { "label": location.name, "value": location.id }
          })
          locationOptions.unshift({"label": " ", "value": null})
          value = fieldsState["location_id"]
          column = 
            <Style.Field key={fieldDefinition.id}>
              <Select 
                  value={value}
                  options={locationOptions}
                  id={"location_id"}
                  name={"location_id"}
                  type={"select"}
                  labelName={fieldDefinition.name}
                  size='small'
                  variant='outlined'
                  handleChange={handleChange}
              />
            </Style.Field>
        return column
        }

        if (fieldDefinition.type === "string_select") {
          const options = fieldDefinition.extra_info.map((option) => {
            return {
              "label": option,
              "value": option
           }})
          column = 
            <Style.Field key={fieldDefinition.id}>
              <Select 
                  value={value}
                  options={options}
                  id={fieldDefinition.id}
                  name={fieldDefinition.id}
                  type={inputTypeMap[fieldDefinition.type]}
                  labelName={fieldDefinition.name}
                  size='small'
                  variant='outlined'
                  handleChange={handleChange}
              />
            </Style.Field>
        } else {
          column = 
            <Style.Field key={fieldDefinition.id}>
              <Input
                value={value}
                id={fieldDefinition.id}
                name={fieldDefinition.id}
                type={inputTypeMap[fieldDefinition.type]}
                labelName={fieldDefinition.name}
                size='small'
                variant='outlined'
                handleChange={handleChange}
              />
            </Style.Field>
        }
        return column
      }) 
    return formFieldsArray
};

export default AssetFormFields;
