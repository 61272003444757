import { Signup } from "../../api/user.service";
import { auth } from "../../shared/auth/firebase";
import { userSignup, userSignupError } from "../actions";

export const signupEffect = (userInfo) => async (dispatch) => {
try {
    await Signup(
      userInfo.email,
      userInfo.password,
      userInfo.first_name,
      userInfo.last_name,
      userInfo.organization,
      userInfo.password_confirmation,
      userInfo.check_box,
      userInfo.password_match
    );
    await auth.signInWithEmailAndPassword(userInfo.email, userInfo.password);
    dispatch(userSignup())
} catch(error) {
    dispatch(userSignupError(error.response.data));
  }
};
