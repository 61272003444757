import styled from "styled-components";

export const Layout = styled.div`
  display: grid;
  width: 100%;

  grid-template-columns: 0.2fr 3fr 0.2fr;
  grid-template-rows: auto;
  grid-template-areas:
    " navbar navbar navbar "
    " . children . ";
`;
