import React from "react";
import * as Style from "./styles";

const HelperChip = ({
	label,
	onDelete,
}) => {
  if (onDelete != null) {
    return (
        <Style.StyledChip
          size="small"
          label={label}
          onDelete={onDelete}
          deleteIcon={<Style.StyledX/>}
        />
    )
  } else {
    return (
        <Style.StyledChip
          size="small"
          label={label}
        />
    ) 
  }
};

export default HelperChip;
