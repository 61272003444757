import styled from "styled-components";

export const AddItemContainer = styled.div`
  display: grid;
  height: 100%;

  grid-template-columns: 1.5fr 1fr 1fr 1fr 1.5fr;
  grid-template-rows: 1fr 1fr 0.75fr 0.5fr auto;
  grid-template-areas:
    " . name address address edit"
    " . location location location . "
    " . border border border . "
    " . coordinates coordinates coordinates . "
    " button button button button button ";
`;

export const Address = styled.div`
  display: grid;
  grid-area: address;
  padding: 15px 5px 10px 5px;
  align-content: center;

  grid-template-columns: 1fr 10px 1fr;
  grid-template-rows: auto;
  grid-template-areas: "address1 . address2";
`;

export const Border = styled.div`
  display: grid;
  grid-area: border;
  border-bottom: solid;
  border-width: thin;
  border-color: #e6e6e6;

  margin-bottom: 1em;
`;

export const ButtonSection = styled.div`
  display: grid;
  grid-area: button;
  align-items: center;
  border-top: solid;
  border-width: thin;
  border-color: #a6a6a6;
  background: #d9d9d9;
  padding: 10px;

  grid-template-columns: 1fr 1fr 1fr 1fr;
  grid-template-rows: auto;
  grid-template-areas: " . . . buttons ";
`;

export const ButtonContainer = styled.div`
  display: grid;
  grid-area: buttons;
  align-items: center;

  grid-template-columns: 1fr 1fr 5px 1fr;
  grid-template-rows: auto;
  grid-template-areas: " .  cancel . save ";
`;

export const Coordinates = styled.div`
  display: grid;
  grid-area: coordinates;
  padding: 0px 5px 15px 5px;
  align-items: center;

  grid-template-columns: 1fr 10px 1fr;
  grid-template-rows: auto;
  grid-template-areas: " latitude . longitude ";
`;

export const FormContainer = styled.form`
  background: white;
`;

export const Location = styled.div`
  display: grid;
  grid-area: location;
  padding: 0px 5px 0px 5px;
  align-content: center;

  grid-template-columns: 1fr 1fr 1fr 10px 1fr 10px 1fr 10px 1fr;
  grid-template-rows: auto;
  grid-template-areas: " city city city . region . postal . country";
`;

export const Name = styled.div`
  display: grid;
  grid-area: name;
  padding: 15px 5px 10px 5px;
  align-content: center;
`;
