import styled from "styled-components";
import { Link } from "react-router-dom";

export const HeaderContainer = styled.div`
  display: grid;
  position: fixed;
  background-color: ${({ theme }) => theme.colors.navBar};
  width: 100%;
  top: 0;
  left: 0;
  height: 50px;
  align-items: center;
  z-index: 98;

  grid-template-columns: 0.2fr 1fr 1.2fr 0.5fr 0.3fr 0.2fr;
  grid-template-rows: auto;
  grid-template-areas: " . searchbar locations spacer logout . ";
`;

//search bar section styles
export const SearchBar = styled.div`
  display: grid;
  grid-area: searchbar;
  align-items: center;
  justify-items: start;
  width: 85%;

  grid-template-columns: 0.1fr 1fr;
  grid-template-rows: auto;
  grid-template-areas: " logo bar ";
`;

export const Logo = styled.div`
  grid-area: logo;
`;

export const Img = styled.img`
  display: flex;
  width: 40px;
`;

export const Input = styled.input`
  width: 100%;
  height: 25px;
  color: ${({ theme }) => theme.colors.sectionDark};
  border-radius: 25px;
  outline: none;
  border: none;
  position: relative;
  padding-left: 15px;
  margin-left: 15px;
  background-color: ${({ theme }) => theme.colors.secondaryGrey};
  transition: 0.3s all;
  ::placeholder {
    color: ${({ theme }) => theme.colors.primaryGrey};
  }
`;

//locations section styles
export const Locations = styled.div`
  display: grid;
  grid-area: locations;
  align-items: center;
  justify-items: center;
  height: 100%;

  grid-template-columns: 1fr 1fr 1fr 1fr;
  grid-template-rows: auto;
  grid-template-areas: " items location reports orders ";
`;

export const StyledLinkText = styled(Link)`
  color: ${({ selected, theme }) =>
    selected ? theme.colors.default : theme.colors.secondaryGrey};
  font-size: 16px;
  text-decoration: none;
`;

export const StyledDiv = styled.div`
  display: grid;
  height: 100%;
  align-items: center;
  justify-items: center;
`;

export const StyledItemsDiv = styled.div`
  display: grid;
  grid-area: ${({ gridArea }) => gridArea};
  height: 100%;
  width: 100%;
  align-items: center;
  justify-items: center;
  font-weight: ${({ selected }) => selected && "bold"};
  background-color: ${({ selected, theme }) =>
    selected && theme.colors.selected};
  outline: 2px solid
    ${({ selected, theme }) =>
      selected ? theme.colors.accent : theme.colors.navBar};
  outline-offset: -2px;
  a:hover {
    color: #ffffff;
  }
`;

export const StyledLocationsDiv = styled.div`
  display: grid;
  grid-area: ${({ gridArea }) => gridArea};
  height: 100%;
  width: 100%;
  align-items: center;
  justify-items: center;
  font-weight: ${({ selected }) => selected && "bold"};
  background-color: ${({ selected, theme }) =>
    selected && theme.colors.selected};
  outline: 2px solid
    ${({ selected, theme }) =>
      selected ? theme.colors.accent : theme.colors.navBar};
  outline-offset: -2px;
  a:hover {
    color: #ffffff;
  }
`;

//user icon avatar style section
export const Spacer = styled.div`
  display: grid;
  grid-area: spacer;
`;

//logout button style section
export const Logout = styled.div`
  display: grid;
  grid-area: logout;
  height: 100%;
  justify-items: end;
`;
