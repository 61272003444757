import {
  MAPS_IS_LOADED
} from './ActionTypes.js';

const initialState = {
  isLoaded: false,
}

const mapsReducer = (state = initialState, action) => {
  switch (action.type) {
    case MAPS_IS_LOADED:
      return {
        ...state,
        isLoaded: action.is_loaded,
      }
    default:
      return state
  }
};

export default mapsReducer;
