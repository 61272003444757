import styled from "styled-components";
import InputLabel from "@material-ui/core/InputLabel";
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';

export const StyledSelect = styled(Select)`
  display: grid;
  grid-area: input;
  & .MuiSelect-select {
    font-size: 14px;
    padding-top: 10.5px;
    padding-bottom: 10.5px;
    text-align: left;
  },
  &.MuiInputBase-root {
    background: white;
  }
`;

export const StyledInputLabel = styled(InputLabel)`
  display: grid;
  grid-area: label;
  text-align: left;
  margin-bottom: 5px;
`; 

export const InputContainer = styled.div`
  display: grid;
  grid-area: ${({ gridArea }) => gridArea};
  justify-items: stretch;

  grid-template-columns: 1fr;
  grid-template-rows: 1fr;
  grid-template-areas:
    " label "
    " input ";
`;

export const StyledMenuItem = styled(MenuItem)`
  &.MuiMenuItem-root {
    font-size: 14px;
  },

`;

