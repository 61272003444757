import React, { useEffect } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";

import { getOrgEffect } from "../effects";
import Layout from "../../components/layout";
import PaperNav from "../components/paper-nav";

import * as Style from "./styles";

const Settings = ({ org, dispatch }) => {
  useEffect(() => {
    dispatch(getOrgEffect());
  }, [dispatch]);

  return (
    <Layout>
      <Style.PageContainer>
        <Style.PaperContainer>
          <PaperNav dispatch={dispatch} org={org} />
        </Style.PaperContainer>
      </Style.PageContainer>
    </Layout>
  );
};

const mapStateToProps = (state) => ({
  org: state.org.org,
});

Settings.propTypes = {
  org: PropTypes.any.isRequired,
};

export default connect(mapStateToProps)(Settings);
