import axios from "axios";

const userApiHost = process.env.REACT_APP_API_HOST;

export async function Signup(email, password, first_name, last_name, organization) {
  const url = userApiHost + "/users/signup";
  const data = await axios.post(url, {
    first_name: first_name,
    last_name: last_name,
    email: email,
    password: password,
    org_name: organization,
  });
  return data;
}

export function GetUser(user_token, user_id, include_orgs) {
  const url = `${userApiHost}/users/${user_id}?include_orgs=${include_orgs}`;
  return axios.get(url, { headers: { Authorization: `${user_token}` } });
}

export function UpdateUser(user_token, user_id, info) {
  const url = `${userApiHost}/users/${user_id}`;
  return axios.put(url, info, { headers: { Authorization: `${user_token}` } });
}

export function UploadAvatar(user_token, user_id, file) {
  const url = `${userApiHost}/users/${user_id}/update_avatar`;
  return axios.put(url, file, {
    headers: { "Content-Type": file.type, Authorization: `${user_token}` },
  });
}
