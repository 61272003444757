import {
  USER_LOGIN,
  USER_LOGIN_ERROR
} from './ActionTypes';

export const userLoginError = (error) => ({
  type: USER_LOGIN_ERROR,
  error: error,
});
export const userLogin = () => ({
  type: USER_LOGIN,
})
