import {
  GET_LOCATIONS_ERROR,
  GET_LOCATIONS,
  FETCHED_LOCATION,
  FETCHED_LOCATION_ERROR,
  DELETE_LOCATION,
  DELETE_LOCATION_ERROR,
  CREATE_LOCATION,
  CREATE_LOCATION_ERROR,
  EDIT_LOCATION,
  EDIT_LOCATION_ERROR,
  USER_LOCATION_REQUEST,
  USER_LOCATION_FETCHED,
  USER_LOCATION_ERROR,
  LOCATION_DISTANCE_REQUEST,
  LOCATION_DISTANCE_FETCHED,
  LOCATION_DISTANCE_ERROR,
} from "../actions/ActionTypes";

const initialState = {
  user_latitude: null,
  user_longitude: null,
  attempted_user_location_load: false,
  locations: [],
  distance: "",
  loading: true,
  error: null,
};

const locationsReducer = (state = initialState, action) => {
  const { type, payload, error } = action;
  switch (type) {
    case GET_LOCATIONS:
      return {
        ...state,
        locations: payload,
        loading: false,
        error: null,
      };
    case FETCHED_LOCATION:
      return {
        ...state,
        locations: state.locations.concat([payload]),
        loading: false,
        error: null,
      };
    case USER_LOCATION_REQUEST:
    case CREATE_LOCATION:
    case EDIT_LOCATION:
    case DELETE_LOCATION:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case USER_LOCATION_FETCHED:
      return {
        ...state,
        attempted_user_location_load: true,
        user_latitude: payload.latitude,
        user_longitude: payload.longitude,
        loading: false,
        error: null,
      };
    case LOCATION_DISTANCE_REQUEST:
      return {
        ...state,
        distance: "",
        loading: true,
        error: null,
      };
    case LOCATION_DISTANCE_FETCHED:
      return {
        ...state,
        distance: payload,
        loading: false,
        error: null,
      };
    case USER_LOCATION_ERROR:
    case FETCHED_LOCATION_ERROR:
    case GET_LOCATIONS_ERROR:
    case LOCATION_DISTANCE_ERROR:
    case DELETE_LOCATION_ERROR:
    case CREATE_LOCATION_ERROR:
    case EDIT_LOCATION_ERROR:
      return {
        ...state,
        loading: false,
        error: error,
      };
    default:
      return state;
  }
};

export default locationsReducer;
