import { UploadAvatar, UpdateUser } from "../../api/user.service.js";
import { avatarURLUpdated, avatarUploadError, userInfoUpdated } from "../actions";

export const uploadAvatarEffect = (token, id, file) => async ( dispatch ) => {
  try {
    const resp = await UploadAvatar(token, id, file);
    dispatch(avatarURLUpdated(resp.data.URL));
  } catch (error) {
    alert("Error uploading new avatar");
    dispatch(avatarUploadError(error));
  }
};

export const updateUserInfoEffect = (user) => async (dispatch, getState) => {
  try {
    await UpdateUser(getState().user.user_token, getState().user.user_id, user);
    dispatch(userInfoUpdated(user.first_name, user.last_name));
  } catch (error) {
    alert("Error uploading new avatar");
    dispatch(avatarUploadError(error));
  }
};
