import styled from "styled-components";
import Button from "../../../../../components/button";

export const PhotoContainer = styled.div`
  display: grid;

  height: 180px;
  grid-template-columns: 1fr;
  grid-template-rows:    1fr 50px;
  grid-template-areas: 
    " image "
    " button ";
`
export const ImageContainer = styled.div`
  display: grid;
  grid-area: image;
`
export const ButtonContainer = styled.div`
  display: grid;
  grid-area: button;
  border-top: 1px solid #7288AB;
  margin: 0px 3px 0px 3px;

  grid-template-columns: 1fr 116px 1fr;
  grid-template-rows:    1fr 30px  1fr;
  grid-template-areas: 
    " . .           . "
    " . updatePhoto . "
    " . .           . ";
`

export const StyledButton = styled(Button)`
  display: grid;
  grid-area: updatePhoto;
  color: "white";
  backgroundColor: "#1a90ab";
`
