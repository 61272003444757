import React from "react";
import DialogActions from "@material-ui/core/DialogActions";
import DialogTitle from "@material-ui/core/DialogTitle";
import Button from "../../components/button";

import * as Style from "./styles";

const DeleteDialog = ({ title, open, handleClose, handleDelete, id }) => (
  <Style.StyledDialog
    open={open}
    onClose={handleClose}
    aria-labelledby="form-dialog-title"
  >
    <DialogTitle id="form-dialog-title">{title}</DialogTitle>
    <DialogActions style={{ justifyContent: "center" }}>
      <Button
        handleClick={handleClose}
        fontSize={12}
        style={{
          color: "white",
          backgroundColor: "#445b79",
        }}
      >
        Cancel
      </Button>
      <Button handleClick={() => handleDelete(id)} fontSize={12}>
        Delete
      </Button>
    </DialogActions>
  </Style.StyledDialog>
);

export { DeleteDialog };
