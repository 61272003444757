import {
  USER_SIGNUP,
  USER_SIGNUP_ERROR
} from './ActionTypes';

export const userSignupError = (error) => ({
  type: USER_SIGNUP_ERROR,
  error: error,
});

export const userSignup = () => ({
  type: USER_SIGNUP,
})