import {
  ASSET_CREATE,
  ASSET_CREATE_ERROR,
  ASSET_GET_ASSETS,
  ASSET_GET_ASSETS_ERROR,
  ASSET_DELETE_ASSET,
  ASSET_DELETE_ASSET_ERROR,
  ASSET_UPDATE_ASSET,
  ASSET_UPDATE_ASSET_ERROR,
  FIELD_DEFINITIONS_REQUESTED,
  FIELD_DEFINITIONS_FETCHED,
  FIELD_DEFINITIONS_ERROR,
  GET_VIEWS
} from '../actions/ActionTypes';

const initialState = {
  assets: [],
  asset_table_titles: [],
  fieldDefinitions: [],
  isLoading: true,
  views: [],
  error: null
}

const assetsReducer = (state = initialState, action) => {
  const { type, payload, error } = action;
  switch (type) {
    case ASSET_CREATE:
      return {
	...state,
	isLoading: true,
      }
    case ASSET_GET_ASSETS:
      return {
	...state,
	isLoading: false,
	assets: Object.assign(payload, {})
      }
    case ASSET_DELETE_ASSET:
      return {
	...state, 
        isLoading: true, 
      }
    case ASSET_UPDATE_ASSET:
      return {
	...state,
	isLoading: true,
      }
    case FIELD_DEFINITIONS_REQUESTED:
      return {
	...state,
	isLoading: true,
      }
    case FIELD_DEFINITIONS_FETCHED:
      return {
	...state,
	isLoading: false,
	fieldDefinitions: payload,
      }
    case GET_VIEWS:
      return {
	...state,
	isLoading: false,
	views: payload,
      }
    case FIELD_DEFINITIONS_ERROR:
    case ASSET_CREATE_ERROR:
    case ASSET_GET_ASSETS_ERROR:
    case ASSET_DELETE_ASSET_ERROR:
    case ASSET_UPDATE_ASSET_ERROR:
      return {
	...state, isLoading: false,
        error: error,
      }
    default:
      return state
  }
}

export default assetsReducer
