import { createGlobalStyle } from "styled-components";

const GlobalStyle = createGlobalStyle`
html {
    height: 100%;
    background: #cfcecd;
}

* {
    padding: 0;
    margin: 0;
}

body {
    font-family: sans-serif;
}

`;

export default GlobalStyle;
