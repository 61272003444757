import {
  SETTINGS_FETCH_ORG,
  SETTINGS_FETCH_ORG_ERROR,
  SETTINGS_UPDATE_ORG,
  SETTINGS_UPDATE_ORG_ERROR,
  SETTINGS_GET_ORG_MEMBERS,
  SETTINGS_GET_ORG_MEMBERS_ERROR,
} from "./ActionTypes";

export const fetchedOrg = (org) => ({
  type: SETTINGS_FETCH_ORG,
  payload: org,
});

export const fetchedOrgError = (error) => ({
  type: SETTINGS_FETCH_ORG_ERROR,
  error: error,
});

export const updateOrg = (org) => ({
  type: SETTINGS_UPDATE_ORG,
  payload: org,
});

export const updateOrgError = (error) => ({
  type: SETTINGS_UPDATE_ORG_ERROR,
  error: error,
});

export const getOrgMembers = (members) => ({
  type: SETTINGS_GET_ORG_MEMBERS,
  payload: members,
});

export const getOrgMembersError = (error) => ({
  type: SETTINGS_GET_ORG_MEMBERS_ERROR,
  error: error,
});
