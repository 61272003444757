import React from "react";
import { connect } from "react-redux";
import { useJsApiLoader } from '@react-google-maps/api';
import { mapsLoaded } from './actions.js';

const GlobalMaps = ({ dispatch }) => {
  const { isLoaded } = useJsApiLoader({
    id: 'google-map-script',
    googleMapsApiKey: process.env.REACT_APP_MAPS_API_KEY,
  });
  dispatch(mapsLoaded(isLoaded));

  return (<div />);
};

const mapStateToProps = (state) => ({
});

export default connect(mapStateToProps)(GlobalMaps);
