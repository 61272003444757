import React from "react";
import { useSelector } from "react-redux";
import { Route, Redirect } from "react-router-dom";

import { selectIsAuthed } from "../auth/authSlice.js";
import * as routes from "./paths";

// A wrapper for <Route> that redirects to the login
// screen if you're not yet authenticated.
function PublicRoute({ component: Component, ...rest }) {
  const isAuthed = useSelector(selectIsAuthed);

  return (
    <Route
      {...rest}
      render={({ location }) =>
        !isAuthed ? (
          <Component {...rest} />
        ) : (
          <Redirect
            to={{
              pathname: routes.ITEMS,
              state: { from: location },
            }}
          />
        )
      }
    />
  );
}

export default PublicRoute;
