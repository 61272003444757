import React, { useState } from "react";
import { Link } from "react-router-dom";
import Logo from "../../shared/assets/ShingoLogo_Blue.png";
import * as routes from "../../shared/routes/paths";
import * as Style from "./styles";
import Profile from "./profile";

const Navbar = () => {
  const pathname = window.location.pathname;
  const [value, setValue] = useState("");
  const isOnLocations = pathname === "/locations";
  const isOnAssets = pathname === "/items";

  return (
    <Style.HeaderContainer>
      <Style.SearchBar>
        <Style.Logo>
          <Link to={routes.ITEMS}>
            <Style.Img src={Logo} alt="" />
          </Link>
        </Style.Logo>
        <Style.Input
          type="text"
          placeholder="Search"
          value={value}
          onChange={(e) => setValue(e.target.value)}
        />
      </Style.SearchBar>
      <Style.Locations>
        <Style.StyledItemsDiv selected={isOnAssets} gridArea="items">
          <Style.StyledLinkText to={routes.ITEMS} selected={isOnAssets}>
            Assets
          </Style.StyledLinkText>
        </Style.StyledItemsDiv>
        <Style.StyledLocationsDiv selected={isOnLocations} gridArea="location">
          <Style.StyledLinkText to={routes.LOCATIONS} selected={isOnLocations}>
            Locations
          </Style.StyledLinkText>
        </Style.StyledLocationsDiv>
      </Style.Locations>
      <Style.Spacer />
      <Style.Logout>
        <Style.StyledDiv>
          <Profile />
        </Style.StyledDiv>
      </Style.Logout>
    </Style.HeaderContainer>
  );
};

export default Navbar;
