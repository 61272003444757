import {
  GetOrg,
  UpdateOrg,
  UpdateOrgUser,
  GetOrgMembers,
} from "../../api/organization.service";
import {
  fetchedOrg,
  fetchedOrgError,
  updateOrg,
  updateOrgError,
  getOrgMembers,
  getOrgMembersError,
} from "../actions";
import {
  userOrgAdminUpdate,
  userOrgAdminUpdateError,
} from "../../profile/actions";
import { setAlert } from "../../components/alert/actions";

export const getOrgEffect = () => async (dispatch, getState) => {
  try {
    const userState = getState().user;
    const { org_id, user_token } = userState;
    const res = await GetOrg(org_id, user_token);
    dispatch(fetchedOrg(res.data.body));
  } catch (error) {
    dispatch(fetchedOrgError(error.message));
  }
};

export const updateOrgEffect = (newOrg) => async (dispatch, getState) => {
  try {
    const userState = getState().user;
    const { org_id, user_token } = userState;
    const updatedOrg = {
      name: newOrg.name,
    };
    const res = await UpdateOrg(org_id, user_token, updatedOrg);
    dispatch(updateOrg(res.data.body));
    dispatch(setAlert("success", "Organization Updated Successfully"));
  } catch (error) {
    dispatch(updateOrgError(error.message));
  }
};

export const updateOrgUserEffect = (admin, member_id, user_id) => async (
  dispatch,
  getState
) => {
  try {
    const userState = getState().user;
    const { org_id, user_token } = userState;

    const res = await UpdateOrgUser(org_id, member_id, user_token, admin);
    if (member_id === user_id) {
      dispatch(userOrgAdminUpdate(res.data.body));
    }
    dispatch(setAlert("success", "Organization User Updated Successfully"));
  } catch (error) {
    dispatch(userOrgAdminUpdateError(error.message));
    dispatch(
      setAlert(
        "error",
        "There must be at least one admin for this organizaiton"
      )
    );
  }
};

export const getOrgMembersEffect = () => async (dispatch, getState) => {
  try {
    const userState = getState().user;
    const { org_id, user_token } = userState;
    const res = await GetOrgMembers(org_id, user_token);
    dispatch(getOrgMembers(res.data.body));
  } catch (error) {
    dispatch(getOrgMembersError(error.message));
  }
};
