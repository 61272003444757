import styled from "styled-components";

export const LocationDetailsContainer = styled.div`
  display: grid;
  grid-area: itemContainer;
  padding: 10px;
  height: 100%;

  grid-template-columns: auto;
  grid-template-rows: auto;
  grid-template-areas: " locationAttributesContainer chart directionsContainer ";
`;

export const LocationAttributesContainer = styled.div`
  display: grid;
  grid-area: locationAttributesContainer;
  padding: 10px;
  height: 100%;

  grid-template-columns: auto;
  grid-template-rows: auto;
  grid-template-areas:
    " name "
    " address "
    " addressRegion "
    " coordinates ";
`;

export const LocationNameContainer = styled.div`
  display: grid;
  grid-area: name;

  justify-items: start;
`;
export const AddressContainer = styled.div`
  display: grid;
  grid-area: address;

  justify-items: start;
`;
export const AddressRegionContainer = styled.div`
  display: grid;
  grid-area: addressRegion;

  justify-items: start;
`;
export const CoordinatesContainer = styled.div`
  display: grid;
  grid-area: coordinates;

  justify-items: start;
`;

export const EditItemContainer = styled.div`
  display: grid;
  grid-area: itemContainer;
  height: 100%;

  grid-template-columns: auto;
  grid-template-rows: auto;
  grid-template-areas:
    " name     city   country "
    " address1 region latitude "
    " address2 postal longitude ";
`;

export const InputStyle = styled.div`
  display: grid;
  grid-area: ${({ gridArea }) => gridArea};
  padding: 15px 5px 10px 5px;
`;

export const Border = styled.div`
  display: grid;
  grid-area: border;
  border-bottom: solid;
  border-width: thin;
  border-color: #e6e6e6;

  margin-bottom: 1em;
`;

export const DirectionsContainer = styled.div`
  display: grid;
  grid-area: directionsContainer;
  margin: 10px;
  height: 100%;
  box-shadow: 0 0 1px #808080;

  grid-template-columns: auto;
  grid-template-rows: 50px 30px;
  grid-template-areas:
    " distance "
    " getDirectionsContainer ";
`;

export const FormContainer = styled.form`
  background: white;
`;

export const GetDirectionsButtonContainer = styled.div`
  display: grid;
  grid-area: getDirectionsContainer;
  align-items: center;

  grid-template-columns: 20px 1fr 20px;
  grid-template-rows: auto;
  grid-template-areas: " . getDirections .";
`;

export const ItemActions = styled.div`
  display: grid;
  grid-area: actions;
  height: 100%;

  grid-template-columns: auto;
  grid-template-rows: auto;
  grid-template-areas:
    " action"
    " . "
    " delete ";
`;

export const ItemContainer = styled.div`
  display: grid;
  height: 100%;
  grid-template-columns: 2fr 1fr 1fr 0.5fr;
  grid-template-rows: 5fr 1fr;
  grid-template-areas:
    " itemContainer ${({ edit }) => (edit ? "." : "itemContainer")} . actions "
    " border  border border border ";
`;

export const StyledDiv = styled.div`
  display: grid;
  grid-area: ${({ gridArea }) => gridArea};
  padding: 15px 15px 10px 10px;
  justify-items: end;
`;

export const LocationChartContainer = styled.div`
  display: grid;
  grid-area: chart;
  align-items: end;
  justify-content: center;
`;
