import React from "react";

import Button from "../../../components/button";
import Input from "../../../components/input";
import * as Style from "./styles";

function AddLocation({
  formRef,
  handleChange,
  handleCancel,
  handleSubmit,
  disabled,
}) {
  return (
    <Style.FormContainer ref={formRef}>
      <Style.AddItemContainer>
        <Style.Name>
          <Input
            required
            id="name"
            name="name"
            type="text"
            labelName={"*Name"}
            size="small"
            variant="outlined"
            handleChange={handleChange}
          />
        </Style.Name>
        <Style.Address>
          <Input
            id="address1"
            name="address1"
            type="text"
            labelName={"Address 1"}
            size="small"
            gridArea={"address1"}
            variant="outlined"
            handleChange={handleChange}
          />
          <Input
            id="address2"
            name="address2"
            type="text"
            labelName={"Address 2"}
            size="small"
            gridArea={"address2"}
            variant="outlined"
            handleChange={handleChange}
          />
        </Style.Address>
        <Style.Location>
          <Input
            id="city"
            name="city"
            type="text"
            labelName={"City"}
            size="small"
            gridArea={"city"}
            variant="outlined"
            handleChange={handleChange}
          />
          <Input
            id="region"
            name="region"
            type="text"
            labelName={"Region"}
            size="small"
            gridArea={"region"}
            variant="outlined"
            handleChange={handleChange}
          />
          <Input
            id="postalcode"
            name="postalcode"
            type="text"
            labelName={"Postal Code"}
            size="small"
            gridArea={"postal"}
            variant="outlined"
            handleChange={handleChange}
          />
          <Input
            id="country"
            name="country"
            type="text"
            labelName={"Country"}
            size="small"
            gridArea={"country"}
            variant="outlined"
            handleChange={handleChange}
          />
        </Style.Location>
        <Style.Border />
        <Style.Coordinates>
          <Input
            id="latitude"
            name="latitude"
            type="number"
            labelName={"Latitude"}
            size="small"
            gridArea={"latitude"}
            variant="outlined"
            handleChange={handleChange}
          />
          <Input
            id="longitude"
            name="longitude"
            type="number"
            labelName={"Longitude"}
            size="small"
            gridArea={"longitude"}
            variant="outlined"
            handleChange={handleChange}
          />
        </Style.Coordinates>
        <Style.ButtonSection>
          <Style.ButtonContainer>
            <Button
              size="small"
              fontSize={12}
              style={{
                gridArea: "cancel",
              }}
              handleClick={handleCancel}
            >
              Cancel
            </Button>
            <Button
              disabled={disabled}
              size="small"
              fontSize={12}
              style={{
                gridArea: "save",
              }}
              handleClick={handleSubmit}
            >
              Save
            </Button>
          </Style.ButtonContainer>
        </Style.ButtonSection>
      </Style.AddItemContainer>
    </Style.FormContainer>
  );
}

export default AddLocation;
