import {
  USER_LOGIN,
  USER_LOGIN_ERROR,
} from '../actions/ActionTypes';

const initialState = {
  error: {}
}

const loginReducer = (state = initialState, action) => {
  const { type, error } = action;
  switch (type) {
    case USER_LOGIN_ERROR:
      return {
        ...state,
        error: Object.assign(error, {}),
      }
      case USER_LOGIN:
        return {
          ...state,
          error: {}
        }
      default:
        return state
  }
}

export default loginReducer;
