import React from "react";

import * as Style from "./styles";

const AdvancedTab = ({ value, index }) => {
  return (
    <div hidden={value !== index} id={index}>
      {value === index && <Style.Page>Advanced Tab</Style.Page>}
    </div>
  );
};

export default AdvancedTab;
