import React from "react";
import * as Style from "./styles";
import Attachment from "../../../../components/attachment";
import PropTypes from "prop-types";

const AttachmentTabContent = ({ attachmentInfo, handleClick }) => {

  let attachments = null
  if (attachmentInfo) {
    attachments = Object.keys(attachmentInfo).map((attachmentName) => (
      <Attachment label={attachmentName} key={attachmentName} handleClick={() => {handleClick(attachmentName)}}/>
    ))
  }
  return (
    <Style.TabContainer>
      <Style.Header>
        <Style.StyledText>
          Attachments
        </Style.StyledText>
      </Style.Header>
      <Style.Content>
        {attachments}
      </Style.Content>
    </Style.TabContainer>
  )
};

AttachmentTabContent.propTypes = {
  attachmentInfo: PropTypes.object,
  handleClick: PropTypes.func,
};

export default AttachmentTabContent;
