import { createSlice } from '@reduxjs/toolkit';

export const authSlice = createSlice({
  name: 'auth',
  initialState: {
    isAuthed: null,
  },
  reducers: {
    setIsAuthed: (state, action) => {
      state.isAuthed = action.payload;
    },
  },
});

export const { setIsAuthed } = authSlice.actions;

export const selectIsAuthed = state => state.auth.isAuthed;

export default authSlice.reducer;
