import styled from "styled-components";
import Link from "../../components/link";
import Button from "../../components/button";

export const Background = styled.div`
  background: ${(props) => props.theme.colors.default};
  padding: 20px;
  width: 20%;
  border-radius: 5px;
`;

export const StyledLink = styled(Link)`
  font-weight: normal;
  font-size: 14px;
  text-decoration: none;
`;

export const StyledText = styled.p`
  grid-area: text;
  text-align: left;
  font-size: 14px;
`;

export const StyleErrorMessage = styled.p`
  grid-area: text;
  text-align: left;
  font-size: 12px;
  color: red;
`;

export const LinksContainer = styled.div`
  display: grid;
  grid-area: links;
  text-align: left;
  padding-top: 15px;

  grid-template-columns: 1fr;
  grid-template-rows: auto;
  grid-template-areas:
    "forgotPassword"
    "signUp";
`;

export const StyledForm = styled.form`
  display: grid;
  padding-bottom: 5px;

  grid-template-columns: 1fr;
  grid-template-rows: auto;
  grid-template-areas:
    "email"
    "password"
    "button";
`;

export const StyledButton = styled(Button)`
  grid-area: button;
`;
