import styled from "styled-components";

export const Page = styled.div`
  display: grid;
  grid-area: page;
  justify-content: center;

  grid-template-columns: 1fr;
  grid-template-rows: 100px 1fr;
  grid-template-areas:
    " . "
    " paper ";
`;

export const PaperContainer = styled.div`
  display: grid;
  grid-area: paper;

  align-items: center;
  width: 100%;
  padding: 0px 0px 15px 0px;
`;
