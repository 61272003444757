export const ASSET_CREATE = 'ASSET.CREATE_ASSET';
export const ASSET_CREATE_ERROR= 'ASSET.CREATE_ASSET_ERROR';

export const ASSET_GET_ASSETS = 'ASSET.GET_ASSETS';
export const ASSET_GET_ASSETS_ERROR= 'ASSET.GET_ASSETS_ERROR';

export const ASSET_DELETE_ASSET = 'ASSET.DELETE_ASSET';
export const ASSET_DELETE_ASSET_ERROR= 'ASSET.DELETE_ASSET_ERROR';

export const ASSET_UPDATE_ASSET = 'ASSET.UPDATE_ASSET';
export const ASSET_UPDATE_ASSET_ERROR= 'ASSET.UPDATE_ASSET_ERROR';

export const FIELD_DEFINITIONS_REQUESTED = 'ASSET.FIELD_DEFINITIONS_REQUESTED';
export const FIELD_DEFINITIONS_FETCHED = 'ASSET.FIELD_DEFINITIONS_FETCHED';
export const FIELD_DEFINITIONS_ERROR = 'ASSET.FIELD_DEFINITIONS_ERROR';

export const VIEW_CREATE = 'ASSET.CREATE_VIEW';
export const VIEW_CREATE_ERROR= 'ASSET.CREATE_VIEW_ERROR';

export const GET_VIEWS = 'ASSET.GET_VIEWS';
export const GET_VIEWS_ERROR= 'ASSET.GET_VIEWS_ERROR';

export const DELETE_VIEW = 'ASSET.DELETE_VIEW';
export const DELETE_VIEW_ERROR= 'ASSET.DELETE_VIEW_ERROR';

export const UPDATE_VIEW = 'ASSET.UPDATE_VIEW';
export const UPDATE_VIEW_ERROR= 'ASSET.UPDATE_VIEW_ERROR';
