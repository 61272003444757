import { SET_ALERT, REMOVE_ALERT } from "./ActionTypes";

export const setAlert =
  (severity, message, timeout = 1800) =>
  (dispatch) => {
    const id = Date.now();
    dispatch({
      type: SET_ALERT,
      payload: { severity, message, id },
    });
    setTimeout(() => dispatch({ type: REMOVE_ALERT, payload: id }), timeout);
  };
