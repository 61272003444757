import React from "react";
import PropTypes from "prop-types";
import * as Style from "./styles";

const Input = ({
  id,
  label,
  name,
  type,
  value,
  margin,
  labelName,
  gridArea,
  handleChange,
  required,
  ...rest
}) => {
  return (
    <Style.InputContainer gridArea={gridArea}>
      <Style.StyledInputLabel style={{ fontSize: 14, color: "black" }}>
        {labelName}
      </Style.StyledInputLabel>
      <Style.StyledTextField
        id={id}
        label={label}
        name={name}
        type={type}
        value={value}
        margin={margin}
        required={required}
        onChange={handleChange}
        fullWidth={true}
        disabled={false}
        {...rest}
      />
    </Style.InputContainer>
  );
};

Input.propTypes = {
  id: PropTypes.string,
  label: PropTypes.string,
  name: PropTypes.string,
  type: PropTypes.string.isRequired,
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]),
  margin: PropTypes.string,
  handleChange: PropTypes.func.isRequired,
};

export default Input;
