import React from "react";
import PropTypes from "prop-types";
import * as Style from "./styles";

const InputSelect = ({
  labelName,
  handleChange,
  size,
  variant,
  inputId,
  inputName,
  inputType,
  inputValue,
  selectId,
  selectName,
  selectValue,
  selectOptions,
}) => {
  return (
    <Style.InputSelectContainer>
      <Style.StyledInputLabel>
        {labelName}
      </Style.StyledInputLabel>
      <Style.StyledTextField
        id={inputId}
        name={inputName}
        type={inputType}
        value={inputValue}
        onChange={handleChange}
        fullWidth={true}
        disabled={false}
        size={size}
        variant={variant}
      />
      <Style.StyledSelect
        id={selectId}
        name={selectName}
        value={selectValue}
        onChange={handleChange}
        size={size}
        variant={variant}
      >
        {selectOptions.map((option, index) => (
          <Style.StyledMenuItem value={option.value} key={index}>{option.label}</Style.StyledMenuItem>
        ))}
      </Style.StyledSelect>
    </Style.InputSelectContainer>
  );
};

InputSelect.propTypes = {
  labelName: PropTypes.string.isRequired,
  required: PropTypes.bool,
  handleChange: PropTypes.func.isRequired,
  size: PropTypes.string,
  variant: PropTypes.string,

  inputId: PropTypes.string,
  inputName: PropTypes.string,
  inputType: PropTypes.string.isRequired,
  inputValue: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]),

  selectId: PropTypes.string,
  selectName: PropTypes.string,
  selectValue: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]),
  selectOptions: PropTypes.array
};

export default InputSelect;
