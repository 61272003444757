export const FilterAssetsByView = (view, assets) => {
     return assets.filter((asset) => {
       let include = false
       view.rules.forEach(rule => {

         if (rule.conjunction === "and" && include === false) {
           return
         }

         if (rule.conjunction === "or" && include === true) {
           return
         }

         if (asset.fields.hasOwnProperty(rule.field)) {
           if (TextPrepOptions.hasOwnProperty(rule.preposition) && asset.fields[rule.field] !== undefined) {
             include = TextPrepOptions[rule.preposition].func(rule, asset.fields);
           }
           if (NumberPrepOptions.hasOwnProperty(rule.preposition) && asset.fields[rule.field] !== undefined) {
             include = NumberPrepOptions[rule.preposition].func(rule, asset.fields);
           }
         }
        }); 
       return include;
     });
};

// Preps
const prepIs = (rule, assetFields) => {
  return (assetFields[rule.field] === rule.value)
};

const prepIsNot = (rule, assetFields) => {
  return !prepIs(rule, assetFields)
};

const prepContains = (rule, assetFields) => {
  return (assetFields[rule.field].includes(rule.value))
};

const prepDoesNotContain = (rule, assetFields) => {
  return !prepContains(rule, assetFields)
};

const prepIsEmpty = (rule, assetFields) => {
  return (assetFields[rule.field].trim() === "")
};

const prepIsNotEmpty = (rule, assetFields) => {
  return !prepIsEmpty(rule, assetFields);
};

const prepIsLessThan = (rule, assetFields) => {
  return assetFields[rule.field] < rule.value
};

const prepIsLessThanOrEqualTo = (rule, assetFields) => {
  return (assetFields[rule.field] <= rule.value)
};

const prepIsGreaterThan = (rule, assetFields) => {
  return (assetFields[rule.field] > rule.value)
};

const prepIsGreaterThanOrEqualTo = (rule, assetFields) => {
  return (assetFields[rule.field] >= rule.value)
};

const prepIsBetween = (rule, assetFields) => {
    return (rule.value <= assetFields[rule.field] && assetFields[rule.field] < rule.value2)
};

const prepIsNotBetween = (rule, assetFields) => {
    return !prepIsBetween(rule, assetFields)
};


// Prep Options
export const TextPrepOptions = {
    "is": {"label": "is", "value": "is", "func": prepIs}, 
    "is_not": {"label": "is not", "value": "is_not", "func": prepIsNot}, 
    "contains": {"label": "contains", "value": "contains", "func": prepContains}, 
    "does_not_contain": {"label": "does not contain", "value": "does_not_contain", "func": prepDoesNotContain}, 
    "is_empty": {"label": "is empty", "value": "is_empty", "func": prepIsEmpty}, 
    "is_not_empty": {"label": "is not empty", "value": "is_not_empty", "func": prepIsNotEmpty}, 
}

export const NumberPrepOptions = {
     "is": {"label": "is", "value": "is", "func": prepIs}, 
     "is_not": {"label": "is not", "value": "is_not", "func": prepIsNot}, 
     "is_less_than": {"label": "is less than", "value": "is_less_than", "func": prepIsLessThan}, 
     "is_less_than_or_equal_to": {"label": "is less than or equal to", "value": "is_less_than_or_equal_to", "func": prepIsLessThanOrEqualTo}, 
     "is_greater_than": {"label": "is greater than", "value": "is_greater_than", "func": prepIsGreaterThan}, 
     "is_greater_than_or_equal_to": {"label": "is greater than or equal to", "value": "is_greater_than_or_equal_to", "func": prepIsGreaterThanOrEqualTo}, 

     // TODO FUNCS
     "is_between": {"label": "is between", "value": "is_between", "func": prepIsBetween}, 
     "is_not_between": {"label": "is not between", "value": "is_not_between", "func": prepIsNotBetween}, 
}

export const ConjunctionOptionsArray = [
    {"label": "And", "value": "and"}, 
    {"label": "Or", "value": "or"}, 
]
