import {
    USER_SIGNUP,
    USER_SIGNUP_ERROR,
  } from '../actions/ActionTypes';
  
  const initialState = {
    error: {}
  }
  
  const signupReducer = (state = initialState, action) => {
    const { type, error } = action;
    switch (type) {
      case USER_SIGNUP_ERROR:
        return {
          ...state,
          error: Object.assign(error, {}),
        }
        case USER_SIGNUP:
          return {
            ...state,
            error: {}
          }
        default:
          return state
    }
  }
  
  export default signupReducer;
  