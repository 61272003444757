import React, { useState } from "react";
import PropTypes from "prop-types";

import AssetForm from "../../components/asset-form";
import TableCell from "@material-ui/core/TableCell";
import Collapse from "@material-ui/core/Collapse";
import Button from "../../../components/button";

import LocalOfferIcon from "@material-ui/icons/LocalOffer";
import RoomIcon from "@material-ui/icons/Room";
import LocalAtmIcon from "@material-ui/icons/LocalAtm";
import QueryBuilderIcon from "@material-ui/icons/QueryBuilder";
import AttachFileIcon from "@material-ui/icons/AttachFile";

import HelpersTabContent from "./components/helpers-tab-content";
import LocationTabContent from "./components/location-tab-content";
import WarrantyTabContent from "./components/warranty-tab-content";
import HistoryTabContent from "./components/history-tab-content";
import AttachmentTabContent from "./components/attachment-tab-content";

import * as Style from "./styles";

const RowDropdown = ({
  row,
  fieldDefinitions,
  locations,
  handleClickOpenDeleteAsset,
  setOpen,
  open,
  handleRowUpdate,
  handleAttachmentDownload
}) => {
  const rowAssetFieldState = {};
  fieldDefinitions
    .filter((fieldDefinition) => {
      if ("editable" in fieldDefinition && fieldDefinition.editable === false) {
        return false;
      } else return true;
    })
    .forEach((field) => {
      if (field.id === "location") {
        rowAssetFieldState["location_id"] = row.fields.location_id;
      } else {
        rowAssetFieldState[field.id] = row.fields[field.id];
      }
    });

  // Normalize start_date and calculate end_date
  const warranty = { ...row.warranty };
  if (row.warranty.start_date != null) {
    warranty.start_date = row.warranty.start_date.split("T")[0];
  }
  if (row.warranty.end_date != null) {
    warranty.end_date = row.warranty.end_date.split("T")[0];
  }

  // Cloning these since they are tied to the redux state
  const helpers = [...row.helpers];
  const attachments = {...row.attachments};

  const [edit, setEdit] = useState(false);
  const [tabValue, setTabValue] = React.useState(0);
  const handleTabsChange = (event, newValue) => {
    setTabValue(newValue);
  };

  const handleDownload = (attachmentName) => {
    handleAttachmentDownload(row.id, attachmentName)
  }

  return (
    <Style.StyledTableRow>
      <TableCell
        style={{ paddingBottom: 0, paddingTop: 0 }}
        colSpan={fieldDefinitions.length + 1}
      >
        <Collapse in={open} timeout="auto" unmountOnExit>
          {!edit ? (
            <Style.DropdownContainer>
              <Style.AssetDropdownContainer>
                <Style.AssetImageContainer>*Image*</Style.AssetImageContainer>
                <Style.AssetTabsContainer>
                  <Style.StyledTabs
                    TabIndicatorProps={{
                      style: { background: "#FF9726", width: "3px" },
                    }}
                    orientation="vertical"
                    value={tabValue}
                    aria-label="Vertical tabs"
                    onChange={handleTabsChange}
                  >
                    <Style.StyledTab icon={<LocalOfferIcon />} />
                    <Style.StyledTab icon={<RoomIcon />} />
                    <Style.StyledTab icon={<QueryBuilderIcon />} />
                    <Style.StyledTab icon={<AttachFileIcon />} />
                    <Style.StyledTab icon={<LocalAtmIcon />} />
                  </Style.StyledTabs>
                </Style.AssetTabsContainer>
                <Style.AssetInfoContainer>
                  {tabValue === 0 ? (
                    <HelpersTabContent helpersInfo={helpers} />
                  ) : null}
                  {tabValue === 1 ? <LocationTabContent /> : null}
                  {tabValue === 2 ? <HistoryTabContent /> : null}
                  {tabValue === 3 ? <AttachmentTabContent 
                          attachmentInfo={attachments} 
                          handleClick={handleDownload}/> : null}
                  {tabValue === 4 ? (
                    <WarrantyTabContent warrantyInfo={warranty} cost={row.fields.cost} />
                  ) : null}
                </Style.AssetInfoContainer>
                <Style.AssetActions>
                  <Style.StyledDiv
                    gridArea={"edit"}
                    padding={"5px 5px 0px 5px"}
                  >
                    <Button
                      variants={"edit"}
                      style={{ padding: "2px" }}
                      handleClick={() => setEdit(true)}
                    />
                  </Style.StyledDiv>
                  <Style.StyledDiv
                    gridArea={"split"}
                    padding={"5px 5px 0px 5px"}
                  >
                    <Button variants={"split"} style={{ padding: "2px" }} />
                  </Style.StyledDiv>
                  <Style.StyledDiv
                    gridArea={"copy"}
                    padding={"5px 5px 0px 5px"}
                  >
                    <Button variants={"copy"} style={{ padding: "2px" }} />
                  </Style.StyledDiv>
                  <Style.StyledDiv
                    gridArea={"delete"}
                    padding={"50px 0px 0px 5px"}
                  >
                    <Button
                      variants={"delete"}
                      style={{ padding: "2px" }}
                      handleClick={() => handleClickOpenDeleteAsset(row.id)}
                    />
                  </Style.StyledDiv>
                </Style.AssetActions>
              </Style.AssetDropdownContainer>
            </Style.DropdownContainer>
          ) : (
            <AssetForm
              initialAssetFieldState={rowAssetFieldState}
              initialAssetWarrantyState={warranty}
              initialAssetHelpersState={helpers}
              initialAssetAttachmentState={attachments}
              fieldDefinitions={fieldDefinitions}
              handleSave={(
                event,
                assetFieldState,
                assetWarrantyState,
                assetHelperState,
                assetAttachmentState
                ) => {
                handleRowUpdate(
                  event,
                  row.id,
                  assetFieldState,
                  assetWarrantyState,
                  assetHelperState,
                  assetAttachmentState
                );
                setEdit(false);
                setOpen(false);
              }}
              handleCancel={() => {
                setEdit(false);
              }}
              locations={locations}
            />
          )}
        </Collapse>
      </TableCell>
    </Style.StyledTableRow>
  );
};

RowDropdown.propTypes = {
  row: PropTypes.object,
  fieldDefinitions: PropTypes.array,
  locations: PropTypes.array,
  handleClickOpenDeleteAsset: PropTypes.func,
  setOpen: PropTypes.func,
  open: PropTypes.bool,
  handleRowUpdate: PropTypes.func,
  handleAttachmentDownload: PropTypes.func,
};

export default RowDropdown;
