import React, { useState } from "react";
import PropTypes from "prop-types";

import * as Style from "./styles";
import Input from "../../../../../components/input";
import Button from "../../../../../components/button";

import ViewDrawerRows from "./components/view-drawer-rows";

const UpsertViewDrawer = ({
	initialViewState,
	fieldDefinitionsMap,
	handleSave,
	handleCancel
}) => {

    const fieldOptions = []
    for (const [id, fieldDef] of Object.entries(fieldDefinitionsMap)) {
      fieldOptions.push({"label": fieldDef.name, "value": id});
    }

    const [viewState, setViewState] = useState(initialViewState);

    const handleChange = (event, index) => {
      const newState = { ...viewState };
      newState["rules"][index][event.target.name] = event.target.value;
      setViewState(newState)
    };
    const handleViewNameChange = (event) => {
      const newState = { ...viewState }
      newState["name"] = event.target.value;
      setViewState(newState);
    };
    const handleRuleAdd = () => {
      const newState = { ...viewState }
      newState["rules"].push({"field": "", "preposition": "", "value": "", "value2": "", "conjunction": ""});
      setViewState(newState);
    };
    const handleRuleDelete = () => {
      const newState = { ...viewState }
      newState["rules"] = newState["rules"].slice(0, newState["rules"].length-1);
      setViewState(newState);
    };

    return (
        <> 
          <Style.ViewDrawerForm>
            <Style.ViewNameContainer>
              <Style.Field
                gridArea="viewName"
              >
                <Input
                  id='viewName'
                  name='viewName'
                  type='text'
                  size='small'
                  labelName='View Name'
                  variant='outlined'
                  value={viewState["name"]}
                  handleChange={handleViewNameChange}
                />
              </Style.Field>
            </Style.ViewNameContainer>
            <ViewDrawerRows
              rules={viewState["rules"]}
              handleChange={handleChange}
              handleRuleDelete={handleRuleDelete}
              handleRuleAdd={handleRuleAdd}
              fieldDefinitionsMap={fieldDefinitionsMap}
            />
            <Style.ButtonSection>
              <Style.ButtonContainer>
                <Button
                  size="small"
                  fontSize={12}
                  style={{
                    gridArea: "cancel",
                  }}
                  handleClick={handleCancel}
                >
                  Cancel
                </Button>
                <Button
                  size="small"
                  fontSize={12}
                  style={{
                    gridArea: "save",
                  }}
                  handleClick={() => {handleSave(viewState)}}
                >
                  Save
                </Button>
              </Style.ButtonContainer>
            </Style.ButtonSection>
          </Style.ViewDrawerForm>
        </>
    ) 
};

UpsertViewDrawer.propTypes = {
  initialViewState: PropTypes.object,
  fieldDefinitionsMap: PropTypes.object,
  handleSave: PropTypes.func,
  handleCancel: PropTypes.func,
};

export default UpsertViewDrawer;
