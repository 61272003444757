import React from "react";
import * as Style from "./styles";
import Input from "../../../../../components/input";
import Select from "../../../../../components/select";
import InputSelect from "../../../../../components/input-select";
import PropTypes from "prop-types";

const AssetFormWarranty = ({
	warrantyState, 
	handleChange
}) => {
  const typeOptions = [
	{"label": "Warranty", "value": "warranty"}, 
        {"label": "Lease", "value": "lease"},
        {"label": " ", "value": null}
  ]
  const durationOptions = [
	{"label": "Days", "value": "days"}, 
        {"label": "Months", "value": "months"},
        {"label": "Years", "value": "years"},
        {"label": " ", "value": null}
  ]

  return (
    <>
      <Style.Field key={"type"}>
        <Select 
            value={warrantyState.type}
            options={typeOptions}
            id={"type"}
            name={"type"}
            type={"select"}
            labelName={"Type"}
            size='small'
            variant='outlined'
            handleChange={handleChange}
        />
      </Style.Field>
      <Style.Field key={"duration"}>
        <InputSelect 
            labelName={"Duration"}
            handleChange={handleChange}
            inputId={"duration"}
            inputName={"duration"}
            inputType={"number"}
            inputValue={warrantyState.duration}
            selectId={"duration_increment"}
            selectName={"duration_increment"}
            selectType={"text"}
            selectValue={warrantyState.duration_increment}
            selectOptions={durationOptions}
            size='small'
            variant='outlined'
        />
      </Style.Field>
      <Style.Field key={"start_date"}>
        <Input
          value={warrantyState.start_date}
          id={"start_date"}
          name={"start_date"}
          type={"date"}
          labelName={"Start Date"}
          size='small'
          variant='outlined'
          handleChange={handleChange}
        />
      </Style.Field>
    </>
  )
};

AssetFormWarranty.propTypes = {
  warrantyState: PropTypes.shape({
    type: PropTypes.string,
    cost: PropTypes.number,
    duration: PropTypes.number,
    duration_increment: PropTypes.string,
    start_date: PropTypes.string,
    end_date: PropTypes.string,
  }),
  handleChange: PropTypes.func,
};

export default AssetFormWarranty;
