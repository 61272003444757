import React from "react";
import PropTypes from "prop-types";

import * as Style from "./styles";
import { NumberPrepOptions, TextPrepOptions} from "../../../view-drawer/filter-logic.js";

const ViewDrawerDisplay = ({
	viewState,
	fieldDefinitionsMap
}) => {

  return (
      <>
        <Style.ViewDisplayContainer>
          <Style.ViewRowContainers>
            {viewState.rules.map((rule, index) => {
            let preposition = "";
            let showValue2 = false;
            if (NumberPrepOptions.hasOwnProperty(rule.preposition)) {
              preposition = NumberPrepOptions[rule.preposition].label
              showValue2 = (rule.preposition === "is_between" || rule.preposition === "is_not_between")
            }
            if (TextPrepOptions.hasOwnProperty(rule.preposition)) {
              preposition = TextPrepOptions[rule.preposition].label
            }
            return (
              <Style.ViewRowContainer key={index}>
                <Style.ViewRuleText
                  key={index}
                >
                  {rule.conjunction} {fieldDefinitionsMap[rule.field].name} {preposition} {rule.value} {showValue2 === true ? "and" : null} {showValue2 === true ? rule.value2 : null}
                </Style.ViewRuleText>
              </Style.ViewRowContainer>
            )})}
          </Style.ViewRowContainers>
        </Style.ViewDisplayContainer>
      </>
  ) 
};


ViewDrawerDisplay.propTypes = {
  viewState: PropTypes.object,
  fieldDefinitionsMap: PropTypes.object,
};

export default ViewDrawerDisplay;
