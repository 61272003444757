import styled from "styled-components";
import ClearSharpIcon from '@material-ui/icons/ClearSharp';
import DescriptionIcon from '@material-ui/icons/Description';
import Button from "../../../../../components/button";

export const AttachmentContainer = styled.div`
  display: grid;

  height: 180px;
  grid-template-columns: 1fr;
  grid-template-rows:    1fr 50px;
  grid-template-areas: 
    " attachments "
    " button ";
`
export const Attachments = styled.div`
  display: -webkit-box;
  grid-area: attachments;
  padding: 5px;
  overflow: auto;
`
export const ButtonContainer = styled.div`
  display: grid;
  grid-area: button;
  border-top: 1px solid #7288AB;
  margin: 0px 3px 0px 3px;

  grid-template-columns: 1fr 150px 1fr;
  grid-template-rows:    1fr 30px  1fr;
  grid-template-areas: 
    " . .             . "
    " . addAttachment . "
    " . .             . ";
`
export const StyledButton = styled(Button)`
  display: grid;
  grid-area: addAttachment;
  backgroundColor: "#1a90ab";
  color: "white";
`
export const Attachment = styled.div`
  display: grid;

  background: white;
  border-radius: 5px;

  height: 75px;
  width 85px;
  margin: 3px;
  overflow: hidden;

  justify-items: center;
  align-items: center;
  grid-template-columns: 1fr 1fr 1fr;
  grid-template-rows:    1fr 1fr  1fr;
  grid-template-areas: 
    " attachmentIcon attachmentIcon deleteIcon "
    " attachmentIcon attachmentIcon . "
    " attachmentName attachmentName attachmentName ";
`

export const AttachmentNameContainer = styled.div`
  display: grid;
  grid-area: attachmentName
`

export const StyledX = styled(ClearSharpIcon)`
  &.MuiSvgIcon-root {
    height: 17px;
    width: 17px;
    color: #FF9726;
  }
`;

export const StyledFileIcon = styled(DescriptionIcon)`
  &.MuiSvgIcon-root {
    height: 50px;
    width: 50px;
    color: #7288AB;
  }
`;
