import styled from "styled-components";

export const TabContainer = styled.div`
  display: grid;
  background: white;

  grid-template-columns: auto;
  grid-template-rows: 50px 1fr;
  grid-template-areas:
    " header "
    " content ";
`

export const Header = styled.div`
  display: grid;
  grid-area: header;
  background: #445b79;
  border: 1px solid #445b79;
`

export const StyledText = styled.p`
  padding-top: 5px;
  padding-left: 10px;
  text-align: left;
  font-size: 22px;
  color: white;
`;

export const Content = styled.div`
  display: flex;
  grid-area: content;
  padding 10px;
`
