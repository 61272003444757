export const options = {
  streetViewControl: false,
  mapTypeControl: false,
  maxZoom: 16,
};

export const style = {
  width: '100%',
  height: '100%',
};

export const center = {
  lat: 47.608833812302144,
  lng: -122.34003170869859,
};

export const zoom = 13;
