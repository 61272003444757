import React from "react";
import PropTypes from "prop-types";

import Navbar from "../navbar";
import * as Style from "./styles";

const Layout = ({ children }) => {
  return (
    <Style.Layout>
      <Navbar style={{ gridArea: "navbar" }} />
      <div style={{ gridArea: "children" }}>{children}</div>
    </Style.Layout>
  );
};

Layout.propTypes = {
  children: PropTypes.node.isRequired,
};

export default Layout;
