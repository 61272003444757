import React from "react";
import PropTypes from "prop-types";

import ViewDrawerDisplay from "./components/details-view-drawer";
import UpsertViewDrawer from "./components/upsert-view-drawer";

const ViewDrawer = ({
	viewState,
	fieldDefinitions,
	handleSave,
	handleCancel,
	edit
}) => {

    const fieldDefinitionsMap = {};
    if (fieldDefinitions.length !== 0) {
    fieldDefinitions.reduce((_, v) => (fieldDefinitionsMap[v.id] = {"name": v.name, "type": v.type})) 
    }

    return (
        <> 
          {edit ?  
            <UpsertViewDrawer
              initialViewState={viewState}
              fieldDefinitionsMap={fieldDefinitionsMap}
              handleSave={handleSave}
              handleCancel={handleCancel}
              edit={edit}
            />
          :
            <ViewDrawerDisplay viewState={viewState} fieldDefinitionsMap={fieldDefinitionsMap} />
          }
        </>
    ) 
};

ViewDrawer.propTypes = {
  viewState: PropTypes.object,
  fieldDefinitions: PropTypes.array,
  handleSave: PropTypes.func,
  handleCancel: PropTypes.func,
  edit: PropTypes.bool,
};

export default ViewDrawer;
