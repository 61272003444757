import styled from "styled-components";
import Typography from "@material-ui/core/Typography";

export const TabContainer = styled.div`
  display: grid;
  background: white;

  grid-template-columns: auto;
  grid-template-rows: 50px 1fr;
  grid-template-areas:
    " header "
    " content ";
`

export const Header = styled.div`
  display: grid;
  grid-area: header;
  background: #445b79;
  border: 1px solid #445b79;
`

export const Content = styled.div`
  display: grid;
  grid-area: content;
  padding 10px;

  grid-template-columns: 1fr 1fr;
  grid-template-rows: auto;
  grid-template-areas:
    " warranty cost ";
`

export const Warranty = styled.div`
  display: grid;
  grid-area: warranty;
  grid-template-columns: 1fr;
  grid-template-rows: 30px 30px 30px 30px;
`

export const Cost = styled.div`
  display: grid;
  grid-area: cost;
  grid-template-columns: 1fr;
  grid-template-rows: 30px 30px 30px;
`

export const StyledHeader = styled.p`
  padding-top: 5px;
  padding-left: 10px;
  text-align: left;
  font-size: 22px;
  color: white;
`;

export const StatusContainer = styled.div`
  display: grid;
  grid-template-columns: 50px 115px;
  grid-template-rows: 25px;
`

export const Status = styled.div`
  display: grid;
  background: ${({ backgroundColor }) => backgroundColor};
  border-radius: 5px;
  justify-items: center;
  grid-template-columns: 1fr;
  height: 22px;
`

export const StyledDiv = styled.div`
  display: grid;
  grid-area: ${({ gridArea }) => gridArea};
`;

export const StyledTypography = styled(Typography)`
  &.MuiTypography-root {
    color: ${({ color }) => color};
  }
`;
