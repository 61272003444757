import React from "react";
import { Link as SLink } from "react-router-dom";
import PropTypes from "prop-types";
import styled from "styled-components";

const StyledLink = styled(SLink)`
  color: ${({ theme }) => theme.colors.link};
  font-weight: bold;
`;

const Link = ({ children, to, ...rest }) => {
  return (
    <StyledLink to={to} {...rest}>
      {children}
    </StyledLink>
  );
};

Link.propTypes = {
  to: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
};

export default Link;
