import React, { useState, useEffect } from "react";
import { useDispatch, connect } from "react-redux";

import Collapse from "@material-ui/core/Collapse";
import PropTypes from "prop-types";


import Layout from "../../components/layout";
import * as Style from "./styles";
import {
  createAssetEffect,
  getAssetsEffect,
  deleteAssetEffect,
  updateAssetEffect,
  getFieldDefinitionsEffect,
  getViewsEffect
} from "../effects";

import Button from "../../components/button";
import CardContainer from "../../components/card-container";
import { Table } from "../../components/table/Table";
import { DeleteDialog } from "../../components/dialog/DeleteDialog.js";
import CircularProgress from "../../components/circular-progress";
import AssetForm from "../components/asset-form";

import ViewDrawer from "../components/view-drawer";

import AssetFilterTable from "../../components/asset-filter-table";
import { FilterAssetsByView } from "../components/view-drawer/filter-logic.js";

import firebase from "firebase/compat/app";
import 'firebase/storage';

const Asset = ({ assets, locations, fieldDefinitions, views, orgID }) => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getFieldDefinitionsEffect());
    dispatch(getAssetsEffect());
    dispatch(getViewsEffect());
  }, [dispatch]);

  // create asset
  const [itemDrawer, setItemDrawer] = useState(false);
  const handleSave = async (
    event,
    assetFieldState,
    assetWarrantyState,
    assetHelpersState,
    assetAttachmentState
  ) => {
    event.preventDefault();
    dispatch(
      createAssetEffect(assetFieldState, assetWarrantyState, assetHelpersState, assetAttachmentState)
    );
    setItemDrawer(false);
  };
  const newAssetFields = {};
  const newAssetWarranty = {
    type: null,
    duration: null,
    duration_increment: null,
    start_date: null,
  };
  const newAssetHelpers = [];
  const newAssetAttachment = {}

  fieldDefinitions.forEach((fieldDefinition) => {
    if ("editable" in fieldDefinition && fieldDefinition.editable === false) {
      return;
    }
    newAssetFields[fieldDefinition.id] = null;
  });

  // update asset
  const handleRowUpdate = (event, row_id, assetState, assetWarranty, assetHelpers, assetAttachment) => {
    event.preventDefault();
    dispatch(updateAssetEffect(row_id, assetState, assetWarranty, assetHelpers, assetAttachment));
  };

  // delete asset
  const [selectedAsset, setSelectedAsset] = useState(null);
  const [openDeleteAsset, setOpenDeleteAsset] = useState(false);
  const handleDeleteAsset = (asset_id) => {
    dispatch(deleteAssetEffect(asset_id));
    setOpenDeleteAsset(false);
  };
  const handleClickOpenDeleteAsset = (asset_id) => {
    setOpenDeleteAsset(true);
    setSelectedAsset(asset_id);
  };
  const handleCloseDeleteAsset = () => {
    setOpenDeleteAsset(false);
    setSelectedAsset(null);
  };

  const [assetTableCollapsed, setAssetTableCollapsed] = useState(false);

  // views
  const initialViewState = {
    "name": "New View",
    "rules": [{"conjunction": "", "field": "", "preposition": "", "value": "", "value2": ""}]
  };
  const [viewState, setViewState] = useState(initialViewState);

  // view drawer type enums 
  const viewDrawerCollapsed = 0;
  const viewDrawerUpsert = 1;
  const viewDrawerDisplay = 2;

  const [viewDrawer, setViewDrawer] = useState(viewDrawerCollapsed);
  const openDrawer = () => {
    if (viewDrawer !== viewDrawerCollapsed){return true} else {return false}
  }
  const collapsedDrawer = () => {
    if (viewDrawer === viewDrawerCollapsed){return true} else {return false}
  }
  const drawerIsUpsert = () => {
    if (viewDrawer === viewDrawerUpsert){return true} else {return false}
  }
  const drawerIsDisplay = () => {
    if (viewDrawer === viewDrawerDisplay){return true} else {return false}
  }

  // asset view table state
  const [viewTableCollapsed, setViewTableCollapsed] = useState(true);

  // view edit
  const handleViewSave = (viewState) => {
    console.log("Handle Save!");
    console.log(viewState);
  };

  const viewSelectFactory = (view) => {
    return () => {
      if (viewState.id === view.id) {
        setViewState(initialViewState);
        setViewDrawer(viewDrawerCollapsed);
      } else {
        setViewState(view);
        setViewDrawer(viewDrawerDisplay);
        setAssetTableCollapsed(false);
      }
    };
  };

  const filterAssetsByView = (viewState) => {
    if (viewDrawer === viewDrawerCollapsed) {
      return assets.assets;
    } else {
      return FilterAssetsByView(viewState, assets.assets)
    }
  };
  const filteredAssetCount = filterAssetsByView(viewState).length;

  // download asset attachment
  const handleAttachmentDownload = (assetID, attachmentName) => {
    const ref = firebase.storage()
                        .ref()
                        .child(`asset-attachments/${orgID}/${assetID}/attachments/${attachmentName}`);


    ref.getDownloadURL().then((url) => {
      // This can be downloaded directly:
      const xhr = new XMLHttpRequest();
      xhr.responseType = 'blob';
      xhr.onload = () => {
        const blob = xhr.response;
        saveBlob(blob, attachmentName)
      };
      xhr.open('GET', url);
      xhr.send();
    })
  }
  const saveBlob = (function () {
      const a = document.createElement("a");
      document.body.appendChild(a);
      a.style = "display: none";
      return function (blob, fileName) {
          var url = window.URL.createObjectURL(blob);
          a.href = url;
          a.download = fileName;
          a.click();
          window.URL.revokeObjectURL(url);
      };
  }());

  const buttonVariant = (collapsed) => {
    if (collapsed) {
      return "expand";
    } else {
      return "collapse";
    }
  }

  const CardContainerActions = () => {
     const assetActions = [
          <Button
            key={6}
            style={{ padding: "1px" }}
            variants={buttonVariant(assetTableCollapsed)}
            fontSize={12}
            handleClick={() => {setAssetTableCollapsed(!assetTableCollapsed)}}
          />,
          <Button
            key={1}
            fontSize={12}
            handleClick={() => {
              setItemDrawer(true);
            }}
            style={{
              gridArea: "addItem",
            }}
          >
            Add Asset
          </Button> 
        ]

     const viewDisplayActions = 
          <Button
            size="small"
            fontSize={12}
            onClick={() => {
              setViewDrawer(viewDrawerUpsert);
            }}
          >
            Edit View
          </Button>

    if (collapsedDrawer()) {
      return (assetActions)
    } else if (drawerIsDisplay()) {
      return (viewDisplayActions)
    } else if (drawerIsUpsert()) {
      return (null)
    }
  };

  return (
    <Layout>
      {assets.isLoading ? <CircularProgress /> : null}
      <Style.PageContainer>
        <Style.Upper>
          <CardContainer
            title="Asset Views"
            titleColor="white"
            style={{
              gridArea: "viewTable",
            }}
            actions={[
              <Button
                key={6}
                style={{ padding: "1px" }}
                variants={buttonVariant(viewTableCollapsed)}
                fontSize={12}
                handleClick={() => {setViewTableCollapsed(!viewTableCollapsed)}}
              />,
              <Button
                key={1}
                fontSize={12}
                handleClick={() => {
                  setViewDrawer(viewDrawerUpsert);
                }}
                style={{
                  gridArea: "addItem",
                }}
                disabled={drawerIsUpsert()}
              >
                New View
              </Button>
            ]}
          >
            <Collapse in={!viewTableCollapsed} timeout="auto" unmountOnExit >
              <AssetFilterTable
                filteringObjects={views}
                assets={assets.assets}
                objectState={viewState}
                onRowClick={viewSelectFactory}
                getAssetCountByObject={FilterAssetsByView}
              />
          </Collapse>
        </CardContainer>
        </Style.Upper>
        <Style.Divider />
        <Style.Lower>
          <CardContainer
            title={`${drawerIsDisplay() ? viewState.name : "All Assets "} (${filteredAssetCount > 0 ? filteredAssetCount : "..."})`}
            gridArea="table"
            actions={CardContainerActions()}
            titleColor="white"
          >
            <Collapse in={openDrawer()} timeout="auto" unmountOnExit >
              {openDrawer() && (
                <ViewDrawer
                  viewState={viewState}
                  fieldDefinitions={fieldDefinitions}
                  handleSave={handleViewSave}
                  handleCancel={() => {
                        setViewDrawer(viewDrawerCollapsed);
                        setViewState(initialViewState);
                      }}
                  edit={drawerIsUpsert()}
                />
              )}
            </Collapse>
            {itemDrawer && (
              <AssetForm
                initialAssetFieldState={newAssetFields}
                initialAssetWarrantyState={newAssetWarranty}
                initialAssetHelpersState={newAssetHelpers}
                initialAssetAttachmentState={newAssetAttachment}
                fieldDefinitions={fieldDefinitions}
                handleSave={handleSave}
                handleCancel={() => {
                  setItemDrawer(false);
                }}
                locations={locations}
              />
            )}
            <Style.ItemsTableContainer>
              <Collapse in={!assetTableCollapsed} timeout="auto" unmountOnExit >
                <Table
                  fieldDefinitions={fieldDefinitions}
                  data={filterAssetsByView(viewState)}
                  locations={locations}
                  handleClickOpenDeleteAsset={handleClickOpenDeleteAsset}
                  handleRowUpdate={handleRowUpdate}
                  handleAttachmentDownload={handleAttachmentDownload}
                />
              </Collapse>
            </Style.ItemsTableContainer>
          </CardContainer>
        </Style.Lower>
      </Style.PageContainer>
      <DeleteDialog
        title={"Delete Asset"}
        open={openDeleteAsset}
        handleClose={handleCloseDeleteAsset}
        handleDelete={handleDeleteAsset}
        id={selectedAsset}
      />
    </Layout>
  );
};

const mapStateToProps = (state) => ({
  assets: state.assets,
  locations: state.location.locations,
  views: state.assets.views,
  fieldDefinitions: state.assets.fieldDefinitions,
  orgID: state.user.org_id,
});

Asset.propTypes = {
  fieldDefinitions: PropTypes.array,
  assets: PropTypes.shape({
    isLoading: PropTypes.bool,
    error: PropTypes.string,
    assets: PropTypes.array,
    new_asset: PropTypes.shape({
      given_id: PropTypes.string,
      quantity: PropTypes.number,
      name: PropTypes.string,
      location_id: PropTypes.string,
    }),
  }),
  locations: PropTypes.array.isRequired,
  views: PropTypes.array.isRequired,
  orgID: PropTypes.string.isRequired,
};

export default connect(mapStateToProps)(Asset);
