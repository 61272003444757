import React, { useState, useRef } from "react";
import { connect } from "react-redux";
import PropTypes from 'prop-types';
import AccountBoxIcon from '@material-ui/icons/AccountBox';

import Layout from "../../components/layout";
import Button from "../../components/button";
import Input from "../../components/input";
import * as Style from "./styles";
import { uploadAvatarEffect, updateUserInfoEffect } from "../effects";

const Profile = ( {dispatch, email, avatar_url, first_name, last_name, user_id, user_token} ) => {
  const fileRef = useRef();
  const [userInfo, setUserInfo] = useState({
    first_name: first_name,
    last_name: last_name,
  });

  const onFileChange = e => {
    dispatch(uploadAvatarEffect(user_token, user_id, e.target.files[0]));
  };

  const handleChange = (event) => {
    setUserInfo({ ...userInfo, [event.target.name]: event.target.value });
  };

  const onUpdateClick = (e) => {
    dispatch(updateUserInfoEffect(userInfo));
  };

  return (
    <Layout>
      <Style.PageContainer>
        <Style.Upper>
          <Style.Header>
            My Profile
          </Style.Header>
          <Style.Avatar>
            {avatar_url ?
              <img src={avatar_url} alt="user avatar" width="170px" height="170px" /> :
              <AccountBoxIcon style={{
                fill: '#cfcecd',
                fontSize: '170px'
              }}/>
            }
            <input
              type="file"
              accept="image/*"
              onChange={onFileChange}
              ref={fileRef}
              style={{display: "none"}}/>
            <Button
              size='small'
              fontSize={12}
              margin='auto'
              display='block'
              handleClick={() => {
                fileRef.current.click();
              }}
            >
              Upload Avatar
            </Button>
          </Style.Avatar>
          <Style.UserInfo>
            <Input
              required
              id='first_name'
              name='first_name'
              type='text'
              value={userInfo.first_name}
              labelName={"First Name"}
              size='small'
              variant='outlined'
              handleChange={handleChange}
            />
            <Input
              required
              id='last_name'
              name='last_name'
              type='text'
              value={userInfo.last_name}
              labelName={"Last Name"}
              size='small'
              variant='outlined'
              handleChange={handleChange}
            />
            <Input
              required
              id='email'
              name='email'
              type='text'
              value={email}
              labelName={"Email"}
              size='small'
              variant='outlined'
              handleChange={() => {}}
              disabled
            />
            <Button
              size='small'
              fontSize={12}
              margin='auto'
              display='block'
              handleClick={onUpdateClick}
            >
              Update User
            </Button>
          </Style.UserInfo>
        </Style.Upper>
        <Style.Lower>
          <Style.Header>
            My Organizations
          </Style.Header>
        </Style.Lower>
      </Style.PageContainer>
    </Layout>
  );
};

const mapStateToProps = state => ({
  email: state.user.username,
  user_id: state.user.user_id,
  user_token: state.user.user_token,
  first_name: state.user.first_name,
  last_name: state.user.last_name,
  avatar_url: state.user.avatar_url,
});

Profile.propTypes = {
  email: PropTypes.string.isRequired,
  user_id: PropTypes.string.isRequired,
  user_token: PropTypes.string.isRequired,
  avatar_url: PropTypes.string
}

export default connect(mapStateToProps)(Profile);
