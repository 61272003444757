import React from "react";
import * as Style from "./styles";

const AssetPhoto = ({
	assetState, 
	fieldDefinitions,
	handleChange
}) => {
  return (
    <>
      <Style.PhotoContainer>
        <Style.ImageContainer>
        </Style.ImageContainer>
        <Style.ButtonContainer>
          <Style.StyledButton
            size="small"
            fontSize={12}
          >
            Update Photo
          </Style.StyledButton>
        </Style.ButtonContainer>
      </Style.PhotoContainer>
    </>
  )
};

export default AssetPhoto;
