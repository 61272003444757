import React from "react";
import * as Style from "./styles";
import Typography from "@material-ui/core/Typography";
import PropTypes from 'prop-types';

const Attachment = ({
	handleClick,
	handleDelete,
	label,
}) => {

  if (handleDelete) {
    return (
     <>
       <Style.Attachment>
         <Style.StyledFileIcon/>
         <Style.StyledX
           onClick={() => {handleDelete()}}
         />
         <Style.AttachmentNameContainer>
           <Style.StyledText variant="body2">
             {label}
           </Style.StyledText>
         </Style.AttachmentNameContainer>
       </Style.Attachment>
     </>
    )
  } else {
    return (
     <>
      <Style.Attachment 
        pointer={"true"}
        onClick={() => {handleClick()}}
      >
        <Style.StyledFileIcon/>
        <Style.AttachmentNameContainer>
          <Typography variant="body2">
            {label}
          </Typography>
        </Style.AttachmentNameContainer>
      </Style.Attachment>
     </>
    )
  }
};

export default Attachment;

Attachment.propTypes = {
  handleClick: PropTypes.func,
  handleDelete: PropTypes.func,
  label: PropTypes.string,
}
