import React from "react";
import * as Style from "./styles";
import Typography from "@material-ui/core/Typography";
import PropTypes from "prop-types";

const white = "white"
const red = "red"
const green = "#01b65f"
const yellow = "#ff9726"
const threeMonthsInMS = 7889400000

const WarrantyTabContent = ({ warrantyInfo, cost }) => {

  let statusText = () => {
    if (warrantyInfo.type === "warranty") { return "Warranty"}
    if (warrantyInfo.type === "lease") { return "Lease"}
    return null
  }

  const withinDate = () => {
    if (warrantyInfo.end_date === null) {
      return null
    }
    const todayInMs = Date.now()
    const endDateInMs = new Date(warrantyInfo.end_date).getTime()
    if (todayInMs >= endDateInMs) {
      return "expired"
    }
    if (todayInMs + threeMonthsInMS >= endDateInMs) {
      return "3 months"
    }
    return "unexpired"
  }

  let statusColor = white
  switch (withinDate()) {
    case "unexpired":
      statusText = `In ${statusText()}`
      statusColor = green
      break
    case "3 months":
      statusText = `In ${statusText()}`
      statusColor = yellow
      break
    case "expired":
      statusText = `Out Of ${statusText()}`
      statusColor = red
      break
    default:
  }

  return (
    <Style.TabContainer>
      <Style.Header>
        <Style.StyledHeader>
          Cost
        </Style.StyledHeader>
      </Style.Header>
      <Style.Content>
        <Style.Warranty>
          <Style.StatusContainer>
              <Typography variant="body2">
                Status: 
              </Typography>
            <Style.Status backgroundColor={statusColor}>
              <Style.StyledTypography variant="body2" color="white">
                {statusText}
              </Style.StyledTypography>
            </Style.Status>
          </Style.StatusContainer>
          <Typography variant="body2">
            Start Date: {warrantyInfo.start_date}
          </Typography>
          <Typography variant="body2">
            Expiration: {warrantyInfo.end_date}
          </Typography>
          <Style.StyledTypography variant="body2" color={statusColor}>
            Duration: {warrantyInfo.duration} {warrantyInfo.duration_increment}
          </Style.StyledTypography>
        </Style.Warranty>
        <Style.Cost>
          <Typography variant="body1">
            Cost
          </Typography>
          <Typography variant="body2">
            Original Cost: ${cost}
          </Typography>
          <Typography variant="body2">
            Current Value: ${cost}
          </Typography>
        </Style.Cost>
      </Style.Content>
    </Style.TabContainer>
  )
};

WarrantyTabContent.propTypes = {
  warrantyInfo: PropTypes.shape({
    type: PropTypes.string,
    duration: PropTypes.number,
    duration_increment: PropTypes.string,
    start_date: PropTypes.string,
    end_date: PropTypes.string,
  }),
  cost: PropTypes.number,
};

export default WarrantyTabContent;

