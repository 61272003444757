import axios from "axios";

const assetApiHost = process.env.REACT_APP_API_HOST;
const assetApiDomain = process.env.REACT_APP_COOKIE_DOMAIN;

export function CreateAsset(userToken, orgID, createAsset) {
    const url = assetApiHost + `/organizations/${orgID}/assets`;
    const data = axios.post(url, { 
            location_id: createAsset.locationID,
            given_id: createAsset.givenID,
            name: createAsset.name,
            quantity: createAsset.quantity,
            cost: createAsset.cost,
            custom_columns: createAsset.customColumns,
            warranty: createAsset.assetWarranty,
            helpers: createAsset.assetHelpers,
            attachments: createAsset.attachments,
          }, {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `${userToken}`
            }
	})
   return data
}

export function GetAssets(userToken, orgID) {
    const url = assetApiHost + `/organizations/${orgID}/assets`;
    const data = axios.get(url, {
        headers: {
            'Authorization': `${userToken}`
        }
    })
   return data
}

export function GetAsset(user_token, org_id, asset_id) {
    const url = assetApiHost + `/organizations/${org_id}/assets/${asset_id}`;
    const data = axios.get(url, {
        headers: {
            'Authorization': `${user_token}`
        }
    })
   return data
}

export function DeleteAsset(user_token, org_id, asset_id) {
    const url = assetApiHost + `/organizations/${org_id}/assets/${asset_id}`;
    const data = axios.delete(url, {
        headers: {
            'Authorization': `${user_token}`
        }
    })
   return data
}

export function UpdateAsset(userToken, orgID, updateAsset) {
    const url = assetApiHost + `/organizations/${orgID}/assets/${updateAsset.assetID}`;
    const data = axios.put(url, { 
            location_id: updateAsset.locationID,
            given_id: updateAsset.givenID,
            name: updateAsset.name,
            quantity: updateAsset.quantity,
            cost: updateAsset.cost,
            custom_columns: updateAsset.customColumns,
            warranty: updateAsset.assetWarranty,
            helpers: updateAsset.assetHelpers,
            attachments: updateAsset.attachments,
          }, {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `${userToken}`
            }
	}
    )
   return data
}

export function ExportAssets(user_token, org_id) {
  const url = `${assetApiHost}/organizations/${org_id}/export/assets`;
  document.cookie = `token=${user_token};Domain=${assetApiDomain};SameSite=none;Secure=true;Max-Age=15;`;

  const link = document.createElement('a');
  link.href = url;
  link.setAttribute('download', '');
  document.body.appendChild(link);
  link.click();
  link.remove();
}
