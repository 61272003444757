import React from "react";
import * as Style from "./styles";
import HelperChip from "../../../../components/helper-chip"

const AssetHelpers = ({
	assetHelpersState, 
	handleChange,
	handleDelete,
        handleSave
}) => {
  return (
    <>
      <Style.HelpersContainer>
        {assetHelpersState.chips.map((helper, index) => (
          <HelperChip key={index}
            label={helper}
            onDelete={() => handleDelete(index)}
          />
	))}
        <Style.StyledTextField 
          size="small"
          type="text"
          onChange={handleChange}
          value={assetHelpersState.input}
          onKeyDown={handleSave}
	/>
      </Style.HelpersContainer>
    </>
  )
};

export default AssetHelpers;
