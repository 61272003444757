import React from "react";
import PropTypes from "prop-types";
import Typography from "@material-ui/core/Typography";
import Input from "../../../components/input";
import Button from "../../../components/button";
import { PieChart, Pie, Tooltip, Cell } from "recharts";

import * as Style from "./styles";

const COLORS = ["#0088FE", "#00C49F", "#FFBB28", "#FF8042"];

const LocationDetails = ({
  dispatch,
  edit,
  setEdit,
  locationState,
  handleEditSave,
  handleClickOpenDeleteLocation,
  formRef,
  handleChange,
  user_latitude,
  user_longitude,
  setAlert,
  distance,
  formatLocationString,
  locationAssets,
  fieldDefinitions,
}) => {
  // TODO: handle field name dynamically.
  const availabilityField = fieldDefinitions.find(
    (field) => field.name === "Availability"
  );

  let availabilities = [];
  if (availabilityField && availabilityField.extra_info) {
    availabilityField.extra_info.forEach((option) =>
      availabilities.push({
        name: option,
        count: 0,
      })
    );

    locationAssets.forEach((locationAsset) => {
      availabilities.forEach((availability) => {
        if (availability.name === locationAsset.fields[availabilityField.id]) {
          availability.count += 1;
        }
      });
    });

    availabilities = availabilities.filter(
      (availability) => availability.count > 0
    );
  }

  const location = locationState;
  // get distance and get directions
  const getDirectionsToLocation = (destination) => {
    if (!user_latitude || !user_longitude) {
      dispatch(setAlert("warning", "User origin cannot be found"));
      return;
    }
    const origin = `${user_latitude},${user_longitude}`;
    window.open(
      `https://www.google.com/maps/dir/?api=1&origin=${origin}&destination=${destination}&travelmode=car`
    );
  };

  const comma = location.address1 ? "," : "";
  const address2 = location.address2 ? `${comma} ${location.address2}` : "";
  return (
    <Style.FormContainer ref={formRef}>
      <Style.ItemContainer edit={edit}>
        {edit && (
          <Style.ItemActions>
            <Style.StyledDiv gridArea={"action"}>
              <Button
                variants={"save"}
                style={{ padding: "2px" }}
                handleClick={(e) => {
                  handleEditSave(e, locationState.id);
                }}
              />
              <Button
                variants={"cancel"}
                style={{ padding: "2px" }}
                handleClick={() => setEdit(false)}
              />
            </Style.StyledDiv>
            <Style.StyledDiv gridArea={"delete"} style={{ alignItems: "end" }}>
              <Button
                variants={"delete"}
                style={{ padding: "2px" }}
                handleClick={() =>
                  handleClickOpenDeleteLocation(locationState.id)
                }
              />
            </Style.StyledDiv>
          </Style.ItemActions>
        )}
        {edit ? (
          <Style.EditItemContainer>
            <Style.InputStyle gridArea={"name"}>
              <Input
                required
                id="name"
                name="name"
                type="text"
                disabled={!edit}
                labelName={"Name"}
                size="small"
                variant="outlined"
                defaultValue={locationState.name}
                handleChange={handleChange}
              />
            </Style.InputStyle>
            <Style.InputStyle gridArea={"address1"}>
              <Input
                id="address1"
                name="address1"
                type="text"
                disabled={!edit}
                labelName={"Address 1"}
                size="small"
                variant="outlined"
                defaultValue={locationState.address1}
                handleChange={handleChange}
              />
            </Style.InputStyle>
            <Style.InputStyle gridArea={"address2"}>
              <Input
                id="address2"
                name="address2"
                type="text"
                disabled={!edit}
                labelName={"Address 2"}
                size="small"
                variant="outlined"
                defaultValue={locationState.address2}
                handleChange={handleChange}
              />
            </Style.InputStyle>
            <Style.InputStyle gridArea={"city"}>
              <Input
                id="city"
                name="city"
                type="text"
                disabled={!edit}
                labelName={"City"}
                size="small"
                variant="outlined"
                defaultValue={locationState.city}
                handleChange={handleChange}
              />
            </Style.InputStyle>
            <Style.InputStyle gridArea={"region"}>
              <Input
                id="region"
                name="region"
                type="text"
                disabled={!edit}
                labelName={"State"}
                size="small"
                variant="outlined"
                defaultValue={locationState.region}
                handleChange={handleChange}
              />
            </Style.InputStyle>
            <Style.InputStyle gridArea={"postal"}>
              <Input
                id="postalcode"
                name="postalcode"
                type="text"
                disabled={!edit}
                labelName={"Postal Code"}
                size="small"
                variant="outlined"
                defaultValue={locationState.postalcode}
                handleChange={handleChange}
              />
            </Style.InputStyle>
            <Style.InputStyle gridArea={"country"}>
              <Input
                id="country"
                name="country"
                type="text"
                disabled={!edit}
                labelName={"Country"}
                size="small"
                variant="outlined"
                defaultValue={locationState.country}
                handleChange={handleChange}
              />
            </Style.InputStyle>
            <Style.InputStyle gridArea={"latitude"}>
              <Input
                id="latitude"
                name="latitude"
                type="number"
                disabled={!edit}
                labelName={"Latitude"}
                size="small"
                variant="outlined"
                defaultValue={locationState.latitude}
                handleChange={handleChange}
              />
            </Style.InputStyle>
            <Style.InputStyle gridArea={"longitude"}>
              <Input
                id="longitude"
                name="longitude"
                type="number"
                disabled={!edit}
                labelName={"Longitude"}
                size="small"
                variant="outlined"
                defaultValue={locationState.longitude}
                handleChange={handleChange}
              />
            </Style.InputStyle>
          </Style.EditItemContainer>
        ) : (
          <Style.LocationDetailsContainer>
            <Style.LocationChartContainer>
              {availabilities && availabilities.length > 0 ? (
                <PieChart width={150} height={150}>
                  <Pie
                    dataKey="count"
                    data={availabilities}
                    cx="50%"
                    cy="50%"
                    outerRadius={40}
                    fill="#8884d8"
                    label
                  >
                    {availabilities.map((_, index) => (
                      <Cell
                        key={`cell-${index}`}
                        fill={COLORS[index % COLORS.length]}
                      />
                    ))}
                  </Pie>
                  <Tooltip />
                </PieChart>
              ) : (
                <Typography>
                  To checkout Pie chart here, add Availability attribute to your
                  assets.
                </Typography>
              )}
            </Style.LocationChartContainer>
            <Style.LocationAttributesContainer>
              <Style.LocationNameContainer>
                <Typography variant="h5">{locationState.name}</Typography>
              </Style.LocationNameContainer>
              <Style.AddressContainer>
                <Typography variant="body2">
                  {location.address1}
                  {address2}
                </Typography>
              </Style.AddressContainer>
              <Style.AddressRegionContainer>
                <Typography variant="body2">
                  {`${location.city} ${location.region}`}{" "}
                  {location.postalcode && `${location.postalcode}`}
                </Typography>
              </Style.AddressRegionContainer>
              <Style.CoordinatesContainer>
                <Typography variant="body2">
                  {location.latitude || location.latitude === 0
                    ? `Latitude: ${location.latitude}`
                    : null}{" "}
                  {location.longitude || location.longitude === 0
                    ? `Longitude: ${location.longitude}`
                    : null}
                </Typography>
              </Style.CoordinatesContainer>
            </Style.LocationAttributesContainer>

            <Style.DirectionsContainer>
              {distance ? (
                <Typography
                  variant="h6"
                  style={{
                    gridArea: "distance",
                  }}
                >
                  {distance}
                </Typography>
              ) : null}
              <Style.GetDirectionsButtonContainer>
                <Button
                  size="small"
                  fontSize={12}
                  disabled={!user_latitude || !user_longitude}
                  handleClick={() => {
                    getDirectionsToLocation(
                      formatLocationString(
                        locationState.address1,
                        locationState.city,
                        locationState.region,
                        locationState.postalCode,
                        locationState.country,
                        locationState.latitude,
                        locationState.longitude
                      )
                    );
                  }}
                  style={{
                    gridArea: "getDirections",
                  }}
                >
                  Get Directions
                </Button>
              </Style.GetDirectionsButtonContainer>
            </Style.DirectionsContainer>
          </Style.LocationDetailsContainer>
        )}
        {edit && <Style.Border />}
      </Style.ItemContainer>
    </Style.FormContainer>
  );
};

LocationDetails.propTypes = {
  locationState: PropTypes.object.isRequired,
  locationAssets: PropTypes.array.isRequired,
  fieldDefinitions: PropTypes.array.isRequired,
};

export default LocationDetails;
