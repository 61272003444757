import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import Logo from "../../shared/assets/light-shingo-logo.png";
import Input from "../../components/input";
import { loginEffect } from "../effects";
import * as routes from "../../shared/routes/paths";
import * as Style from "./styles";
import { connect } from "react-redux";
import PropTypes from "prop-types";

const Login = ({ error}) => {
  const dispatch = useDispatch();

  const [state, setState] = useState({
    email: "",
    password: "",
  });
  
  const [errorState, setErrorState] = useState({
    emailError: false,
    passwordError: false,
    emailComplexity: false,
    message: "",
    code: ""
  });

  const errorStateDict = { "auth/too-many-requests": "Access to this account has been temporarily disabled due to many failed login attempts. " 
  +"You can immediately restore it by resetting your password or you can try again later.", 
  "auth/user-not-found": "There is no user record corresponding to this identifier. The user may have been deleted.",
  "auth/wrong-password": "The password is invalid or the user does not have a password."
  };

  useEffect(() => {
    setErrorState({...errorState, 
      code: error.code,
  })
     // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [error]);

  const inputIsInvalid = (val) => {
    if (val === "email") {
      return state.email === "";
    }
    if (val === "password") {
      return state.password === "";
    }
    if (val === "emailComplexity") {
      //regex to check that there is an @ symbol and a . symbol
      var re = /^\S+@\S+\.\S+$/;
      const email = state.email;
      return !re.test(email);
    }
    if (val === "invalidUserPasswordCombo") {
      if (errorStateDict[errorState.code]) {
        return true
      }
    }
    return false;
  }
    
  const handleSubmit = (event) => {
    event.preventDefault();
    const emailError = inputIsInvalid("email");
    const passwordError = inputIsInvalid("password");
    const emailComplexity = inputIsInvalid("emailComplexity");
    
    setErrorState({
      emailError: emailError,
      passwordError: passwordError,
      emailComplexity: emailComplexity,
      });
    //if any of these are true means there is invalid input from the user
    if (emailError || passwordError || emailComplexity) {
      return 
    } else {
         dispatch(loginEffect(state.email, state.password));
        }
  };

  const handleChange = (event) => {
    setState({ ...state, [event.target.name]: event.target.value });
  };
  return (
    <Style.Background>
      <p>
        <img src={Logo} alt="" />
      </p>
      <br />
      <Style.StyledForm onSubmit={handleSubmit}>
        <Input
          id="email"
          name="email"
          type="email"
          size="small"
          labelName="Email"
          value={state.email}
          variant="outlined"
          handleChange={handleChange}
          style={{ marginBottom: "5px" }}
          error ={errorState.emailError || errorState.invalidUserPasswordCombo || errorState.emailComplexity || inputIsInvalid("invalidUserPasswordCombo")}
        />
      </Style.StyledForm>
      <Style.StyleErrorMessage style={{ marginBottom: "10px" }}>
        {errorState.emailError && "Email required"}
        {errorState.emailComplexity && !errorState.emailError && "Please enter valid email"}
      </Style.StyleErrorMessage>
      <Style.StyledForm>
        <Input
          id="password"
          name="password"
          type="password"
          size="small"
          labelName="Password"
          value={state.password}
          variant="outlined"
          handleChange={handleChange}
          style={{ marginBottom: "5px" }}
          error = {inputIsInvalid("invalidUserPasswordCombo") || errorState.passwordError}
        />
      </Style.StyledForm>
      <Style.StyleErrorMessage style={{ marginBottom: "10px" }}>
        {errorState.passwordError && "Password required"}
        {!errorState.passwordError && !errorState.emailError && !errorState.emailComplexity 
        && inputIsInvalid("invalidUserPasswordCombo") && 
        errorStateDict[errorState.code]}
      </Style.StyleErrorMessage>
      <Style.StyledForm>
        <Style.StyledButton
          renderas="button"
          variant="contained"
          color="primary"
          href="/"
          handleClick={handleSubmit}
        >
          Sign in
        </Style.StyledButton>
      </Style.StyledForm>
      <Style.LinksContainer>
        <Style.StyledLink
          style={{ gridArea: "forgotPassword", paddingBottom: "5px" }}
          to={""} //TODO: will be forgot password route
        >
          Forgot your password?
        </Style.StyledLink>
        <Style.StyledText style={{ gridArea: "signUp", paddingBottom: "5px" }}>
          or
          <Style.StyledLink to={routes.SIGNUP}> Sign up</Style.StyledLink>
        </Style.StyledText>
      </Style.LinksContainer>
    </Style.Background>
  );
}

const mapStateToProps = (state) => ({
  login: state.login,
  error: state.login.error,
});

Login.propTypes = {
 login: PropTypes.shape({ 
  error: PropTypes.shape({ 
    error: PropTypes.string,
  })
}),
};

export default connect(mapStateToProps)(Login);

