import {
  AVATAR_URL_UPDATED,
  USER_LOGGED_IN,
  USER_LOGGED_OUT,
  USER_INFO_UPDATED,
  AVATAR_ERROR,
  USER_ORG_ADMIN_UPDATE,
  USER_ORG_ADMIN_UPDATE_ERROR,
} from "./ActionTypes";

export const avatarURLUpdated = (avatar_url) => ({
  type: AVATAR_URL_UPDATED,
  avatar_url,
});

export const userLoggedIn = (
  username,
  user_id,
  user_token,
  first_name,
  last_name,
  avatar_url,
  org_id,
  org_name,
  is_org_admin
) => ({
  type: USER_LOGGED_IN,
  username,
  user_id,
  user_token,
  first_name,
  last_name,
  avatar_url,
  org_id,
  org_name,
  is_org_admin,
});

export const userInfoUpdated = (first_name, last_name) => ({
  type: USER_INFO_UPDATED,
  first_name,
  last_name,
});

export const userLoggedOut = () => ({
  type: USER_LOGGED_OUT,
});

export const avatarUploadError = (error) => ({
  type: AVATAR_ERROR,
  error,
});

export const userOrgAdminUpdate = (orgUser) => ({
  type: USER_ORG_ADMIN_UPDATE,
  payload: orgUser,
});

export const userOrgAdminUpdateError = (error) => ({
  type: USER_ORG_ADMIN_UPDATE_ERROR,
  error,
});
