import {
  CreateAsset,
  GetAssets,
  DeleteAsset,
  UpdateAsset,
} from "../../api/asset.service";
import { GetOrgCustomColumns } from "../../api/organization.service.js";
import {
  createAsset,
  createAssetError,
  getAssets,
  getAssetsError,
  deleteAsset,
  deleteAssetError,
  updateAsset,
  updateAssetError,
  fieldDefinitionsRequested,
  fieldDefinitionsFetched,
  fieldDefinitionsError,
  getViews,
} from "../actions";
import { getLocationsEffect } from "../../locations/effects";
import { setAlert } from "../../components/alert/actions";
import moment from "moment";
import firebase from "firebase/compat/app";
import "firebase/storage";

export const createAssetEffect =
  (assetFields, assetWarranty, assetHelpers, assetAttachments) =>
  async (dispatch, getState) => {
    try {
      // Build attachments for backend mapping
      const attachments = {};
      Object.keys(assetAttachments).forEach((attachmentName) => {
        attachments[attachmentName] =
          assetAttachments[attachmentName]["file_path"];
      });

      // setup customColumns
      const customColumns = Object.assign({}, assetFields);
      delete customColumns.location_id;
      delete customColumns.location;
      delete customColumns.given_id;
      delete customColumns.name;
      delete customColumns.quantity;
      delete customColumns.cost;

      // Format date and localize
      if (assetWarranty.start_date != null) {
        assetWarranty.start_date = moment(assetWarranty.start_date).format();
      }

      const createAssetObject = {
        locationID: assetFields.location_id,
        givenID: assetFields.given_id,
        name: assetFields.name,
        quantity: Number(assetFields.quantity),
        cost: Number(assetFields.cost),
        customColumns,
        assetWarranty,
        assetHelpers,
        attachments,
      };

      const response = await CreateAsset(
        getState().user.user_token,
        getState().user.org_id,
        createAssetObject
      );

      // Upload any asset attachments
      Object.keys(assetAttachments).forEach((attachmentName) => {
        if (assetAttachments[attachmentName]["file"] != null) {
          const ref = firebase
            .storage()
            .ref()
            .child(
              `asset-attachments/${getState().user.org_id}/${
                response.data.body.id
              }/attachments/${attachmentName}`
            );
          ref
            .put(assetAttachments[attachmentName]["file"])
            .then(() => {})
            .catch((err) => {
              console.log(err);
            });
        }
      });

      dispatch(createAsset());
      dispatch(getAssetsEffect());
      dispatch(setAlert("success", "Asset Added Successfully"));
    } catch (error) {
      dispatch(createAssetError(error.message));
      dispatch(setAlert("error", "Error Adding Asset"));
    }
  };

export const getAssetsEffect = () => async (dispatch, getState) => {
  try {
    const resp = await GetAssets(
      getState().user.user_token,
      getState().user.org_id
    );

    dispatch(getLocationsEffect()).then(() => {
      const responseBody = resp.data.body;
      if (!responseBody || responseBody.length === 0) {
        dispatch(getAssets([]));
        return;
      }

      const locationMap = {};
      getState().location.locations.forEach((loc) => {
        locationMap[loc.id] = loc.name;
      });

      const assets = responseBody.map((asset) => {
        const newAsset = {};
        newAsset["id"] = asset.id;
        newAsset["org_id"] = asset.org_id;
        // Move default fields under fields
        newAsset["fields"] = {};
        newAsset["fields"]["name"] = asset.name;
        newAsset["fields"]["quantity"] = asset.quantity;
        newAsset["fields"]["updated_time"] = asset.updated_time;
        newAsset["fields"]["given_id"] = asset.given_id;
        newAsset["fields"]["location_id"] = asset.location_id;
        newAsset["fields"]["cost"] = asset.cost;
        newAsset["fields"]["location"] = locationMap[asset.location_id];

        newAsset["warranty"] = asset.warranty;
        newAsset["helpers"] = asset.helpers;

        if (asset.attachments) {
          newAsset["attachments"] = {};
          Object.keys(asset.attachments).forEach((key) => {
            newAsset["attachments"][key] = {
              file_path: asset.attachments[key],
              file: null,
            };
          });
        }
        if (asset.custom_columns) {
          Object.keys(asset.custom_columns).forEach((key) => {
            newAsset["fields"][key] = asset.custom_columns[key];
          });
        }
        return newAsset;
      });
      dispatch(getAssets(assets));
    });
  } catch (error) {
    alert(error.message);
    dispatch(getAssetsError(error.message));
  }
};

export const deleteAssetEffect = (assetID) => async (dispatch, getState) => {
  try {
    await DeleteAsset(
      getState().user.user_token,
      getState().user.org_id,
      assetID
    );
    const ref = firebase
      .storage()
      .ref()
      .child(
        `asset-attachments/${getState().user.org_id}/${assetID}/attachments`
      );

    ref.listAll().then((res) => {
      res.items.forEach((item) => {
        const delRef = firebase.storage().ref().child(`${item.fullPath}`);
        delRef
          .delete()
          .then(() => {})
          .catch((error) => {
            dispatch(deleteAssetError(error.message));
          });
      });
    });
    dispatch(deleteAsset());
    dispatch(getAssetsEffect());
    dispatch(setAlert("success", "Asset Deleted Successfully"));
  } catch (error) {
    dispatch(deleteAssetError(error.message));
    dispatch(setAlert("error", "Error Deleting Asset"));
  }
};

export const updateAssetEffect =
  (assetID, assetFields, assetWarranty, assetHelpers, assetAttachments) =>
  async (dispatch, getState) => {
    try {
      // Upload any asset attachments and build attachments for backend mapping
      const attachments = {};
      Object.keys(assetAttachments).forEach((attachmentName) => {
        attachments[attachmentName] =
          assetAttachments[attachmentName]["file_path"];
        if (assetAttachments[attachmentName]["file"] != null) {
          const ref = firebase
            .storage()
            .ref()
            .child(
              `asset-attachments/${
                getState().user.org_id
              }/${assetID}/attachments/${attachmentName}`
            );
          ref
            .put(assetAttachments[attachmentName]["file"])
            .then(() => {})
            .catch((err) => {
              console.log(err);
            });
        }
      });

      // setup customColumns
      const customColumns = Object.assign({}, assetFields);
      delete customColumns.location_id;
      delete customColumns.location;
      delete customColumns.given_id;
      delete customColumns.name;
      delete customColumns.quantity;
      delete customColumns.cost;

      // Format date and localize
      if (assetWarranty.start_date != null) {
        assetWarranty.start_date = moment(assetWarranty.start_date).format();
      }

      const updateAssetObject = {
        assetID: assetID,
        locationID: assetFields.location_id,
        givenID: assetFields.given_id,
        name: assetFields.name,
        quantity: Number(assetFields.quantity),
        cost: Number(assetFields.cost),
        customColumns,
        assetWarranty,
        assetHelpers,
        attachments,
      };
      await UpdateAsset(
        getState().user.user_token,
        getState().user.org_id,
        updateAssetObject
      );
      dispatch(updateAsset());
      dispatch(getAssetsEffect());
      dispatch(setAlert("success", "Asset Edited Successfully"));
    } catch (error) {
      dispatch(updateAssetError(error.message));
      dispatch(setAlert("error", "Error Editing Asset"));
    }
  };

export const getFieldDefinitionsEffect = () => async (dispatch, getState) => {
  const defaultFieldDefinitions = [
    {
      id: "name",
      name: "Name",
      type: "string",
      position: 2,
      required: true,
      editable: true,
    },
    {
      id: "given_id",
      name: "Identifier",
      type: "string",
      position: 1,
      required: true,
      editable: true,
    },
    {
      id: "quantity",
      name: "Blk Quantity",
      type: "number",
      not_negative: true,
      position: 7,
      required: true,
      editable: true,
    },
    {
      id: "cost",
      name: "Cost",
      type: "number",
      not_negative: true,
      position: 8,
      required: true,
      editable: true,
    },
    {
      id: "updated_time",
      name: "Last Modified",
      type: "string",
      position: 9,
      required: false,
      editable: false,
    },
    {
      id: "location",
      name: "Location",
      type: "string",
      position: 5,
      required: false,
      editable: true,
    },
  ];
  try {
    dispatch(fieldDefinitionsRequested());
    const resp = await GetOrgCustomColumns(
      getState().user.user_token,
      getState().user.org_id
    );

    // TODO: This is a hack! - Replace with backend column "position" on custom_columns
    let counter = 9;
    const fieldsWithPositions = resp.data.body.map((custom_column) => {
      if (custom_column.name === "Make") {
        custom_column["position"] = 3;
        return custom_column;
      }
      if (custom_column.name === "Model") {
        custom_column["position"] = 4;
        return custom_column;
      }
      if (custom_column.name === "Availability") {
        custom_column["position"] = 6;
        return custom_column;
      }
      counter += 1;
      custom_column["position"] = counter;
      return custom_column;
    });

    const fieldDefinitions =
      defaultFieldDefinitions.concat(fieldsWithPositions);
    fieldDefinitions.sort((a, b) => {
      return a.position - b.position;
    });
    dispatch(fieldDefinitionsFetched(fieldDefinitions));
  } catch (error) {
    alert(error.message);
    dispatch(fieldDefinitionsError(error.message));
  }
};

// Views

export const getViewsEffect = () => async (dispatch) => {
  const views = [
    {
      id: "123-345-1232",
      name: "Is Not A Floof",
      rules: [
        {
          conjunction: "",
          field: "name",
          preposition: "is_not",
          value: "Floof",
        },
      ],
    },
    {
      id: "bla-bla",
      name: "Is a Floofy",
      rules: [
        { conjunction: "", field: "name", preposition: "is", value: "Floof" },
      ],
    },
    {
      id: "is between",
      name: "Cost Is Between",
      rules: [
        {
          conjunction: "",
          field: "cost",
          preposition: "is_between",
          value: "100",
          value2: "200",
        },
      ],
    },
    {
      id: "is not between",
      name: "Cost Is Not Between",
      rules: [
        {
          conjunction: "",
          field: "cost",
          preposition: "is_not_between",
          value: "100",
          value2: "200",
        },
      ],
    },
    {
      id: "between two floofs",
      name: "Is Not A Floof And Not Between",
      rules: [
        {
          conjunction: "",
          field: "name",
          preposition: "is_not",
          value: "Floof",
        },
        {
          conjunction: "and",
          field: "cost",
          preposition: "is_not_between",
          value: "100",
          value2: "200",
        },
      ],
    },
    {
      id: "between two floofs or not",
      name: "Is Not A Floof OR not between",
      rules: [
        {
          conjunction: "",
          field: "name",
          preposition: "is_not",
          value: "Floof",
        },
        {
          conjunction: "or",
          field: "cost",
          preposition: "is_not_between",
          value: "100",
          value2: "200",
        },
      ],
    },
    {
      id: "contains",
      name: "Contains",
      rules: [
        {
          conjunction: "",
          field: "name",
          preposition: "contains",
          value: "Flo",
        },
      ],
    },
    {
      id: "not contains",
      name: "Not Contains",
      rules: [
        {
          conjunction: "",
          field: "name",
          preposition: "does_not_contain",
          value: "Flo",
        },
      ],
    },
  ];
  dispatch(getViews(views));
};

export const createViewEffect = () => async (dispatch, getState) => {};

export const updateViewEffect = () => async (dispatch, getState) => {};

export const deleteViewEffect = () => async (dispatch, getState) => {};
