import styled from "styled-components";
import Chip from '@material-ui/core/Chip';
import TextField from "@material-ui/core/TextField";
import ClearSharpIcon from '@material-ui/icons/ClearSharp';

export const HelpersContainer = styled.div`
  justify-content: left;
  overflow: auto;
  padding: 7px;
  text-align: left;
`;

export const StyledText = styled.p`
  grid-area: text;
  text-align: left;
  font-size: 14px;
`;

export const StyledChip = styled(Chip)`
  margin: 2px;
  &.MuiChip-root {
    color: white;
    background-color: #7288AB;
    border-radius: 5px;
  }
`;

export const StyledX = styled(ClearSharpIcon)`
  &.MuiChip-deleteIcon {
    color: #FF9726;
  }
`;

export const StyledTextField = styled(TextField)`
  width: 100px;
  margin: 0px 0px 0px 5px;
  &.MuiFormControl-root {
    margin: 0px 0px 0px 5px;
  }
`;
