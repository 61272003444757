import firebase from "firebase/compat/app";
import "firebase/compat/auth";

import { setIsAuthed } from "./authSlice.js";
import { GetUser } from "../../api/user.service";
import { userLoggedIn, userLoggedOut } from "../../profile/actions";
import store from "../../app/store";

const config = {
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
  storageBucket: process.env.REACT_APP_FIREBASE_BUCKET_NAME,
};

firebase.initializeApp(config);

const setUser = async () => {
  const user_token = await auth.currentUser.getIdToken(true);
  const user_id = await auth.currentUser.uid;

  const user_data = await GetUser(user_token, user_id, true);
  let org_id;
  let avatar_url;
  let org_name;
  let first_name;
  let last_name;
  let is_org_admin;
  if (user_data.data) {
    if (user_data.data.orgs) {
      org_id = user_data.data.orgs[0].id;
      org_name = user_data.data.orgs[0].name;
      is_org_admin = user_data.data.orgs[0].admin;
    }
    avatar_url = user_data.data.avatar_url;
    first_name = user_data.data.first_name;
    last_name = user_data.data.last_name;
  }

  store.dispatch(
    userLoggedIn(
      auth.currentUser.email,
      user_id,
      user_token,
      first_name,
      last_name,
      avatar_url,
      org_id,
      org_name,
      is_org_admin
    )
  );
  store.dispatch(setIsAuthed(true));
};

const unsetUser = async () => {
  store.dispatch(setIsAuthed(false));
  store.dispatch(userLoggedOut());
};

const auth = firebase.auth();
auth.onAuthStateChanged((user) => {
  if (user) {
    setUser();
  } else {
    unsetUser();
  }
});

window.auth = auth;
export { auth };
