import { createTheme } from "@material-ui/core/styles";

const black = "#000000";
const darkGrey = "#433d3c";
const red = "#e11919";
const whiteOffset = "#f9f7f5";
const blue = "#0088cc";
const grey = "#CCCCCC";
const white = "#ffffff";
const primaryGrey = "#404040";
const secondaryGrey = "#CFCECD";
const primaryBlue = "#445b79";
const secondaryBlue = "#7288AB";
const primaryOrange = "#FF9726";
const secondaryOrange = "#FFDCA7";
const dataGreen = "#27AB1B";

export const CustomMuiTheme = createTheme({
  colors: {
    navBar: primaryGrey,
    sectionDark: black,
    secondaryGrey: secondaryGrey,
    sectionLight: darkGrey,
    primary: red,
    accent: primaryOrange,
    selected: secondaryBlue,
    offSet: whiteOffset,
    link: blue,
    tableHeader: primaryBlue,
    input: grey,
    disabled: grey,
    default: white,
    hover: secondaryOrange,
    dataGreen: dataGreen,
  },
  fontSizes: {
    large: "3rem",
    medium: "2rem",
  },
});
