import styled from "styled-components";
import Button from "@material-ui/core/Button";
import Collapse from "@material-ui/icons/ExpandLess";
import Expand from "@material-ui/icons/ExpandMore";
import DeleteIcon from "@material-ui/icons/Delete";
import SaveIcon from "@material-ui/icons/Save";
import CancelIcon from "@material-ui/icons/Cancel";
import SortIcon from "@material-ui/icons/Sort";
import IconButton from "@material-ui/core/IconButton";
import CallSplitIcon from "@material-ui/icons/CallSplit";
import FileCopyIcon from "@material-ui/icons/FileCopy";
import Edit from "@material-ui/icons/Edit";
import AddIcon from '@material-ui/icons/Add';
import { withStyles } from "@material-ui/core/styles";

// This is the defaul button - it uses a standard MUI button with rounded corners
// the border radius at 5em affects the pill shape of the standard button
export const StyledButton = styled(Button)`
  display: grid;
  height: 30px;
  &.MuiButton-root {
    color: ${({ theme }) => theme.colors.tableHeader};
    background: ${({ theme }) => theme.colors.default};
    margin: 0px 4px;
    white-space: nowrap;
    border-radius: 5em;
    text-transform: none;
    border: 2px solid ${({ theme }) => theme.colors.accent};
    &:hover {
      color: ${({ theme }) => theme.colors.default};
      background-color: ${({ theme }) => theme.colors.accent};
    }
  }
  &.Mui-disabled {
    color: ${({ theme }) => theme.colors.secondaryGrey};
    background: ${({ theme }) => theme.colors.default};
    border: 2px solid ${({ theme }) => theme.colors.secondaryGrey};
  }
`;

// withStyles used to control the hover color state of MUI Icon Buttons
export const IconButtonContainer = withStyles(() => ({
  root: {
    border: "2px solid #FF9726",
    backgroundColor: "white",
    "&:hover": {
      backgroundColor: "#FF9726",
    },
  },
}))(IconButton);

export const GreenIconButtonContainer = styled(IconButton)`
  &.MuiIconButton-root {
    border: 2px solid #27AB1B;
    backgroundColor: white;
    &:hover {
      backgroundColor: #27AB1B;
    }    
  }
  &.Mui-disabled {
    border: 2px solid ${({ theme }) => theme.colors.secondaryGrey};
    background: ${({ theme }) => theme.colors.default};
  }
`;

export const RedIconButtonContainer = withStyles(() => ({
  root: {
    border: "2px solid #e11919",
    backgroundColor: "white",
    "&:hover": {
      backgroundColor: "#e11919",
    },
  },
}))(IconButton);

// StyledText used on Standard button children
export const StyledText = styled.p`
  padding: 0px 14px 0px 14px;
`;

// Icons are Expand, Collaspe, Delete, Cancel, Save, Sort
export const StyledExpandButton = styled(Expand)`
  color: ${({ theme, hovercolor }) =>
    hovercolor !== "" ? hovercolor : theme.colors.tableHeader};
`;

export const StyledCollapseButton = styled(Collapse)`
  color: ${({ theme, hovercolor }) =>
    hovercolor !== "" ? hovercolor : theme.colors.tableHeader};
`;

export const StyledEditButton = styled(Edit)`
  color: ${({ theme, hovercolor }) =>
    hovercolor !== "" ? hovercolor : theme.colors.tableHeader};
`;

export const StyledDeleteIcon = styled(DeleteIcon)`
  color: ${({ theme, hovercolor }) =>
    hovercolor !== "" ? hovercolor : theme.colors.tableHeader};
`;

export const StyledSplitIcon = styled(CallSplitIcon)`
  color: ${({ theme, hovercolor }) =>
    hovercolor !== "" ? hovercolor : theme.colors.tableHeader};
`;

export const StyledCopyIcon = styled(FileCopyIcon)`
  color: ${({ theme, hovercolor }) =>
    hovercolor !== "" ? hovercolor : theme.colors.tableHeader};
`;

export const StyledCancelIcon = styled(CancelIcon)`
  color: ${({ theme, hovercolor }) =>
    hovercolor !== "" ? hovercolor : theme.colors.tableHeader};
`;

export const StyledSaveIcon = styled(SaveIcon)`
  color: ${({ theme, hovercolor }) =>
    hovercolor !== "" ? hovercolor : theme.colors.tableHeader};
`;

export const StyledSortIcon = styled(SortIcon)`
  color: ${({ theme, hovercolor }) =>
    hovercolor !== "" ? hovercolor : theme.colors.tableHeader};
`;

export const StyledAddIcon = styled(AddIcon)`
  color: ${({ theme, hovercolor }) =>
    hovercolor !== "" ? hovercolor : theme.colors.tableHeader};
`;

// StyledDiv used to padd space between buttons
export const StyledDiv = styled.div`
  padding: 0px 7px 0px 0px;
`;
