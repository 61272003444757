import styled from "styled-components";

export const CardContainer = styled.div`
  display: grid;

  height: min-content;
  justify-content: center;
  align-items: start;
  background: white;
  border: 1px solid ${({ theme }) => theme.colors.tableHeader};

  grid-area: ${({ gridArea }) => gridArea};
  grid-template-columns: 1fr;
  grid-auto-rows: minmax(min-content, max-content);
  grid-template-areas:
    " header "
    " children ";

`;

export const Header = styled.div`
  display: grid;
  grid-area: header;
  padding: 5px 0px 5px 0px;
  align-self: start;
  width: full;
  background: ${(props) => props.theme.colors.tableHeader};

  grid-template-columns: 5fr 0.4fr;
  grid-template-rows: auto;
  grid-template-areas: " text actions ";
`;

export const StyledChildren = styled.div`
  grid-area: children;
`;

export const StyledText = styled.p`
  display: grid;
  grid-area: text;
  padding-left: 14px;
  text-align: left;
  align-self: center;
  font-size: 22px;
  color: ${({ color }) => (color ? color : "white")};
`;

export const Actions = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  margin: 0px 3px 0px 0px;
`;
