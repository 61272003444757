import styled from "styled-components";

export const ViewDisplayContainer = styled.div`
  display: grid;

  grid-template-columns: 1fr;
  grid-template-rows: auto;
  grid-template-areas:
    " viewRowContainers ";
`;

export const ViewRowContainers = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  grid-area: viewRowContainers
`;

export const ViewRowContainer = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
  margin: 0px 0px 0px 10px;
`;

export const ViewRuleText = styled.p`
  font-size: 20px;
  padding: 15px 5px 10px 5px;
`;
