import React from "react";
import { Link } from "react-router-dom";

import Layout from "../../../components/layout";
import Navbar from "../../../components/navbar";
import * as routes from "../../routes/paths";

const NotFound = () => {
  return (
    <Layout>
      <Navbar />
      <div>
        <p>404: You look lost</p>
        <Link to={routes.ITEMS}>Take me home</Link>
      </div>
    </Layout>
  );
};

export default NotFound;
