import {
  AddLocation,
  GetLocations,
  GetLocation,
  EditLocation,
  DeleteLocation,
} from "../../api/location.service";
import {
  fetchedLocation,
  fetchedLocationError,
  getLocations,
  getLocationsError,
  userLocationRequest,
  userLocationFetched,
  userLocationError,
  locationDistanceRequest,
  locationDistanceFetched,
  locationDistanceError,
  createLocation,
  createLocationError,
  deleteLocation,
  deleteLocationError,
  editLocation,
  editLocationError,
} from "../actions";
import { setAlert } from "../../components/alert/actions";

export const addLocationEffect = (newLocation) => async (
  dispatch,
  getState
) => {
  try {
    const { org_id, user_token } = getState().user;

    const formatedAddressString = formatLocationString(
         newLocation.address1,
         newLocation.city,
         newLocation.region,
         newLocation.postalCode,
         newLocation.country,
         newLocation.latitude,
         newLocation.longitude
    )
    if ((!newLocation.latitude || !newLocation.longitude) && formatedAddressString) {
          const geocoderService = new window.google.maps.Geocoder();
          const location = {
            name: newLocation.name,
            address1: newLocation.address1,
            address2: newLocation.address2,
            city: newLocation.city,
            region: newLocation.region,
            postalcode: newLocation.postalcode,
            country: newLocation.country,
            latitude: null,
            longitude: null,
          };
          geocoderService.geocode({"address": formatedAddressString}, async function(results, status) {
                  if (status === window.google.maps.GeocoderStatus.OK) {
                    if (status !== window.google.maps.GeocoderStatus.ZERO_RESULTS) {
                      location.latitude = results[0].geometry.location.lat()
                      location.longitude = results[0].geometry.location.lng()
                      const res = await AddLocation(org_id, user_token, location);
                      dispatch(createLocation());
                      dispatch(getLocationEffect(res.data.id));
                      dispatch(setAlert("success", "Location Added Successfully"));
                    } else {
                      location.latitude = null
                      location.longitude = null
                      const res = await AddLocation(org_id, user_token, location);
                      dispatch(createLocation());
                      dispatch(getLocationEffect(res.data.id));
                      dispatch(setAlert("warning", "No results found for Geocode"));
                    }
                  } else {
                    location.latitude = null
                    location.longitude = null
                    const res = await AddLocation(org_id, user_token, location);
                    dispatch(createLocation());
                    dispatch(getLocationEffect(res.data.id));
                    console.log("Geocode was not successful for the following reason: " + status);
                    dispatch(setAlert("warning", "Geocode failed"));
                  }
                });
    } else {
      const latitude = (newLocation.latitude || newLocation.latitude === 0) ? Number(newLocation.latitude) : null
      const longitude = (newLocation.longitude || newLocation.longitude === 0) ? Number(newLocation.longitude) : null
      const location = {
        name: newLocation.name,
        address1: newLocation.address1,
        address2: newLocation.address2,
        city: newLocation.city,
        region: newLocation.region,
        postalcode: newLocation.postalcode,
        country: newLocation.country,
        latitude: latitude,
        longitude: longitude,
      };
      const res = await AddLocation(org_id, user_token, location);
      dispatch(createLocation(location));
      dispatch(getLocationEffect(res.data.id));
      dispatch(setAlert("success", "Location Added Successfully"));
    }
  } catch (error) {
    dispatch(createLocationError(error.message));
    dispatch(fetchedLocationError(error.message));
    dispatch(setAlert("error", "Error Adding Location"));
  }
};

export const getLocationEffect = (locationId) => async (dispatch, getState) => {
  try {
    const userState = getState().user;
    const res = await GetLocation(
      userState.org_id,
      userState.user_token,
      locationId
    );
    dispatch(fetchedLocation(res.data.body));
  } catch (error) {
    dispatch(fetchedLocationError(error.message));
  }
};

export const getLocationsEffect = () => async (dispatch, getState) => {
  try {
    const userState = getState().user;
    const res = await GetLocations(
      userState.org_id,
      userState.user_token,
      userState.user_id
    );
    const locations = res.data.body === null ? [] : res.data.body;
    dispatch(getLocations(locations));
  } catch (error) {
    dispatch(getLocationsError(error.message));
  }
};

export const editLocationEffect = (editedLocationState, locationId) => async (
  dispatch,
  getState
) => {
  try {
    const { org_id, user_token } = getState().user;

    const formatedAddressString = formatLocationString(
         editedLocationState.address1,
         editedLocationState.city,
         editedLocationState.region,
         editedLocationState.postalCode,
         editedLocationState.country,
         editedLocationState.latitude,
         editedLocationState.longitude
    )
    if ((!editedLocationState.latitude || !editedLocationState.longitude) && formatedAddressString) {
          const geocoderService = new window.google.maps.Geocoder();
          const editedLocation = {
            name: editedLocationState.name,
            address1: editedLocationState.address1,
            address2: editedLocationState.address2,
            city: editedLocationState.city,
            region: editedLocationState.region,
            postalcode: editedLocationState.postalcode,
            country: editedLocationState.country,
            latitude: null,
            longitude: null,
          };
          geocoderService.geocode({"address": formatedAddressString}, async function(results, status) {
                  if (status === window.google.maps.GeocoderStatus.OK) {
                    if (status !== window.google.maps.GeocoderStatus.ZERO_RESULTS) {
                      editedLocation.latitude = results[0].geometry.location.lat()
                      editedLocation.longitude = results[0].geometry.location.lng()
                      await EditLocation(org_id, user_token, editedLocation, locationId);
                      dispatch(editLocation());
                      dispatch(getLocationsEffect());
                      dispatch(setAlert("success", "Location Edited Successfully"));
                    } else {
                      editedLocation.latitude = null
                      editedLocation.longitude = null
                      await EditLocation(org_id, user_token, editedLocation, locationId);
                      dispatch(editLocation());
                      dispatch(getLocationsEffect());
                      dispatch(setAlert("warning", "No results found for Geocode"));
                    }
                  } else {
                    editedLocation.latitude = null
                    editedLocation.longitude = null
                    await EditLocation(org_id, user_token, editedLocation, locationId);
                    dispatch(editLocation());
                    dispatch(getLocationsEffect());
                    console.log("Geocode was not successful for the following reason: " + status);
                    dispatch(setAlert("warning", "Geocode failed"));
                  }
                });
    } else {
      const latitude = (editedLocationState.latitude || editedLocationState.latitude === 0) ? Number(editedLocationState.latitude) : null
      const longitude = (editedLocationState.longitude || editedLocationState.longitude === 0) ? Number(editedLocationState.longitude) : null
      const editedLocation = {
        name: editedLocationState.name,
        address1: editedLocationState.address1,
        address2: editedLocationState.address2,
        city: editedLocationState.city,
        region: editedLocationState.region,
        postalcode: editedLocationState.postalcode,
        country: editedLocationState.country,
        latitude: latitude,
        longitude: longitude,
      };
      await EditLocation(org_id, user_token, editedLocation, locationId);
      dispatch(editLocation());
      dispatch(getLocationsEffect());
      dispatch(setAlert("success", "Location Edited Successfully"));
    }
  } catch (error) {
    dispatch(editLocationError(error.message));
    dispatch(getLocationsError(error.message));
    dispatch(setAlert("error", "Error Editing Location"));
  }
};

export const deleteLocationEffect = (locationId) => async (
  dispatch,
  getState
) => {
  try {
    await DeleteLocation(
      locationId,
      getState().user.org_id,
      getState().user.user_token
    );
    dispatch(deleteLocation());
    dispatch(getLocationsEffect());
    dispatch(setAlert("success", "Location Deleted Successfully"));
  } catch (error) {
    dispatch(deleteLocationError(error.message));
    dispatch(setAlert("error", "Error Deleting Location"));
  }
};

export const getUserLocationEffect = () => async (dispatch) => {
  dispatch(userLocationRequest())
  try {
    const geoSuccess = (position) => {
      dispatch(userLocationFetched(position.coords.latitude, position.coords.longitude));
    }
    const geoError = (err) => {
      console.warn(`ERROR(${err.code}): ${err.message}`);
      dispatch(userLocationError("Unable to retrieve your location"));
    }

    if(!navigator.geolocation) {
      console.log("Geolocation is not supported by your browser")
    } else {
      console.log("Locating user location…")
      navigator.geolocation.getCurrentPosition(geoSuccess, geoError, {timeout:5000});
    }
  } catch (error) {
    dispatch(userLocationError(error.message));
  }
};

export const getLocationDistanceEffect = (location) => async (dispatch, getState) => {
  const userLocationLatitude = getState().location.user_latitude
  const userLocationLongitude = getState().location.user_longitude

  dispatch(locationDistanceRequest())
  if (!userLocationLatitude || !userLocationLongitude) {
    console.log("user origin cannot be found")
    dispatch(locationDistanceError("user origin cannot be found"));
    return
  }
  // define the getDistance function in the context of the effect
  const getDistance = async (destination) => {
    const origin = new window.google.maps.LatLng(userLocationLatitude, userLocationLongitude)
    const service = new window.google.maps.DistanceMatrixService();
    const result = await getDistanceMatrix(
       service,
       {
        origins: [origin],
        destinations: [destination],
        travelMode: "DRIVING",
        avoidHighways: false,
        avoidTolls: false,
        unitSystem: window.google.maps.UnitSystem.IMPERIAL
      })
    return result
  }

  try {
      if (!addressExists(
             location.address1,
             location.city,
             location.region,
             location.country,
             location.latitude,
             location.longitude
      )) { return dispatch(locationDistanceFetched(" ")) }

      getDistance(
        formatLocationString(
             location.address1,
             location.city,
             location.region,
             location.postalCode,
             location.country,
             location.latitude,
             location.longitude
        )).then((result) => {
          const distanceElement = result.rows[0].elements[0]
          if (distanceElement.status === "OK") {
            dispatch(locationDistanceFetched(distanceElement.distance.text))
          } else {
            dispatch(locationDistanceFetched(" "))
          }
	}).catch((err) => {
          dispatch(locationDistanceError(err));
	})
  } catch (error) {
    dispatch(locationDistanceError(error.message));
  }
};

const getDistanceMatrix = (service, data) => new Promise((resolve, reject) => {
  service.getDistanceMatrix(data, (response, status) => {
    if (status === 'OK') {
      resolve(response)
    } else {
      reject(response)
    }
  })
});

export const formatLocationString = (address1, city, region, postalCode, country, latitude, longitude) => {
  // TODO: Remove 0,0 lat/lng below when null lat lng is implemented
  if (latitude && longitude && latitude !== 0 && longitude !== 0) {
    return new window.google.maps.LatLng(latitude, longitude)
  }
  let fullAddress = ""
  if (address1) {
    fullAddress = fullAddress.concat(`${address1},`)
  }
  if (city) {
    fullAddress = fullAddress.concat(` ${city},`)
  }
  if (region) {
    fullAddress = fullAddress.concat(` ${region},`)
  }
  if (postalCode) {
    fullAddress = fullAddress.concat(` ${postalCode},`)
  }
  if (country) {
    fullAddress = fullAddress.concat(` ${country}`)
  }
  return fullAddress
}

const addressExists = (address1, city, region, country, latitude, longitude) => {
  if (address1 === "" && city === "" && region === "" && country === "" && latitude === 0 && longitude === 0) {
    return false
  } else {
    return true
  }
}
