import styled from "styled-components";
import TextField from "@material-ui/core/TextField";
import InputLabel from "@material-ui/core/InputLabel";

export const StyledTextField = styled(TextField)`
  display: grid;
  grid-area: input;

  & .MuiOutlinedInput-root {
    font-size: 14px;
    background: white;
    &.Mui-focused fieldset {
      border-color: ${(props) => props.theme.colors.input};
    }
  }
`;

export const StyledInputLabel = styled(InputLabel)`
  display: grid;
  grid-area: label;

  margin-bottom: 5px;
`;

export const InputContainer = styled.div`
  display: grid;
  grid-area: ${({ gridArea }) => gridArea};
  justify-items: flex-start;
  width: 100%;

  grid-template-columns: 1fr;
  grid-template-rows: auto;
  grid-template-areas:
    " label "
    " input ";
`;
