import { configureStore, applyMiddleware } from "@reduxjs/toolkit";
import thunk from "redux-thunk";
import authReducer from "../shared/auth/authSlice";
import mapsReducer from "../shared/maps/reducers";
import loginReducer from "../login/reducers";
import locationsReducer from "../locations/reducers";
import assetsReducer from "../assets/reducers";
import userReducer from "../profile/reducers";
import alertReducer from "../components/alert/reducers";
import orgReducer from "../settings/reducers";
import signupReducer from "../sign-up/reducers";

const rootReducer = {
  reducer: {
    auth: authReducer,
    login: loginReducer,
    location: locationsReducer,
    assets: assetsReducer,
    user: userReducer,
    alert: alertReducer,
    maps: mapsReducer,
    org: orgReducer,
    signup: signupReducer,
  },
};

export default configureStore(rootReducer, applyMiddleware(thunk));
