export const FETCHED_LOCATION = "FETCHED_LOCATION";
export const FETCHED_LOCATION_ERROR = "FETCHED_LOCATION_ERROR";
export const CREATE_LOCATION = "CREATE_LOCATION";
export const CREATE_LOCATION_ERROR = "CREATE_LOCATION_ERROR";
export const GET_LOCATIONS = "GET_LOCATIONS";
export const GET_LOCATIONS_ERROR = "GET_LOCATIONS_ERROR";
export const GET_LOCATION = "GET_LOCATION";
export const GET_LOCATION_ERROR = "GET_LOCATION_ERROR";
export const DELETE_LOCATION = "DELETE_LOCATION";
export const DELETE_LOCATION_ERROR = "DELETE_LOCATION_ERROR";
export const EDIT_LOCATION = "EDIT_LOCATION";
export const EDIT_LOCATION_ERROR = "EDIT_LOCATION_ERROR";
export const USER_LOCATION_REQUEST = "USER_LOCATION_REQUEST";
export const USER_LOCATION_FETCHED = "USER_LOCATION_FETCHED";
export const USER_LOCATION_ERROR = "GET_USER_LOCATION_ERROR";
export const LOCATION_DISTANCE_REQUEST = "LOCATION_DISTANCE_REQUEST";
export const LOCATION_DISTANCE_FETCHED = "LOCATION_DISTANCE_FETCHED";
export const LOCATION_DISTANCE_ERROR = "LOCATION_DISTANCE_ERROR";
