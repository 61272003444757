import React from "react";
import PropTypes from "prop-types";

import * as Style from "./styles";

import ViewDrawerRow from "./components/view-drawer-row";

const ViewDrawerRows = ({
	rules,
	handleChange,
	handleRuleDelete,
	handleRuleAdd,
	fieldDefinitionsMap,
}) => {

    const viewDrawerRows = (rs) => {
      const viewDrawerRows = rs.map((rulePhrase, index) => {
        if (index === 0 && rs.length-1 === 0) {
          return(
                  <ViewDrawerRow
                    includeConjunction={false}
                    showAdd={true}
                    showCancel={false}

                    fieldDefinitionsMap={fieldDefinitionsMap}
                    rulePhrase={rulePhrase}
                    handleChange={handleChange}
                    handleRuleDelete={handleRuleDelete}
                    handleRuleAdd={handleRuleAdd}
                    index={index}

                    key={index}
                  />
        )}
        if (index === 0 && rs.length-1 !== 0) {
          return(
                  <ViewDrawerRow
                    includeConjunction={false}
                    showAdd={false}
                    showCancel={false}

                    fieldDefinitionsMap={fieldDefinitionsMap}
                    rulePhrase={rulePhrase}
                    handleChange={handleChange}
                    handleRuleDelete={handleRuleDelete}
                    handleRuleAdd={handleRuleAdd}
                    index={index}

                    key={index}
                  />
	)}
        if (rs.length-1 > index) {
          return(
                  <ViewDrawerRow
                    includeConjunction={true}
                    showAdd={false}
                    showCancel={false}

                    fieldDefinitionsMap={fieldDefinitionsMap}
                    rulePhrase={rulePhrase}
                    handleChange={handleChange}
                    handleRuleDelete={handleRuleDelete}
                    handleRuleAdd={handleRuleAdd}
                    index={index}

                    key={index}
                  />
	)}
        return(
                  <ViewDrawerRow
                    includeConjunction={true}
                    showAdd={true}
                    showCancel={true}

                    fieldDefinitionsMap={fieldDefinitionsMap}
                    rulePhrase={rulePhrase}
                    handleChange={handleChange}
                    handleRuleDelete={handleRuleDelete}
                    handleRuleAdd={handleRuleAdd}
                    index={index}

                    key={index}
                  />
      )})
      return viewDrawerRows
    }

    return (
        <> 
          <Style.ViewRowContainer>
            {viewDrawerRows(rules)}
          </Style.ViewRowContainer>
        </>
    ) 
};

ViewDrawerRows.propTypes = {
  rules: PropTypes.array,
  handleChange: PropTypes.func,
  handleRuleDelete: PropTypes.func,
  handleRuleAdd: PropTypes.func,
  fieldDefinitionsMap: PropTypes.object,
};

export default ViewDrawerRows;
