import styled from "styled-components";
import SaveIcon from "@material-ui/icons/Save";
import CancelIcon from "@material-ui/icons/Cancel";
import { withStyles } from "@material-ui/styles";
import { Typography, TableCell, TableRow } from "@material-ui/core";
import Input from "../../../components/input";

export const Page = styled.div`
  display: grid;
  grid-area: page;
  justify-content: center;

  grid-template-columns: 2fr 5fr;
  grid-template-rows: auto;
  grid-template-areas:
    " orgNameContainer ${({ actions }) => (actions ? "actions" : ".")} "
    "lower lower";
`;

export const Lower = styled.div`
  display: grid;
  grid-area: lower;

  grid-template-columns: 1fr 1fr;
  grid-template-rows: 40px auto;
  grid-template-areas:
    " tableTitle invite "
    " table table ";
`;

export const OrgNameContainer = styled.div`
  display: grid;
  grid-area: orgNameContainer;

  justify-items: start;
  align-items: center;
  width: 100%;
`;

export const EditButtonDiv = styled.div`
  align-self: center;
  padding: 0px 0px 0px 7px;
`;

export const StyledSave = styled(SaveIcon)`
  cursor: pointer;
  grid-area: save;
  z-index: 10;
  align-self: center;
  color: ${(props) => props.theme.colors.primary};
`;

export const StyledCancel = styled(CancelIcon)`
  cursor: pointer;
  grid-area: cancel;
  z-index: 10;
  align-self: center;
  color: ${(props) => props.theme.colors.primary};
`;

export const OrgNameDiv = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: flex-start;
`;

export const StyledHeading = withStyles(() => ({
  root: {
    color: "#433d3c",
  },
}))(Typography);

export const StyledHeaderCell = withStyles(() => ({
  root: {
    backgroundColor: "#445b79",
    color: "white",
    padding: "5px",
  },
}))(TableCell);

export const StyledTableCell = withStyles(() => ({
  root: {
    padding: "0px 0px 0px 5px",
  },
}))(TableCell);

export const StyledTableRow = withStyles(() => ({
  root: {
    "&:nth-of-type(odd)": {
      backgroundColor: "#F9F7F5",
    },
  },
}))(TableRow);

export const StyledActionsContainer = styled.div`
  display: grid;
  grid-area: actions;
  justify-self: flex-end;
  margin-top: -40px;

  grid-template-columns: auto;
  grid-template-rows: 2.25em 2.25em;
  grid-template-areas:
    " save "
    " cancel ";
`;

export const StyledDiv = styled.div`
  grid-area: ${({ gridArea }) => (gridArea ? gridArea : null)};
  justify-self: ${({ justifySelf }) => (justifySelf ? justifySelf : null)};
  align-self: ${({ alignSelf }) => (alignSelf ? alignSelf : null)};
`;

export const StyledInput = styled(Input)`
  & .MuiOutlinedInput-root {
    font-size: 22px;
    &.Mui-focused fieldset {
      border-color: ${({ theme }) => theme.colors.selected};
    }
    input {
      padding: 5px;
    }
  }
`;

export const InputStyle = styled.div`
  display: grid;
  align-self: center;
  grid-area: ${({ gridArea }) => gridArea};
  padding: 10px 5px 10px 0px;
`;
