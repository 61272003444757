import { auth } from "../../shared/auth/firebase";
import { userLoginError, userLogin } from "../actions";

export const loginEffect = (username, password) => async (dispatch) => {
  try {
      await auth.signInWithEmailAndPassword(username, password);
      dispatch(userLogin());
     } catch (error) {
      const data = JSON.parse(JSON.stringify(error));
      dispatch(userLoginError(data));
  }
};
