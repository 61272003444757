import styled from "styled-components";
import ClearSharpIcon from '@material-ui/icons/ClearSharp';
import DescriptionIcon from '@material-ui/icons/Description';
import Typography from "@material-ui/core/Typography";

export const Attachment = styled.div`
  display: grid;

  background: white;
  border-radius: 5px;

  height: 75px;
  width 95px;
  margin: 3px;
  overflow: hidden;
  cursor: ${({ pointer }) => pointer ? "pointer" : "default"};

  justify-items: left;
  align-items: left;
  grid-template-columns: 1fr 1fr 1fr;
  grid-template-rows:    1fr 1fr 20px;
  grid-template-areas: 
    " attachmentIcon attachmentIcon deleteIcon "
    " attachmentIcon attachmentIcon . "
    " attachmentName attachmentName attachmentName ";
`

export const AttachmentNameContainer = styled.div`
  display: grid;
  grid-area: attachmentName
`

export const StyledX = styled(ClearSharpIcon)`
  display: grid;
  grid-area: deleteIcon;
  cursor: pointer;
  &.MuiSvgIcon-root {
    height: 22px;
    width: 22px;
    color: #FF9726;
    justify-self: end;
  }
`;

export const StyledFileIcon = styled(DescriptionIcon)`
  display: grid;
  grid-area: attachmentIcon;
  &.MuiSvgIcon-root {
    height: 60px;
    width: 60px;
    color: #7288AB;
  }
`;

export const StyledText = styled(Typography)`
  padding: 0px 0px 0px 8px;
`;
