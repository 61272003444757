import styled from "styled-components";
import { withStyles } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import AppBar from "@material-ui/core/AppBar";
import Tab from "@material-ui/core/Tab";
import Tabs from "@material-ui/core/Tabs";

export const PaperContainer = withStyles((theme) => ({
  root: {
    height: "100%",
    width: "100%",
    elevation: 0,
    borderRadius: "0px",
    border: "1px solid #404040",
  },
}))(Paper);

export const StyledAppBar = withStyles((theme) => ({
  root: {
    backgroundColor: "white",
    color: "#404040",
  },
}))(AppBar);

export const StyledTab = withStyles((theme) => ({
  root: {
    minWidth: 100,
    width: 100,
    margin: "0px 10px 0px 10px",
    textTransform: "none",
    "&.Mui-selected": {
      fontWeight: "bold",
    },
  },
}))(Tab);

export const StyledTabs = withStyles((theme) => ({
  indicator: {
    height: "8px",
    backgroundColor: "#FF9726",
  },
}))(Tabs);

export const StyledChildrenDiv = styled.div`
  padding: 10px;
`;
