import React from "react";
import PropTypes from "prop-types";

import * as Style from "./styles";
import Input from "../../../../../../../../../components/input";
import Select from "../../../../../../../../../components/select";
import Button from "../../../../../../../../../components/button";

import { TextPrepOptions, NumberPrepOptions, ConjunctionOptionsArray } from "../../../../../../../../components/view-drawer/filter-logic.js";

const ViewDrawerRow = ({
        rulePhrase, 
	fieldDefinitionsMap,
        includeConjunction, 
        showAdd, 
        showCancel, 
        handleChange, 
	handleRuleDelete,
	handleRuleAdd,
        index,
}) => {
    const OptionsToArrayFunc = (OptionsObject) => {
      const OptionsArray = []
      Object.keys(OptionsObject).forEach(key => {
        OptionsArray.push(OptionsObject[key])
      });
      return OptionsArray
    }
    const TextPrepOptionsArray = OptionsToArrayFunc(TextPrepOptions);
    const NumberPrepOptionsArray = OptionsToArrayFunc(NumberPrepOptions);

    const fieldOptions = []
    for (const [id, fieldDef] of Object.entries(fieldDefinitionsMap)) {
      fieldOptions.push({"label": fieldDef.name, "value": id});
    }

    const prepositionIsDisabled = (field) => {
      if (field === "") {
        return true
      }
      return false
    }

    const valueIsDisabled = (preposition) => {
      if (preposition === "is_not_empty" || preposition === "is_empty" || preposition === "") {
        return true
      }
      return false
    }

    const value2IsDisabled = (preposition) => {
      if (preposition !== "is_between" && preposition !== "is_not_between") {
        return true
      }
      return false
    }

    const addIsDisabled = (preposition, value) => {
      if ((preposition !== "is_not_empty" && preposition !== "is_empty") && (value === undefined || value === "")) {
        return true
      }
      return false
    }

    const getFieldType = (fieldId) => {
      if (fieldDefinitionsMap[fieldId] !== undefined) {
        return fieldDefinitionsMap[fieldId].type
      }
    };

    const prepositionOptions = (fieldDefType) => {
      if (fieldDefType === "string" || fieldDefType === "string_select") {
        return TextPrepOptionsArray
      }
      if (fieldDefType === "number") {
        return NumberPrepOptionsArray
      }
      if (fieldDefType === undefined) {
        return [{"label": "", "value":""}]
      }
    }

    const buttons = (showAdd, addIsDisabled, showCancel) => {
      const cancelButton = (
          <Style.StyledDiv
            gridArea="cancel"
          >
            <Button
              variants={"cancel"}
              style={{
                padding: "1px",
                alignContent: "center",
              }}
              onClick={handleRuleDelete}
            />
          </Style.StyledDiv>
      )
      const addButton = (
          <Style.StyledDiv
            gridArea="add"
          >
            <Button
              variants={"add"}
              style={{
                padding: "1px",
                alignContent: "center",
              }}
              onClick={handleRuleAdd}
              disabled={addIsDisabled}
            />
          </Style.StyledDiv>
      )
      return (
        <>
          {showAdd ? addButton : null}
          {showCancel ? cancelButton : null}
        </>
      )
    }

    return (
        <>
          <Style.ViewDrawerRow>
            {includeConjunction ?
              <Style.ConjunctionField
                gridArea="conjunction"
              >
                <Select 
                    value={rulePhrase.conjunction}
                    options={ConjunctionOptionsArray}
                    id={"conjunction"}
                    name={"conjunction"}
                    type={"select"}
                    size='small'
                    variant='outlined'
                    handleChange={(event) => {handleChange(event, index)}}
                />
              </Style.ConjunctionField>
            : null}
            <Style.RuleContainer>
              <Style.Field>
                <Select 
                    value={rulePhrase.field}
                    options={fieldOptions}
                    id={"field"}
                    name={"field"}
                    type={"select"}
                    size='small'
                    variant='outlined'
                    handleChange={(event) => {handleChange(event, index)}}
                />
              </Style.Field>
              {prepositionIsDisabled(rulePhrase.field) ? null :
                <Style.Field>
                  <Select 
                      value={rulePhrase.preposition}
                      options={prepositionOptions(getFieldType(rulePhrase.field))}
                      id={"preposition"}
                      name={"preposition"}
                      type={"select"}
                      size='small'
                      variant='outlined'
                      handleChange={(event) => {handleChange(event, index)}}
                  />
                </Style.Field>
              }
              {valueIsDisabled(rulePhrase.preposition) ? null :
                <Style.Field>
                  <Input
                    value={rulePhrase.value}
                    id={"value"}
                    name={"value"}
                    type={"text"}
                    size='small'
                    variant='outlined'
                    handleChange={(event) => {handleChange(event, index)}}
                  />
                </Style.Field>
              }
              {value2IsDisabled(rulePhrase.preposition) ? null :
                <>
                  <Style.StyledText>
                    AND
                  </Style.StyledText>
                  <Style.Field>
                    <Input
                      value={rulePhrase.value2}
                      id={"value2"}
                      name={"value2"}
                      type={"text"}
                      size='small'
                      variant='outlined'
                      handleChange={(event) => {handleChange(event, index)}}
                    />
                  </Style.Field>
                </>
              }
              {buttons(showAdd, addIsDisabled(rulePhrase.preposition, rulePhrase.value), showCancel)}
            </Style.RuleContainer>
          </Style.ViewDrawerRow>
	</>
    ) 
};

ViewDrawerRow.propTypes = {
  fieldDefinitionsMap: PropTypes.object,
  includeConjunction: PropTypes.bool,
  showAdd: PropTypes.bool, 
  showCancel: PropTypes.bool, 
  rulePhrase: PropTypes.object, 
  handleChange: PropTypes.func, 
  handleRuleDelete: PropTypes.func,
  handleRuleAdd: PropTypes.func,
  index: PropTypes.number,
};

export default ViewDrawerRow;
