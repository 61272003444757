import React, { useState } from "react";
import PropTypes from "prop-types";

import * as Style from "./styles";

// useState customizes the on hover state without modifying underlying MUI effects
const Button = ({ variants, handleClick, children, ...rest }) => {
  const [color, setColor] = useState("");
  const addColor = () => {
    setColor("white");
  };
  const removeColor = () => {
    setColor("");
  };

  switch (variants) {
    case "edit":
      return (
        <Style.StyledDiv>
          <Style.IconButtonContainer
            onClick={handleClick}
            onMouseEnter={addColor}
            onMouseLeave={removeColor}
            {...rest}
          >
            <Style.StyledEditButton hovercolor={color} />
          </Style.IconButtonContainer>
        </Style.StyledDiv>
      );
    case "collapse":
      return (
        <Style.StyledDiv>
          <Style.IconButtonContainer
            onClick={handleClick}
            onMouseEnter={addColor}
            onMouseLeave={removeColor}
            {...rest}
          >
            <Style.StyledCollapseButton hovercolor={color} />
          </Style.IconButtonContainer>
        </Style.StyledDiv>
      );
    case "split":
      return (
        <Style.StyledDiv>
          <Style.IconButtonContainer
            onClick={handleClick}
            onMouseEnter={addColor}
            onMouseLeave={removeColor}
            {...rest}
          >
            <Style.StyledSplitIcon hovercolor={color} />
          </Style.IconButtonContainer>
        </Style.StyledDiv>
      );
    case "copy":
      return (
        <Style.StyledDiv>
          <Style.IconButtonContainer
            onClick={handleClick}
            onMouseEnter={addColor}
            onMouseLeave={removeColor}
            {...rest}
          >
            <Style.StyledCopyIcon hovercolor={color} />
          </Style.IconButtonContainer>
        </Style.StyledDiv>
      );

    case "expand":
      return (
        <Style.StyledDiv>
          <Style.IconButtonContainer
            onClick={handleClick}
            onMouseEnter={addColor}
            onMouseLeave={removeColor}
            {...rest}
          >
            <Style.StyledExpandButton hovercolor={color} />
          </Style.IconButtonContainer>
        </Style.StyledDiv>
      );
    case "save":
      return (
        <Style.StyledDiv>
          <Style.GreenIconButtonContainer
            onClick={handleClick}
            onMouseEnter={addColor}
            onMouseLeave={removeColor}
            {...rest}
          >
            <Style.StyledSaveIcon hovercolor={color} />
          </Style.GreenIconButtonContainer>
        </Style.StyledDiv>
      );
    case "delete":
      return (
        <Style.StyledDiv>
          <Style.IconButtonContainer
            onClick={handleClick}
            onMouseEnter={addColor}
            onMouseLeave={removeColor}
            {...rest}
          >
            <Style.StyledDeleteIcon hovercolor={color} />
          </Style.IconButtonContainer>
        </Style.StyledDiv>
      );
    case "cancel":
      return (
        <Style.StyledDiv>
          <Style.RedIconButtonContainer
            onClick={handleClick}
            onMouseEnter={addColor}
            onMouseLeave={removeColor}
            {...rest}
          >
            <Style.StyledCancelIcon hovercolor={color} />
          </Style.RedIconButtonContainer>
        </Style.StyledDiv>
      );
    case "sort":
      return (
        <Style.StyledDiv>
          <Style.IconButtonContainer
            onClick={handleClick}
            onMouseEnter={addColor}
            onMouseLeave={removeColor}
            {...rest}
          >
            <Style.StyledSortIcon hovercolor={color} />
          </Style.IconButtonContainer>
        </Style.StyledDiv>
      );
    case "add":
      return (
        <Style.StyledDiv>
          <Style.GreenIconButtonContainer
            onClick={handleClick}
            onMouseEnter={addColor}
            onMouseLeave={removeColor}
            {...rest}
          >
            <Style.StyledAddIcon hovercolor={color} />
          </Style.GreenIconButtonContainer>
        </Style.StyledDiv>
      );
    default:
      return (
        <Style.StyledButton onClick={handleClick} {...rest}>
          <Style.StyledText>{children}</Style.StyledText>
        </Style.StyledButton>
      );
  }
};

Button.propTypes = {
  children: PropTypes.node,
  handleClick: PropTypes.func,
};

export default Button;
