import styled from "styled-components";

export const ViewDrawerRow = styled.div`
  display: grid;
  width: 100%;
  height: 100%;

  grid-template-columns: 75px 10px 1fr;
  grid-template-rows: auto;
  grid-template-areas:
    "conjunction . ruleContainer ";
`;

export const RuleContainer = styled.div`
  display: flex;
  grid-area: ruleContainer;
`;

export const ConjunctionField = styled.div`
  align-content: center;
  grid-area: ${({ gridArea }) => gridArea};
  padding: 15px 5px 10px 5px;
`;

export const Field = styled.div`
  align-content: center;
  padding: 15px 5px 10px 5px;
  min-width: 180px;
`;

export const StyledText = styled.p`
  font-size: 14px;
  margin: 30px 10px 0px 10px;
`;

export const StyledDiv = styled.div`
  display: grid;
  grid-area: ${({ gridArea }) => gridArea};
  align-content: center;
  padding: 15px 5px 10px 5px;
`;
