import styled from "styled-components";

export const FormContainer = styled.form`
  width: 100%;
  grid-area: form;
  background: #f5f7f9;
  margin: 0px 0px 30px 0px;
`;

export const AssetContainer = styled.div`
  display: grid;
  height: 100%;

  background: #f5f7f9;

  grid-template-columns: 1fr 1fr 1fr 1fr 80px;
  grid-template-rows: 1fr 100px 25px 100px 100px;
  grid-template-areas:
    " field_containers       field_containers       field_containers       . icons "
    " depreciation_container depreciation_container depreciation_container . ."
    " photo                  helpersText            attachment_container   . ."
    " photo                  helpers                attachment_container   . ."
    " photo                  helpers                attachment_container   . .";
`;

export const IconsContainer = styled.div`
  display: grid;
  grid-area: icons;
  grid-template-columns: 1fr 5px 1fr;
  grid-template-rows: 30px;
  grid-template-areas: " cancel . save ";

  padding: 20px 10px 0px 10px;
  justify-content: center;
`;

export const StyledDiv = styled.div`
  display: grid;
  grid-area: ${({ gridArea }) => gridArea};
  padding: ${({ padding }) => padding};
`;

export const FieldContainer = styled.div`
  display: grid;

  grid-area: field_containers;
  align-content: center;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  grid-template-rows: auto;

  padding: 15px 5px 15px 5px;
  background: #f5f7f9;
`;

export const WarrantyContainer = styled.div`
  display: grid;

  grid-area: depreciation_container;
  align-content: center;

  grid-template-columns: 1fr 1fr 1fr 1fr;
  grid-template-rows: auto;

  padding: 15px 5px 10px 5px;
  margin: 0px 10px 0px 10px;
  background: #c3c3c3;
  border-radius: 6px;
`;

export const PhotoContainer = styled.div`
  display: grid;
  grid-area: photo;

  border-radius: 6px;
  background: #c3c3c3;

  margin: 10px;
`;

export const HelpersContainer = styled.div`
  display: grid;
  grid-area: helpers;

  border: 1px solid #cfcecd;
  border-radius: 6px;
  background: white;

  margin: 10px;
  height: 180px;

`;

export const StyledTextContainer = styled.p`
  display: grid;
  grid-area: helpersText;
  grid-template-columns: 65px 1fr;
  grid-template-rows: auto;
  grid-template-areas:
    " text1 text2 ";

  margin: 10px;
`;

export const StyledText1 = styled.p`
  text-align: left;
  font-size: 17px;
  color: black;
  display: grid;
  grid-area: text1;
`;

export const StyledText2 = styled.p`
  text-align: left;
  font-size: 11px;
  color: black;
  display: grid;
  grid-area: text2;
`;

export const AttachmentContainer = styled.div`
  display: grid;
  grid-area: attachment_container;

  border-radius: 6px;
  background: #c3c3c3;

  margin: 10px;
`;

export const BottomButtonSection = styled.div`
  display: grid;
  grid-area: button;
  align-items: center;
  border-top: solid;
  border-width: thin;
  border-color: #a6a6a6;
  background: #d9d9d9;

  padding: 10px;

  grid-template-columns: 1fr 1fr 1fr 1fr;
  grid-template-rows: auto;
  grid-template-areas: " . . . buttons ";
`;

export const BottomButtonContainer = styled.div`
  display: grid;
  grid-area: buttons;
  align-items: center;

  grid-template-columns: 1fr 1fr 5px 1fr;
  grid-template-rows: auto;
  grid-template-areas: " .  cancel . save ";
`;
