import {
  SETTINGS_FETCH_ORG,
  SETTINGS_FETCH_ORG_ERROR,
  SETTINGS_UPDATE_ORG,
  SETTINGS_UPDATE_ORG_ERROR,
  SETTINGS_GET_ORG_MEMBERS,
  SETTINGS_GET_ORG_MEMBERS_ERROR,
} from "../actions/ActionTypes";

const initialState = {
  org: [],
  orgMembers: [],
  loading: true,
  error: null,
};

const orgReducer = (state = initialState, action) => {
  const { type, payload, error } = action;
  switch (type) {
    case SETTINGS_FETCH_ORG:
      return {
        ...state,
        org: payload,
        loading: false,
        error: null,
      };
    case SETTINGS_UPDATE_ORG:
      return {
        ...state,
        org: payload,
        loading: false,
        error: null,
      };
    case SETTINGS_GET_ORG_MEMBERS:
      return {
        ...state,
        orgMembers: payload,
        loading: false,
        error: null,
      };
    case SETTINGS_FETCH_ORG_ERROR:
    case SETTINGS_UPDATE_ORG_ERROR:
    case SETTINGS_GET_ORG_MEMBERS_ERROR:
      return {
        ...state,
        loading: false,
        error: error,
      };
    default:
      return state;
  }
};

export default orgReducer;
