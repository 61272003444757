import React from "react";
import * as Style from "./styles";

const HistoryTabContent = () => {

  return (
    <Style.TabContainer>
      <Style.Header>
        <Style.StyledText>
          History
        </Style.StyledText>
      </Style.Header>
    </Style.TabContainer>
  )
};

HistoryTabContent.propTypes = {};

export default HistoryTabContent;
