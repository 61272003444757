import styled from "styled-components";
import TableHead from "@material-ui/core/TableHead";
import Table from "@material-ui/core/Table";
import TableCell from "@material-ui/core/TableCell";

export const StyledTableHead = styled(TableHead)``;

export const StyledTableCell = styled(TableCell)`
  box-shadow: 1px 0 0 ${({ theme }) => theme.colors.tableHeader};
`;

export const StyledTable = styled(Table)`
  display: table;

  /* Table styling */
  border-collapse: collapse;
  /* border-collapse: separate; */
  /* border-spacing: 5px 10px; */

  caption-side: bottom;
  empty-cell: show;
  /* empty-cell: show | hide;  */
  /* empty-cell is a property of table or the cells themselves */

  /* vertical-align: baseline | sub | super | text-top | 
                text-bottom | middle | top | bottom | 
                <percentage> | <length> */
  th,
  td {
    background-color: white;
    color: black;
  }
  th {
    font-weight: bold;
    background: ${(props) => props.theme.colors.tableHeader};
    color: #f5f7f9;
  }

  tbody tr {
    :nth-of-type(odd) {
      background-color: #efefef;
    }
  }
  thead > tr {
    background-color: #c2c2c2;
  }
  table {
    width: 100%;
  }
  caption {
    font-size: 0.9em;
    padding: 5px;
    font-weight: bold;
  }
  box-shadow: 0px 6px 5px grey;
`;
