import React from "react";
import PropTypes from "prop-types";
import * as Style from "./styles";

const Select = ({
  id,
  labelName,
  value,
  options,
  gridArea,
  handleChange,
  ...rest
}) => {
  return (
    <Style.InputContainer gridArea={gridArea}>
      <Style.StyledInputLabel style={{ fontSize: 14, color: "black" }}>
        {labelName}
      </Style.StyledInputLabel>
      <Style.StyledSelect
        id={id}
        value={value}
        onChange={handleChange}
        {...rest}
      >
        {options.map((option, index) => (
          <Style.StyledMenuItem value={option.value} key={index}>{option.label}</Style.StyledMenuItem>
        ))}
      </Style.StyledSelect>
    </Style.InputContainer>
  );
};

Select.propTypes = {
  id: PropTypes.string,
  labelName: PropTypes.string,
  value: PropTypes.string,
  options: PropTypes.array,
  gridArea: PropTypes.string,
  handleChange: PropTypes.func.isRequired,
};

export default Select;
