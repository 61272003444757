import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import Logo from "../../shared/assets/light-shingo-logo.png";
import Input from "../../components/input";
import { signupEffect } from '../effects/';
import * as routes from "../../shared/routes/paths";
import * as Style from "./styles";
import PropTypes from "prop-types";
import { connect } from "react-redux";

const Signup = ({error}) => {
  const dispatch = useDispatch();

  const [state, setState] = useState({
    email: "",
    password: "",
    password_confirmation: "",
    organization: "",
    first_name: "",
    last_name: "",
    checkbox: false,
    password_match: "",
  });

  const [errorState, setErrorState] = useState({
    email: false,
    password: false,
    password_confirmation: false,
    organization: false,
    checkbox: false,
    password_match: false,
    message: "",
  });
  
  const passwordComplexityList = ["10 or more characters",
  "One or more uppercase letters", "One or more lowercase letters", 
  "One or more numbers", "One or more special characters"];

  useEffect(() => {
    setErrorState({...errorState, 
      message: error.error
  })
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [error]);

  function inputIsInvalid(val) {
    if (val === "email") {
      return state.email === "";
    }
    if (val === "password") {
      return state.password === "";
    }
    if (val === "password_confirmation") {
      return state.password_confirmation === "";
    }
    if (val === "organization") {
      return state.organization === "";
    }
    if (val === "checkbox") {
      return !state.checkbox;
    }
    if (val === "password_match") {
      return state.password !== state.password_confirmation;
    }
    //password must have 10 or more characters, contain one or more
    //uppercase letters, lowercase letters, numbers and special characters
    if (val === "password_complexity") {
      var regPassword = /(?=^.{10,}$)(?=.*\d)(?=.*[!@#$%^&*]+)(?![.\n])(?=.*[A-Z])(?=.*[a-z]).*$/;
      const password = state.password;
      return !regPassword.test(password);
    }
    //regex to check that there is an @ symbol and a . symbol
    if (val === "emailComplexity") {
      var regEmail = /^\S+@\S+\.\S+$/;
      const email = state.email;
      return !regEmail.test(email);
    }
    if (val === "emailTaken") {
      return errorState.message === "User with the provided email already exists"
    }
    if (val === "orgTaken") {
      return errorState.message === "An organization with that name already exists"
    }
  }

  const handleSubmit = async (event) => {
    event.preventDefault();
    
    const email = inputIsInvalid("email")
    const password = inputIsInvalid("password")
    const password_confirmation = inputIsInvalid("password_confirmation")
    const organization = inputIsInvalid("organization")
    const checkbox = inputIsInvalid("checkbox")
    const password_match = inputIsInvalid("password_match")
    const password_complexity = inputIsInvalid("password_complexity")
    const emailComplexity = inputIsInvalid("emailComplexity");

    setErrorState({
      email: email,
      password: password,
      password_confirmation: password_confirmation,
      organization: organization,
      checkbox: checkbox,
      password_match: password_match,
      password_complexity: password_complexity,
      emailComplexity: emailComplexity,
    });

    if (email || password || password_confirmation ||
      organization || checkbox || password_complexity || password_match 
      || emailComplexity
    ) {
      return;
    } else {
      dispatch(signupEffect(state));
    }
  };

  const handleChange = (event) => {
    if (event.target.name === "checkbox") {
      setState({ ...state, [event.target.name]: event.target.checked });
    }
    else {
      setState({ ...state, [event.target.name]: event.target.value });
    }
    return;
  };

  return (
    <Style.Background>
      <div>
        <p>
          <img src={Logo} alt="" />
        </p>
        <Style.StyledForm noValidate autoComplete="off" onSubmit={handleSubmit}>
          <Input
            id="email"
            name="email"
            type="email"
            size="small"
            labelName="Email*"
            value={state.email}
            gridArea={"email"}
            variant="outlined"
            handleChange={handleChange}
            style={{ marginBottom: "5px" }}
            error ={errorState.email || inputIsInvalid("emailTaken")}
          />
        </Style.StyledForm>
        <Style.StyleErrorMessage style={{ marginBottom: "10px" }}>
          {errorState.email && "Email required"}
          {inputIsInvalid("emailTaken") && errorState.message}
        </Style.StyleErrorMessage>
        <Style.StyledForm>
          <Input
            id="first_name"
            name="first_name"
            type="text"
            size="small"
            labelName="First Name"
            value={state.first_name}
            gridArea={"firstName"}
            variant="outlined"
            handleChange={handleChange}
            style={{ marginBottom: "15px" }}
          />

          <Input
            id="last_name"
            name="last_name"
            type="text"
            size="small"
            labelName="Last Name"
            value={state.last_name}
            gridArea={"lastName"}
            variant="outlined"
            handleChange={handleChange}
            style={{ marginBottom: "15px" }}
          />
          <Input
            id="password"
            name="password"
            type="password"
            size="small"
            labelName="Password*"
            value={state.password}
            gridArea={"password"}
            variant="outlined"
            handleChange={handleChange}
            style={{ marginBottom: "5px" }}
            error ={errorState.password || errorState.password_complexity }
          />
        </Style.StyledForm>
        
        <Style.StyleErrorMessage style={{ marginBottom: "10px" }}>
          {errorState.password && "Password required"}
          {!errorState.password && errorState.password_complexity && "Password must contain: " }
          {!errorState.password && errorState.password_complexity && 
          passwordComplexityList.map((index,element) =>
            <li key={index}>
                {passwordComplexityList[element]}
            </li>
        ) 
        }
        </Style.StyleErrorMessage>
        <Style.StyledForm>
          <Input
            id="password-confirmation"
            name="password_confirmation"
            type="password"
            size="small"
            labelName="Password Confirmation*"
            value={state.password_confirmation}
            gridArea={"passwordConf"}
            variant="outlined"
            handleChange={handleChange}
            style={{ marginBottom: "5px" }}
            error ={errorState.password_confirmation}
          />
        </Style.StyledForm>
        <Style.StyleErrorMessage style={{ marginBottom: "10px" }}>
          {errorState.password_match &&
            "Password must match password confirmation"}
          {!errorState.password_match &&
            errorState.password_confirmation &&
            "Password must match password confirmation"}
        </Style.StyleErrorMessage>
        <Style.StyledForm>
          <Input
            id="organization"
            name="organization"
            type="text"
            size="small"
            labelName="Organization*"
            value={state.organization}
            gridArea={"organization"}
            variant="outlined"
            handleChange={handleChange}
            style={{ marginBottom: "5px" }}
            error ={errorState.organization || inputIsInvalid("orgTaken")}          
          />
        </Style.StyledForm>

        <Style.StyleErrorMessage style={{ marginBottom: "10px" }}>
          {errorState.password_confirmation && "Organization required"}
          { inputIsInvalid("orgTaken") && errorState.message}
        </Style.StyleErrorMessage>
      </div>

      <Style.DetailsContainer>
        <Style.AgreementContainer>
          <Style.CheckBox
            type="checkbox"
            id="checkbox"
            name="checkbox"
            onChange={handleChange}
            error ={errorState.checkbox}
            
          />

          <Style.StyledText>
            I have read and agreed to the
            <Style.StyledLink
              to={""} //TODO: will be terms and conditions page route
            >
              {" "}
              terms and conditions
            </Style.StyledLink>
          </Style.StyledText>
        </Style.AgreementContainer>
      </Style.DetailsContainer>
      <Style.StyleErrorMessage style={{ marginBottom: "10px" }}>
        {errorState.checkbox &&
          "Verify you have read the terms and conditions"}
      </Style.StyleErrorMessage>

      <Style.StyledButton
        style={{ gridArea: "forgotPassword" }}
        renderas="button"
        variant="contained"
        color="primary"
        href="/"
        handleClick={handleSubmit}
      >
        Create Account
      </Style.StyledButton>
      <Style.LinksContainer>
        <Style.StyledLink
          style={{ gridArea: "signIn", paddingBottom: "5px" }}
          to={routes.LOGIN}
        >
          Sign In
        </Style.StyledLink>
        <Style.StyledLink style={{ gridArea: "forgotPassword" }} to={""}>
          Forgot your password?
        </Style.StyledLink>
      </Style.LinksContainer>
    </Style.Background>
  );
}
const mapStateToProps = (state) => ({
  error: state.signup.error,
});

Signup.propTypes = {
  signup: PropTypes.shape({ 
    error: PropTypes.shape({ 
      error: PropTypes.string,
    }),
}),
};

export default connect(mapStateToProps)(Signup);

