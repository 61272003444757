import React from "react";
import { useSelector } from "react-redux";
import { Route, Redirect } from "react-router-dom";

import { selectIsAuthed } from "../auth/authSlice.js";
import * as routes from "./paths";

// A wrapper for <Route> that redirects to the login
// screen if you're not yet authenticated.
function ProtectedRoute({ component: Component, children, ...rest }) {
  const isAuthed = useSelector(selectIsAuthed);

  return (
    <Route
      {...rest}
      render={({ location }) => {
        if (isAuthed) {
          return <Component {...rest} />;
        }
        if (isAuthed !== null) {
          return (
            <Redirect
              to={{
                pathname: routes.LOGIN,
                state: { from: location },
              }}
            />
          );
        }
      }}
    />
  );
}

export default ProtectedRoute;
