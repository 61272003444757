import React, { useState } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";

import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import IconButton from "@material-ui/core/IconButton";
import KeyboardArrowUpIcon from "@material-ui/icons/KeyboardArrowUp";
import TableCell from "@material-ui/core/TableCell";
import RowDropdown from "../../assets/components/asset-row-dropdown";

import * as Style from "./styles";

const Row = ({
  row,
  fieldDefinitions,
  locations,
  handleClickOpenDeleteAsset,
  handleRowUpdate,
  handleAttachmentDownload
}) => {
  const [open, setOpen] = useState(false);
  const handleIconButtonClick = () => {
    setOpen(!open);
  };

  return (
    <>
      <Style.StyledTableRow>
        <TableCell>
          <IconButton
            aria-label="expand row"
            size="small"
            onClick={handleIconButtonClick}
          >
            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </TableCell>
        {fieldDefinitions.map((fieldDefinition, index) => (
          <TableCell component="td" key={index}>
            {row["fields"][fieldDefinition.id]}
          </TableCell>
        ))}
      </Style.StyledTableRow>
      <RowDropdown
        row={row}
        fieldDefinitions={fieldDefinitions}
        locations={locations}
        handleClickOpenDeleteAsset={handleClickOpenDeleteAsset}
        setOpen={setOpen}
        open={open}
        handleRowUpdate={handleRowUpdate}
        handleAttachmentDownload={handleAttachmentDownload}
      />
    </>
  );
};

const mapStateToProps = (state, ownProps) => ({
  assets: state.assets,
  props: ownProps,
});

Row.propTypes = {
  row: PropTypes.object,
  fieldDefinitions: PropTypes.array,
  locations: PropTypes.array,
  handleClickOpenDeleteAsset: PropTypes.func,
  handleRowUpdate: PropTypes.func,
  handleAttachmentDownload: PropTypes.func,
};

export default connect(mapStateToProps)(Row);
