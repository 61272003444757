import styled from "styled-components";

export const ViewDrawerForm = styled.div`
  display: grid;

  grid-template-columns: 1fr;
  grid-template-rows: 1fr;
  grid-template-areas:
    " viewNameContainer"
    " viewRowContainer "
    " button ";
`;

export const ViewNameContainer = styled.div`
  display: grid;
  width: 100%;
  height: 100%;

  padding: 7px 5px 5px 5px;

  grid-template-columns: 1fr 1fr 1fr 40px 40px 1fr;
  grid-template-rows: auto;
  grid-template-areas:
    "viewName . . . . . ";
`;

export const Field = styled.div`
  align-content: center;
  grid-area: ${({ gridArea }) => gridArea};
  padding: 15px 5px 10px 5px;
`;

export const ButtonSection = styled.div`
  display: grid;
  grid-area: button;
  align-items: center;
  border-top: solid;
  border-width: thin;
  border-color: #a6a6a6;
  background: #d9d9d9;
  padding: 10px;

  grid-template-columns: 1fr 1fr 1fr 1fr;
  grid-template-rows: auto;
  grid-template-areas: " . . . buttons ";
`;

export const ButtonContainer = styled.div`
  display: grid;
  grid-area: buttons;
  align-items: center;

  grid-template-columns: 1fr 1fr 5px 1fr;
  grid-template-rows: auto;
  grid-template-areas: " .  cancel . save ";
`;
