import styled from "styled-components";

export const PageContainer = styled.div`
  display: grid;
  height: 100vh;

  grid-template-columns: 1fr;
  grid-template-rows: auto 0.3fr 5fr;
  grid-template-areas:
    " upper "
    " divider "
    " lower ";
`;

export const Upper = styled.div`
  display: grid;
  grid-area: upper;
  justify-content: center;

  grid-template-columns: 1fr 1fr;
  grid-template-rows: 3em auto;
  grid-template-areas:
    " . . "
    " viewTable viewTable ";
`;

export const Divider = styled.div`
  display: grid;
  grid-area: divider;
  width: 100%;
`;

export const Lower = styled.div`
  grid-area: lower;
`;

export const ItemsTableContainer = styled.div`
  position: relative;
`;

export const Map = styled.div`
  display: grid;
  grid-area: map;

  justify-content: center;
  align-content: center;
  background: white;
  margin-right: 0.7em;
  border: 1px solid ${({ theme }) => theme.colors.tableHeader};
`;
