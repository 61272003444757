import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import AccountBoxIcon from "@material-ui/icons/AccountBox";
import PersonIcon from "@material-ui/icons/Person";
import PowerSettingsNewIcon from "@material-ui/icons/PowerSettingsNew";
import SettingsIcon from "@material-ui/icons/Settings";
import TableChartOutlinedIcon from "@material-ui/icons/TableChartOutlined";

import { auth } from "../../../shared/auth/firebase";
import * as routes from "../../../shared/routes/paths";
import { CustomMuiTheme } from "../../../shared/styles/theme.js";
import * as Style from "./styles";
import { ExportAssets } from "../../../api/asset.service.js";

const Profile = ({
  username,
  user_token,
  avatar_url,
  org_id,
  org_name,
  is_org_admin,
}) => {
  const [admin, setAdmin] = useState(false);

  useEffect(() => {
    setAdmin(is_org_admin);
  }, [is_org_admin]);

  const handleLogOutClick = () => {
    auth.signOut();
  };

  const handleExportClick = () => {
    ExportAssets(user_token, org_id);
  };

  return (
    <Style.DropDown>
      <Style.User>{username ? username : "User Name"}</Style.User>
      <Style.Org>{org_name ? org_name : "Organization Name"}</Style.Org>
      {avatar_url ? (
        <Style.Avatar
          src={avatar_url}
          alt="user avatar"
          width="38px"
          height="38px"
        />
      ) : (
        <AccountBoxIcon
          style={{
            gridArea: "avatar",
            fill: CustomMuiTheme.colors.offSet,
            fontSize: "38px",
          }}
        />
      )}
      <Style.DropDownContent>
        <Style.Option>
          <Style.ClickableOption onClick={handleExportClick}>
            <TableChartOutlinedIcon style={{ fontSize: Style.fontSize }} />
            Data Export (csv)
          </Style.ClickableOption>
        </Style.Option>
        {admin && (
          <Style.Option>
            <Link to={routes.SETTINGS}>
              <SettingsIcon style={{ fontSize: Style.fontSize }} />
              Settings
            </Link>
          </Style.Option>
        )}
        <Style.Option>
          <Link to={routes.PROFILE}>
            <PersonIcon style={{ fontSize: Style.fontSize }} />
            Profile
          </Link>
        </Style.Option>
        <Style.Option>
          <Style.ClickableOption onClick={handleLogOutClick}>
            <PowerSettingsNewIcon style={{ fontSize: Style.fontSize }} />
            Log Out
          </Style.ClickableOption>
        </Style.Option>
      </Style.DropDownContent>
    </Style.DropDown>
  );
};

const mapStateToProps = (state) => ({
  user_id: state.user.user_id,
  username: state.user.username,
  user_token: state.user.user_token,
  avatar_url: state.user.avatar_url,
  org_id: state.user.org_id,
  org_name: state.user.org_name,
  orgMembers: state.org.orgMembers,
  is_org_admin: state.user.is_org_admin,
});

Profile.propTypes = {
  username: PropTypes.string,
  user_token: PropTypes.string,
  avatar_url: PropTypes.string,
  org_id: PropTypes.string,
  org_name: PropTypes.string,
  is_org_admin: PropTypes.bool,
};

export default connect(mapStateToProps)(Profile);
