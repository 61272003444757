import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import Checkbox from "@material-ui/core/Checkbox";

import {
  getOrgMembersEffect,
  updateOrgEffect,
  updateOrgUserEffect,
} from "../../effects/";
import Button from "../../../components/button";

import * as Style from "./styles";

const columns = [
  { id: "admin", label: "Admin" },
  { id: "first_name", label: "First Name" },
  { id: "last_name", label: "Last Name" },
  { id: "email", label: "Email" },
];

const OrganizationTab = ({
  org,
  dispatch,
  orgMembers,
  index,
  value,
  user_id,
}) => {
  const [name, setName] = useState("");
  const [clickedCheck, setClickChecked] = useState(null);
  const [editOrgName, setEditOrgName] = useState(false);
  const { name: orgName } = org;

  useEffect(() => {
    dispatch(getOrgMembersEffect());
    setClickChecked(false);
  }, [dispatch, clickedCheck]);

  const handleChange = (e) => setName(e.target.value);
  const handleEditSave = (event, newOrg) => {
    event.preventDefault();

    dispatch(updateOrgEffect(newOrg));
    setName(newOrg.name);
    setEditOrgName(false);
  };
  const handleOpenEdit = (orgName) => {
    setEditOrgName(true);
    setName(orgName);
  };

  const handleAdminChange = (member) => {
    setClickChecked(true);
    dispatch(updateOrgUserEffect(!member.admin, member.id, user_id));
  };

  return (
    <div hidden={value !== index} id={index}>
      {value === index && (
        <Style.Page actions={editOrgName}>
          <Style.OrgNameContainer>
            <Style.OrgNameDiv>
              {orgName && (
                <>
                  <Style.InputStyle>
                    <Style.StyledInput
                      id="name"
                      name="name"
                      type="text"
                      size="medium"
                      variant="outlined"
                      disabled={editOrgName ? false : true}
                      defaultValue={orgName}
                      handleChange={handleChange}
                    />
                  </Style.InputStyle>
                  {!editOrgName && (
                    <Style.EditButtonDiv>
                      <Button
                        variants={"edit"}
                        style={{ padding: "2px" }}
                        handleClick={() => handleOpenEdit(orgName)}
                      />
                    </Style.EditButtonDiv>
                  )}
                </>
              )}
            </Style.OrgNameDiv>
          </Style.OrgNameContainer>
          {editOrgName && (
            <Style.StyledActionsContainer>
              <Button
                variants={"save"}
                style={{ padding: "2px" }}
                handleClick={(e) => handleEditSave(e, { name: name })}
              />
              <Button
                variants={"cancel"}
                style={{ padding: "2px" }}
                handleClick={() => setEditOrgName(false)}
              />
            </Style.StyledActionsContainer>
          )}
          <Style.Lower>
            <Style.StyledDiv
              gridArea={"tableTitle"}
              justifySelf={"start"}
              alignSelf={"center"}
            >
              <Style.StyledHeading variant="subtitle1">
                Current Members
              </Style.StyledHeading>
            </Style.StyledDiv>
            <Style.StyledDiv
              gridArea={"invite"}
              justifySelf={"end"}
              alignSelf={"center"}
            >
              <Button
                size="small"
                variants="invite"
                fontSize={12}
                onClick={() => {
                  console.log("invite user click");
                }}
              >
                Invite User
              </Button>
            </Style.StyledDiv>
            <Style.StyledDiv gridArea={"table"}>
              <Paper style={{ width: "100%" }} elevation={0}>
                <Table stickyHeader aria-label="sticky table">
                  <TableHead>
                    <TableRow>
                      {columns.map((column) => (
                        <Style.StyledHeaderCell
                          key={column.id}
                          align={column.align}
                        >
                          {column.label}
                        </Style.StyledHeaderCell>
                      ))}
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {orgMembers.map((member) => {
                      return (
                        <Style.StyledTableRow key={member.id}>
                          {columns.map((column) => {
                            const value = member[column.id];
                            return (
                              <Style.StyledTableCell key={column.id}>
                                {column.id === "admin" ? (
                                  <Checkbox
                                    checked={member.admin}
                                    onChange={() => handleAdminChange(member)}
                                    color="default"
                                  />
                                ) : (
                                  value
                                )}
                              </Style.StyledTableCell>
                            );
                          })}
                        </Style.StyledTableRow>
                      );
                    })}
                  </TableBody>
                </Table>
              </Paper>
            </Style.StyledDiv>
          </Style.Lower>
        </Style.Page>
      )}
    </div>
  );
};

const mapStateToProps = (state) => ({
  user_id: state.user.user_id,
  orgMembers: state.org.orgMembers,
});

OrganizationTab.propTypes = {
  org: PropTypes.any.isRequired,
  dispatch: PropTypes.func.isRequired,
  value: PropTypes.number.isRequired,
  index: PropTypes.number.isRequired,
};

export default connect(mapStateToProps)(OrganizationTab);
