import {
  AVATAR_URL_UPDATED,
  USER_LOGGED_IN,
  USER_LOGGED_OUT,
  USER_INFO_UPDATED,
  USER_ORG_ADMIN_UPDATE,
  USER_ORG_ADMIN_UPDATE_ERROR,
  AVATAR_ERROR,
} from "../actions/ActionTypes";

const initialState = {
  username: null,
  user_id: null,
  user_token: null,
  first_name: null,
  last_name: null,
  avatar_url: null,
  org_id: null,
  org_name: null,
  is_org_admin: false,
  error: null,
};

const userReducer = (state = initialState, action) => {
  switch (action.type) {
    case AVATAR_URL_UPDATED:
      return {
        ...state,
        avatar_url: action.avatar_url,
      };
    case USER_LOGGED_IN:
      return {
        ...state,
        username: action.username,
        user_id: action.user_id,
        user_token: action.user_token,
        first_name: action.first_name,
        last_name: action.last_name,
        avatar_url: action.avatar_url,
        org_id: action.org_id,
        org_name: action.org_name,
        is_org_admin: action.is_org_admin,
      };
    case USER_INFO_UPDATED:
      return {
        ...state,
        first_name: action.first_name,
        last_name: action.last_name,
      };
    case USER_ORG_ADMIN_UPDATE:
      return {
        ...state,
        is_org_admin: action.payload.admin,
      };
    case USER_LOGGED_OUT:
      return {
        ...initialState,
        error: state.error,
      };
    case AVATAR_ERROR:
    case USER_ORG_ADMIN_UPDATE_ERROR:
      return {
        ...state,
        error: action.error,
      };
    default:
      return state;
  }
};

export default userReducer;
