import styled from "styled-components";
import { withStyles } from "@material-ui/core/styles";
import TableCell from "@material-ui/core/TableCell";
import TableRow from "@material-ui/core/TableRow";
import TableContainer from "@material-ui/core/TableContainer";

export const StyledLocationNameCell = withStyles(() => ({
  body: {
    fontSize: 12,
  },
  root: {
    padding: 6,
  },
}))(TableCell);

export const StyledCountCell = withStyles(() => ({
  body: {
    fontSize: 12,
  },
  root: {
    padding: 6,
    color: "white",
  },
}))(TableCell);

export const StyledTableRow = styled(TableRow)`
  &.MuiTableRow-root {
    &:nth-of-type(odd) {
      background-color: white;
    }
    &:hover {
      background-color: #C0CEE2 !important;
      box-shadow: 1px 0 0 #C0CEE2;
      & > .MuiTableCell-root {
        color: black;
      }
      max-width: 100px;
    }
    &.MuiTableRow-root.Mui-selected {
      background-color: #C0CEE2;
      box-shadow: 1px 0 0 #C0CEE2;
      & > .MuiTableCell-root {
        font-weight: bold;
        color: black;
      }
    }
  }
`

export const StyledTableContainer = styled(TableContainer)`
  //x-small screens
  @media only screen and (max-width: 425px) {
    max-height: 200px;
  }
  //small screens
  @media only screen and (min-width: 800px) {
    max-height: 250px;
  }
  //medium screens
  @media only screen and (min-width: 1280px) {
    max-height: 400px;
  }
  //large screens
  @media only screen and (min-width: 1920px) {
    max-height: 450px;
  }
  //x-large screens
  @media only screen and (min-width: 2560px) {
    max-height: 600px;
  }
  //xx-large screens
  @media only screen and (min-width: 3840px) {
    max-height: 950px;
  }
`;

export const ItemRecordCount = styled.div`
  background-color: ${({ selected, index }) =>
    selected || index ? "white" : "#999999"};

  padding: 4px;
  border-radius: 25px;
`;
