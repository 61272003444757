import axios from "axios";

const locationApiHost = process.env.REACT_APP_API_HOST;

export function AddLocation(org_id, user_token, location) {
  const url = locationApiHost + `/organizations/${org_id}/locations`;
  const {
    name,
    address1,
    address2,
    city,
    region,
    postalcode,
    country,
    latitude,
    longitude,
  } = location;
  const data = axios.post(
    url,
    {
      name: name,
      address1: address1,
      address2: address2,
      city: city,
      region: region,
      postalCode: postalcode,
      country: country,
      latitude: latitude,
      longitude: longitude,
    },
    {
      headers: {
        Authorization: `${user_token}`,
        "Content-Type": "application/json",
      },
    }
  );
  return data;
}

export function GetLocations(org_id, user_token) {
  const url = locationApiHost + `/organizations/${org_id}/locations`;
  const data = axios.get(url, {
    headers: {
      Authorization: `${user_token}`,
    },
  });

  return data;
}

export function GetLocation(org_id, user_token, locationId) {
  const url =
    locationApiHost + `/organizations/${org_id}/locations/${locationId}`;
  const data = axios.get(url, {
    headers: {
      Authorization: `${user_token}`,
    },
  });

  return data;
}

export function EditLocation(org_id, user_token, editedLocation, locationId) {
  const {
    name,
    address1,
    address2,
    city,
    region,
    postalcode,
    country,
    latitude,
    longitude,
  } = editedLocation;
  const url =
    locationApiHost + `/organizations/${org_id}/locations/${locationId}`;
  const data = axios.put(
    url,
    {
      name: name,
      address1: address1,
      address2: address2,
      city: city,
      region: region,
      postalCode: postalcode,
      country: country,
      latitude: latitude,
      longitude: longitude,
    },
    {
      headers: {
        Authorization: `${user_token}`,
        "Content-Type": "application/json",
      },
    }
  );
  return data;
}

export function DeleteLocation(locationId, org_id, user_token) {
  const url =
    locationApiHost + `/organizations/${org_id}/locations/${locationId}`;
  const data = axios.delete(url, {
    headers: {
      Authorization: `${user_token}`,
    },
  });
  return data;
}
