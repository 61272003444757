import {
  ASSET_CREATE,
  ASSET_CREATE_ERROR,
  ASSET_GET_ASSETS,
  ASSET_GET_ASSETS_ERROR,
  ASSET_DELETE_ASSET,
  ASSET_DELETE_ASSET_ERROR,
  ASSET_UPDATE_ASSET,
  ASSET_UPDATE_ASSET_ERROR,

  FIELD_DEFINITIONS_REQUESTED,
  FIELD_DEFINITIONS_FETCHED,
  FIELD_DEFINITIONS_ERROR,

  GET_VIEWS,
  GET_VIEWS_ERROR,
  VIEW_CREATE,
  VIEW_CREATE_ERROR,
  DELETE_VIEW,
  DELETE_VIEW_ERROR,
  UPDATE_VIEW,
  UPDATE_VIEW_ERROR,

} from './ActionTypes';

// Assets
export const createAsset = () => ({
  type: ASSET_CREATE
});

export const createAssetError = (error) => ({
  type: ASSET_CREATE_ERROR,
  error: error
});

export const getAssets = (assets) => ({
  type: ASSET_GET_ASSETS,
  payload: assets
});

export const getAssetsError = (error) => ({
  type: ASSET_GET_ASSETS_ERROR,
  error: error
});

export const deleteAsset = () => ({
  type: ASSET_DELETE_ASSET
});

export const deleteAssetError = (error) => ({
  type: ASSET_DELETE_ASSET_ERROR,
  error: error
});

export const updateAsset = () => ({
  type: ASSET_UPDATE_ASSET
});

export const updateAssetError = (error) => ({
  type: ASSET_UPDATE_ASSET_ERROR,
  error: error
});

// Field Defs
export const fieldDefinitionsFetched = (fields) => ({
  type: FIELD_DEFINITIONS_FETCHED,
  payload: fields
});

export const fieldDefinitionsRequested = () => ({
  type: FIELD_DEFINITIONS_REQUESTED,
});

export const fieldDefinitionsError = (error) => ({
  type: FIELD_DEFINITIONS_ERROR,
  error: error
});

// Views 
export const getViews = (views) => ({
  type: GET_VIEWS,
  payload: views
});

export const getViewsError = (error) => ({
  type: GET_VIEWS_ERROR,
  error: error
});

export const createView = (views) => ({
  type: VIEW_CREATE,
  payload: views
});

export const createViewError = (error) => ({
  type: VIEW_CREATE_ERROR,
  error: error
});

export const deleteView = () => ({
  type: DELETE_VIEW,
});

export const deleteViewError = (error) => ({
  type: DELETE_VIEW_ERROR,
  error: error
});

export const updateView = () => ({
  type: UPDATE_VIEW,
});

export const updateViewError = (error) => ({
  type: UPDATE_VIEW_ERROR,
  error: error
});
