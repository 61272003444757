import React, { useRef } from "react";
import * as Style from "./styles";
import Attachment from "../../../../components/attachment";
import PropTypes from 'prop-types';

const AssetAttachments = ({
	assetAttachmentState, 
	handleChange,
        handleDelete
}) => {

  const fileRef = useRef();
  let attachments = null
  if (assetAttachmentState) {
    attachments = Object.keys(assetAttachmentState).map((attachmentName) => (
      <Attachment label={attachmentName} key={attachmentName} handleDelete={() => handleDelete(attachmentName)}/>
    ))
  }

  return (
    <>
      <Style.AttachmentContainer>
        <Style.Attachments>
          {attachments}
        </Style.Attachments>
        <Style.ButtonContainer>
          <input
            type="file"
            accept="*"
            onChange={handleChange}
            ref={fileRef}
            style={{display: "none"}}/>
          <Style.StyledButton
            size="small"
            fontSize={12}
            handleClick={() => {
              fileRef.current.click();
            }}
          >
            Add Attachment
          </Style.StyledButton>
        </Style.ButtonContainer>
      </Style.AttachmentContainer>
    </>
  )
};

AssetAttachments.propTypes = {
  assetAttachmentState: PropTypes.object, 
  handleChange: PropTypes.func,
  handleDelete: PropTypes.func
}

export default AssetAttachments;
