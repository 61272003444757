import axios from "axios";

const organizationApiHost = process.env.REACT_APP_API_HOST;

export function GetOrgCustomColumns(user_token, org_id) {
  const url = organizationApiHost + `/organizations/${org_id}/custom_columns`;
  const data = axios.get(url, {
    headers: {
      Authorization: `${user_token}`,
    },
  });
  return data;
}

export function GetOrg(org_id, user_token) {
  const url = organizationApiHost + `/organizations/${org_id}`;
  const data = axios.get(url, {
    headers: {
      Authorization: `${user_token}`,
    },
  });

  return data;
}

export function UpdateOrg(org_id, user_token, updatedOrg) {
  const { name } = updatedOrg;
  const url = organizationApiHost + `/organizations/${org_id}`;
  const data = axios.put(
    url,
    {
      name: name,
    },
    {
      headers: {
        Authorization: `${user_token}`,
        "Content-Type": "application/json",
      },
    }
  );
  return data;
}

export function UpdateOrgUser(org_id, user_id, user_token, admin) {
  const url = organizationApiHost + `/organizations/${org_id}/users/${user_id}`;
  const data = axios.put(
    url,
    {
      admin: admin,
    },
    {
      headers: {
        Authorization: `${user_token}`,
        "Content-Type": "application/json",
      },
    }
  );
  return data;
}

export function GetOrgMembers(org_id, user_token) {
  const url = organizationApiHost + `/organizations/${org_id}/users`;
  const data = axios.get(url, {
    headers: {
      Authorization: `${user_token}`,
    },
  });

  return data;
}
