import React, { useState } from "react";
import PropTypes from "prop-types";

import CircularProgress from "../../../components/circular-progress";
import OrganizationTab from "../organization-tab";
import AdvancedTab from "../advanced-tab";

import * as Style from "./styles";

const PaperNav = ({ dispatch, org }) => {
  const [value, setValue] = useState(0);
  const handleChange = (e, newValue) => {
    setValue(newValue);
  };
  return (
    <Style.PaperContainer>
      <Style.StyledAppBar position="static" elevation={0}>
        <Style.StyledTabs value={value} onChange={handleChange}>
          <Style.StyledTab label="Organization" />
          <Style.StyledTab label="Advanced" />
        </Style.StyledTabs>
      </Style.StyledAppBar>
      <Style.StyledChildrenDiv>
        {org ? (
          <OrganizationTab
            org={org}
            value={value}
            index={0}
            dispatch={dispatch}
          />
        ) : (
          <CircularProgress />
        )}
        <AdvancedTab value={value} index={1} />
      </Style.StyledChildrenDiv>
    </Style.PaperContainer>
  );
};

PaperNav.propTypes = {
  org: PropTypes.any.isRequired,
  dispatch: PropTypes.func.isRequired,
};

export default PaperNav;
